import {all, put, takeLatest} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import drawerActions from './drawerActions';

export function* openHelp({payload}) {
  const {markdown, hash} = payload;
  yield put(drawerActions.hideDrawer());

  yield put({
    type: ActionTypes.DRAWER_DISPLAY,
    payload: {markdown, hash}
  });
}

export default function* drawerSaga() {
  yield all([
    yield takeLatest(ActionTypes.DRAWER_SHOW, openHelp),
  ]);
}