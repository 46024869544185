exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FilmListContainer_container1280__2WPt6, .FilmListContainer_container__3r58A {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .FilmListContainer_container1280__2WPt6, .FilmListContainer_container__3r58A {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.FilmListContainer_container__3r58A {\n  height: 1px;\n  min-height: calc(100vh - 104px);\n  display: flex;\n  flex-direction: column; }\n\n.FilmListContainer_title__3bFDW {\n  margin-bottom: 4px; }\n\n.FilmListContainer_table__LRKri {\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n  overflow: auto; }\n  .FilmListContainer_table__LRKri thead > tr > th:first-child {\n    width: 120px; }\n  .FilmListContainer_table__LRKri thead > tr > th {\n    padding: 10px 24px; }\n  .FilmListContainer_table__LRKri tbody > tr > td {\n    padding: 16px 24px; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "FilmListContainer_container1280__2WPt6",
	"container": "FilmListContainer_container__3r58A",
	"title": "FilmListContainer_title__3bFDW",
	"table": "FilmListContainer_table__LRKri"
};