//import BaseLayout from '../containers/layout/BaseLayout/BaseLayout';
import EmptyLayout from '../containers/layout/EmptyLayout/EmptyLayout';
import DiglexLayout from '../containers/layout/DiglexLayout/DiglexLayout';
import PathLayout from '../containers/layout/PathLayout/PathLayout';
import DiglexContainer from '../containers/DiglexContainer/DiglexContainer';
// import AnalyzerContainer from '../containers/AnalyzerContainer';
import TextsContainer from '../containers/TextsContainer';
import CollectionContainer from '../containers/CollectionContainer';
import React from 'react';
import LoginFormContainer from '../containers/user/LoginFormContainer/LoginFormContainer';
import RegistrationFormContainer from '../containers/user/RegistrationFormContainer/RegistrationFormContainer';
import DiglexProjectsContainer from '../containers/diglex/DiglexProjectsContainer/DiglexProjectsContainer';
import DiglexGroupsContainer from '../containers/diglex/DiglexGroupsContainer/DiglexGroupsContainer';
import DiglexCreateProjectContainer from 'containers/diglex/DiglexProjectCreateContainer/DiglexProjectCreateContainer';
import DiglexCreateGroupContainer from '../containers/diglex/DiglexGroupCreateContainer/DiglexGroupCreateContainer';
import DiglexGroupPageContainer from '../containers/diglex/DiglexGroupPageContainer/DiglexGroupPageContainer';
import DiglexProjectPageContainer from '../containers/diglex/DiglexProjectPageContainer/DiglexProjectPageContainer';

import createDiglexGroupList from '../containers/diglex/DiglexGroupsListContainer/DiglexGroupsListContainer';
import createDiglexProjectList from '../containers/diglex/DiglexProjectListContainer/DiglexProjectListContainer';
import createDiglexMemberList from '../containers/diglex/DiglexMemberListContainer/DiglexMemberListContainer';
import DiglexMemberInviteContainer from '../containers/diglex/DiglexMemberInviteContainer/DiglexMemberInviteContainer';
import DiglexProjectSettingsContainer
  from '../containers/diglex/DiglexProjectSettingsContainer/DiglexProjectSettingsContainer';
import DiglexGroupSettingsContainer
  from '../containers/diglex/DiglexGroupSettingsContainer/DiglexGroupSettingsContainer';
import DiglexProjectForkContainer from '../containers/diglex/DiglexProjectForkContainer/DiglexProjectForkContainer';
import ModalLayout from '../containers/layout/ModalLayout/ModalLayout';
import DiglexProjectPageModalContainer
  from '../containers/diglex/DiglexProjectPageModalContainer/DiglexProjectPageModalContainer';
import MarkdownContainer from '../containers/MarkdownContainer/MarkdownContainer';
import {helpRoutes, baseHelpPath} from 'config/helpRoutes';
import MovieBotLayout from '../containers/layout/MovieBotLayout/MovieBotLayout';
import FilmListContainer from '../containers/movieBot/FilmListContainer/FilmListContainer';
import FilmEditContainer from '../containers/movieBot/FilmEditContainer/FilmEditContainer';
import createRouterTitleComponent from '../utils/router/RouterTitleComponent';
import ChatViewContainer from '../containers/movieBot/ChatViewContainer/ChatViewContainer';
import BaseLayout from '../containers/layout/BaseLayout/BaseLayout';
import LoginLayout from "../containers/layout/LoginLayout/LoginLayout";

const routes = [
  {
    path: baseHelpPath,
    component: EmptyLayout,
    routes: [
      ...Object.keys(helpRoutes).map(path => ({
        path,
        render: () => <MarkdownContainer title={helpRoutes[path].title} source={helpRoutes[path].source}/>
      }))
    ],
  },
  {
    path: '/movie-bot',
    component: MovieBotLayout,
    routes: [
      {
        path: '/',
        component: createRouterTitleComponent("Movie Bot"),
        routes: [
          {
            path: ['/chats', '/chat/:id'],
            component: ChatViewContainer
          },
          {
            path: 'movies',
            component: FilmListContainer
          },
          {
            path: '/movie/:id',
            component: FilmEditContainer
          },
          {
            path: '/',
            render: () => null
          }
        ]
      }
    ],
  },
  {
    path: '/user',
    component: LoginLayout,
    permission: {
      every: {
        profile: true,
      },
      then: {
        redirect: '/',
      }
    },
    routes: [
      {
        path: '/login',
        component: LoginFormContainer,
      },
      {
        path: '/registration',
        component: RegistrationFormContainer,
      },
      {
        path: '/',
        redirect: '/user/login',
      },
    ],
  },
  {
    path: '/corpora',
    component: BaseLayout,
    /* todo: make redirects? */
    // redirect: '/collections',
    routes: [
      {
        text: 'Корпус',
        icon: 'solution',
        path: '/',
        /* need sub or just undefined path for menu composition? */
        sub: true,
        routes: [
          {
            path: '/collections',
            text: 'Коллекции',
            icon: 'database',
            component: CollectionContainer
          },
          {
            path: '/texts',
            text: 'Тексты',
            icon: 'read',
            component: TextsContainer
          },
          // {
          //   path: '/analyzer',
          //   text: 'Анализ',
          //   icon: 'experiment',
          //   component: AnalyzerContainer
          // },
        ]
      },
      // {
      //   path: '/diglex',
      //   text: 'diglex',
      //   icon: 'book',
      //   component: DiglexContainer
      // }
    ]
  },
  {
    path: '/',
    component: DiglexLayout,
    permission: {
      some: {
        profile: false,
      },
      then: {
        redirect: '/user/login',
      }
    },
    routes: [
      {
        path: '/project',
        component: PathLayout,
        routes: [
          {
            path: [
              '/:type/:owner/:projectPath/file/:filePath',
              '/:type/:owner/:projectPath',
            ],
            component: DiglexContainer,
            routes: [
              {
                path: '/',
                component: ModalLayout,
                routes: [
                  {
                    path: '/',
                    component: DiglexProjectPageModalContainer,
                    routes: [
                      {
                        path: '/members/invite',
                        title: 'Участники проекта',
                        component: DiglexMemberInviteContainer,
                      },
                      {
                        path: '/members',
                        title: 'Участники проекта',
                        component: createDiglexMemberList('project'),
                      },
                      {
                        path: '/settings',
                        title: 'Настройки проекта',
                        component: DiglexProjectSettingsContainer,
                      },
                    ]
                  },
                ]
              },
            ]
          }
        ]
      },
      {
        path: '/projects',
        component: PathLayout,
        routes: [
          {
            path: '/new',
            component: DiglexCreateProjectContainer,
          },
          {
            path: '/fork',
            component: DiglexProjectForkContainer,
          },
          {
            path: '/:type/:owner/:projectPath',
            component: DiglexProjectPageContainer,
            routes: [
              {
                path: '/members/invite',
                component: DiglexMemberInviteContainer,
              },
              {
                path: '/members',
                component: createDiglexMemberList('project'),
              },
              {
                path: '/settings',
                component: DiglexProjectSettingsContainer,
                // component: createDiglexProjectList('user'),
              },
              {
                path: '/',
                redirect: '/members',
                // component: createDiglexProjectList('all'),
              }
            ]
          },
          {
            path: '/',
            component: DiglexProjectsContainer,
            routes: [
              {
                path: '/explore',
                component: createDiglexProjectList('explore-project'),
              },
              {
                path: '/personal',
                component: createDiglexProjectList('user-projects'),
              },
              {
                path: '/groups',
                component: createDiglexProjectList('user-groups'),
              },
              {
                path: '/',
                component: createDiglexProjectList('all'),
              }
            ]
          },
        ]
      },
      {
        path: '/group/:groupPath',
        component: DiglexGroupPageContainer,
        routes: [
          {
            path: '/members/invite',
            component: DiglexMemberInviteContainer,
          },
          {
            path: '/members',
            component: createDiglexMemberList('group'),
          },
          {
            path: '/projects',
            component: createDiglexProjectList('group'),
          },
          {
            path: '/settings',
            component: DiglexGroupSettingsContainer,
          },
          {
            path: '/',
            component: createDiglexProjectList('group'),
          },
        ]
      },
      {
        path: '/groups',
        component: PathLayout,
        routes: [
          {
            path: '/new',
            component: DiglexCreateGroupContainer,
          },
          {
            path: '/',
            component: DiglexGroupsContainer,
            routes: [
              {
                path: '/explore',
                component: createDiglexGroupList('explore-groups'),
              },
              {
                path: '/',
                component: createDiglexGroupList('user-groups'),
              }
            ]
          },
        ]
      },
      {
        path: '/',
        redirect: '/projects',
      },
    ]
  },
];

export default routes;