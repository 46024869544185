export class Color {

  constructor(red = 0, green = 0, blue = 0, alpha = 1) {
    this.red = red;
    this.green = green;
    this.blue = blue;
    this.alpha = alpha;
  }

  toHex({red = this.red, green = this.green, blue = this.blue} = this) {
    const hex = c => {
      const n = c.toString(16);
      return n.length === 1 ? '0' + n : n;
    };
    return `#${hex(red|0)}${hex(green|0)}${hex(blue|0)}`;
  }

  toRGB({red = this.red, green = this.green, blue = this.blue} = this) {
    return `rgb(${red}, ${green}, ${blue})`;
  }

  toRGBA({red = this.red, green = this.green, blue = this.blue, alpha = this.alpha} = this) {
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  }
}

Color.fromStr = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return new Color((hash >> 16) & 0xFF, (hash >> 8) & 0xFF, hash & 0xFF);
};

Color.fromMultiply = function (colors) {
  if (!Array.isArray(colors)) {
    colors = [...arguments];
  }
  if (colors.length === 1) {
    return colors.pop();
  }
  const rgb = [0, 0, 0];

  colors.forEach(color => {
    rgb[0] += color.red / colors.length;
    rgb[1] += color.green / colors.length;
    rgb[2] += color.blue / colors.length;
  });

  return new Color(...rgb);
};