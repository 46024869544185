
const ActionTypes = {

  PROJECT_WS_SUBSCRIBE: 'PROJECT_WS_SUBSCRIBE',
  PROJECT_WS_UNSUBSCRIBE: 'PROJECT_WS_UNSUBSCRIBE',
  PROJECT_WS_FILES_LOCKED: 'PROJECT_WS_FILES_LOCKED',
  PROJECT_WS_FILES_UNLOCKED: 'PROJECT_WS_FILES_UNLOCKED',
  PROJECT_FORCE_LOCK_FILE: 'PROJECT_FORCE_LOCK_FILE',

  PROJECT_CREATE_REQUEST: 'PROJECT_CREATE_REQUEST',
  PROJECT_CREATE_FULFILLED: 'PROJECT_CREATE_FULFILLED',
  PROJECT_CREATE_FAIL: 'PROJECT_CREATE_FAIL',

  PROJECT_FORK_REQUEST: 'PROJECT_FORK_REQUEST',
  PROJECT_FORK_FULFILLED: 'PROJECT_FORK_FULFILLED',
  PROJECT_FORK_FAIL: 'PROJECT_FORK_FAIL',

  PROJECT_DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',
  PROJECT_DELETE_FULFILLED: 'PROJECT_DELETE_FULFILLED',
  PROJECT_DELETE_FAIL: 'PROJECT_DELETE_FAIL',

  PROJECT_UPDATE_REQUEST: 'PROJECT_UPDATE_REQUEST',
  PROJECT_UPDATE_FULFILLED: 'PROJECT_UPDATE_FULFILLED',
  PROJECT_UPDATE_FAIL: 'PROJECT_UPDATE_FAIL',

  PROJECT_FETCH_REQUEST: 'PROJECT_FETCH_REQUEST',
  PROJECT_FETCH_FULFILLED: 'PROJECT_FETCH_FULFILLED',
  PROJECT_FETCH_FAIL: 'PROJECT_FETCH_FAIL',

  PROJECT_NEW_FILE: 'PROJECT_NEW_FILE',
  PROJECT_RENAME_FILE: 'PROJECT_RENAME_FILE',
  PROJECT_DELETE_FILE: 'PROJECT_DELETE_FILE',

  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  PROJECT_SELECT_FILE: 'PROJECT_SELECT_FILE',

  SET_FILE_TREE: 'SET_FILE_TREE',

  PROJECT_LIST_FETCHED: 'PROJECT_LIST_FETCHED',
  PROJECT_CLEAN_SAVE_QUEUE: 'PROJECT_CLEAN_SAVE_QUEUE',
  PROJECT_ADD_FILE_TO_SAVE_QUEUE: "PROJECT_ADD_FILE_TO_SAVE_QUEUE",
  PROJECT_REMOVE_FILE_FROM_SAVE_QUEUE: 'PROJECT_REMOVE_FILE_FROM_SAVE_QUEUE',
  // PROJECT_NEW_FILE: 'PROJECT_NEW_FILE',
};

export default ActionTypes;
