import {call, put, all, takeLatest} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import * as axios from 'axios';
import {MOVIE_BOT_URL} from 'constants/constants';
import {notification} from 'antd';

function* fetchChats({payload}) {
  const {page, page_size, id} = payload;

  try {
    const response = yield call(axios, {
      url: `${MOVIE_BOT_URL}/users/`,
      method: 'GET',
      params: {
        page,
        page_size,
      }
    });
    const {results, count} = response.data;

    yield put({
      type: ActionTypes.MOVIE_BOT_CHATS_FULFILLED,
      payload: {
        count,
        results: results.map((user, i) => {
          return {
            key: i,
            ...user
          };
        }),
      },
    });

  } catch (e) {
    yield put({
      type: ActionTypes.MOVIE_BOT_CHATS_FAIL,
      payload: {},
    });
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

function* fetchChat({payload}) {
  const {chatID} = payload;
  try {
    const response = yield call(axios, {
      url: `${MOVIE_BOT_URL}/chats/${chatID}/`,
      method: 'GET',
    });

    yield put({
      type: ActionTypes.MOVIE_BOT_CHAT_FULFILLED,
      payload: {
        results: response.data
      },
    });

  } catch (e) {
    yield put({
      type: ActionTypes.MOVIE_BOT_CHAT_FAIL,
      payload: {},
    });
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export default function* userSaga() {
  yield all([
    yield takeLatest(ActionTypes.MOVIE_BOT_CHATS_REQUEST, fetchChats),
    yield takeLatest(ActionTypes.MOVIE_BOT_CHAT_REQUEST, fetchChat),
  ]);
}