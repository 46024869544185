import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {normalizePath} from '../utils';
import {connect} from 'react-redux';

function handlePermissions(route, path, out, actions = {}) {
  const result = {};

  Object.keys(actions).forEach(key => {
    switch (key) {
      case 'redirect': {
        out.push(<Redirect exact={route.exact} key={path} from={path} to={actions[key]}/>);
        result.stop = true;
        break;
      }
      default: // nop
    }
  });

  return result;
}

class NestedRoutes extends React.Component {
  renderRoutes = ({routes, match}) => {
    let out = [];

    routes.forEach(route => {
      const key = route.path.toString();
      let path = null;

      if (Array.isArray(route.path)) {
        path = normalizePath(route.path.map(path => `${match.path}${path}`));
      } else {
        path = normalizePath(`${match.path}${route.path}`);
      }

      if (!(route.component || route.render) && route.routes) {
        out = out.concat(this.renderRoutes({routes: route.routes, match}));
        return;
      }

      if (route.redirect) {
        out.push(<Redirect exact={route.exact} key={key} from={path} to={route.redirect}/>);
      }

      if (route.permission) {
        const func = route.permission.some ? 'some' : 'every';

        const permissions = route.permission[func];
        const keys = Object.keys(route.permission[func]);

        let type = null;
        if (keys[func](key => permissions[key] === !!this.props.user[key])) {
          type = 'then';
        } else {
          type = 'else';
        }
        const result = handlePermissions(route, path, out, route.permission[type]);

        if (result.stop) {
          return;
        }
      }

      out.push(
        <Route
          key={key}
          path={path}
          exact={route.exact}
          render={route.component ? props => (<route.component {...props} routes={route.routes}/>) : route.render}
        />
      );
    });

    return out;
  };

  render() {
    const {routes, match} = this.props;
    return (
      <Switch>
        {this.renderRoutes({routes, match})}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // ???
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NestedRoutes);