import React from 'react';
import {Menu, Icon} from 'antd';
import styles from './HeaderUser.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';

export default class HeaderUser extends React.Component {
  state = {
    toggled: false
  };

  toggle = toggled => this.setState({toggled});

  user = ({key}) => {
    this.toggle(false);
    switch (key) {
      case "logout": {
        this.props.logout();
        break;
      }
      default:
    }
  };

  render() {
    const userMenu = (
      <Menu onClick={this.user}>
        {/*<Menu.Item key={'profile'}>*/}
        {/*<Icon type="logout" />*/}
        {/*{' '}*/}
        {/*Settings*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key={'settings'}>*/}
        {/*<Icon type="logout" />*/}
        {/*{' '}*/}
        {/*Выйти*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Divider/>*/}
        <Menu.Item key={'logout'}>
          <Icon type="logout"/>
          {' '}
          Выйти
        </Menu.Item>
      </Menu>
    );
    return (
      <HeaderDropdown
        overlay={userMenu}
        overlayClassName={styles.dropDown}
        onVisibleChange={this.toggle}
        placement="bottomRight"
        trigger={['click']}
      >
        <span>
          <Icon
            type="user"
            className={`${this.state.toggled ? styles.iconToggled : styles.icon}`}
          />
          {this.props.username}
        </span>
      </HeaderDropdown>
    );
  }
}
