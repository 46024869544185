import React from "react";
import {connect} from "react-redux";
import {Card, Input, Pagination, Table, Tag} from 'antd';
import styles from './FilmListContainer.module.scss';
import {Color} from 'utils';
import {fetchMovies} from 'ducks/movieBot/movie/movieActions';
import {Link} from 'react-router-dom';

class FilmListContainer extends React.Component {
  state = {
    id: null,
    search: '',
    page: 1,
    limit: 10,
  };

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      id: query.get('id') ? Number(query.get('id')) : null,
      search: query.get('search') ? query.get('search') : '',
      page: query.get('page') ? Number(query.get('page')) : 1,
      limit: query.get('limit') ? Number(query.get('limit')) : 10,
    };
  }

  createColumns = () => [
    {
      title: (
        <div>
          <div className={styles.title}>ID</div>
          <Input
            value={this.state.id ? this.state.id : ''}
            onChange={this.handleByID}
            // onBlur={this.handleByID}
            onKeyUp={this.handleByID}
            placeholder="Search by ID"
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      render: id => <Link to={`/movie-bot/movie/${id}`}>{id}</Link>,
    },
    {
      title: (
        <div>
          <div className={styles.title}>Film Name</div>
          <Input
            value={this.state.search}
            onChange={this.handleByName}
            // onBlur={this.handleByName}
            onKeyUp={this.handleByName}
            placeholder="Search by Film Name"
          />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (title, obj) => <Link to={`/movie-bot/movie/${obj.id}`}>{title}</Link>,
    },
    {
      title: 'Localized Name',
      dataIndex: 'title_ru',
      key: 'title_ru',
      render: (title, obj) => <Link to={`/movie-bot/movie/${obj.id}`}>{title}</Link>,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: year => <b>{year}</b>
    },
    {
      title: 'Genres',
      key: 'genres',
      dataIndex: 'genres',
      render: tags => (
        <span>
        {tags.map(tag => {
          const name = tag.toUpperCase();
          const color = Color.fromStr(name);
          const darkColor = Color.fromMultiply([color, new Color(1, 1, 1)]);

          return (
            <Tag
              key={tag}
              style={{
                color: darkColor.toHex(),
                background: color.toRGBA({alpha: .5}),
                borderColor: darkColor.toHex(),
                margin: 3,
              }}
            >
              {name}
            </Tag>
          );
        })}
      </span>
      ),
    },
    {
      title: 'Links',
      key: 'links',
      dataIndex: 'links',
      render: links => links.map((link, i) => (
        <span key={i}>{i ? ', ' : ''}<a target="_blank" href={link.url}>{link.name}</a></span>
      ))
    },
  ];

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      const {search, page, limit, id} = this.state;
      this.props.fetchMovies({search, page, limit, id});
    }
  }

  componentDidMount() {
    const {search, page, limit, id} = this.state;
    this.props.fetchMovies({search, page, limit, id});
  }

  handleByName = (e) => {
    if (e.type === 'change') {
      return this.setState({search: e.target.value});
    }
    if (e.key !== 'Enter' && e.type !== 'blur') {
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    if (e.type === 'blur' && e.target.value === query.get('search')) {
      return;
    }
    query.set('page', '1');
    query.delete('id');
    query.set('search', e.target.value);
    this.setState({
      id: null,
      page: 1,
      search: e.target.value
    });
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  handleByID = (e) => {
    if (e.type === 'change') {
      if (!e.target.value || /[0-9]+/.test(e.target.value)) {
        this.setState({id: e.target.value});
      }
      return;
    }
    if (e.key !== 'Enter' && e.type !== 'blur') {
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    if (e.type === 'blur' && e.target.value === query.get('search')) {
      return;
    }
    query.set('page', '1');
    query.delete('search');
    query.set('id', e.target.value);
    this.setState({
      id: e.target.value,
      page: 1,
      search: ''
    });
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  handlePagination = (page, limit) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('limit', limit.toString());
    query.set('page', page.toString());
    this.setState({page, limit});
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  render() {
    return (
      <Card
        className={styles.container}
        headStyle={{flexShrink: 0}}
        bodyStyle={{padding: '1px 0 0 0', height: 'calc(100% - 56px)'}}
        title={<div> Film Select </div>}
      >
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <Table
            loading={this.props.loading}
            className={styles.table}
            columns={this.createColumns()}
            dataSource={this.props.movies || []}
            pagination={false}
          />

          <Pagination
            style={{
              textAlign: 'center',
              padding: 16,
            }}
            showSizeChanger
            onChange={this.handlePagination}
            onShowSizeChange={this.handlePagination}

            defaultPageSize={this.state.limit}
            defaultCurrent={this.state.page}

            total={this.props.count}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location,
    loading: state.movieBotReducers.movie.loading,
    count: state.movieBotReducers.movie.count,
    movies: state.movieBotReducers.movie.movies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMovies: ({search, page, limit, id}) => dispatch(fetchMovies({search, page, page_size: limit, id})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilmListContainer);
