import ActionTypes from './actionTypes.js';

const userActions = {

  authorization: ({email, password}) => {
    return {
      type: ActionTypes.USER_AUTH_REQUEST,
      payload: {email, password},
    };
  },

  changePreferences: (preferences) => {
    return {
      type: ActionTypes.USER_CHANGE_PREFERENCES,
      payload: {preferences},
    };
  },

  registration: ({username, email, password}) => {
    return {
      type: ActionTypes.USER_REGISTRATION_REQUEST,
      payload: {username, email, password},
    };
  },

  logout: () => {
    return {
      type: ActionTypes.USER_LOGOUT,
      payload: {},
    };
  },

  restore: () => {
    return {
      type: ActionTypes.USER_RESTORE_DATA,
      payload: {
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        profile: JSON.parse(localStorage.getItem('profile')),
      }
    };
  }

};

export default userActions;
