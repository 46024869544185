import React from 'react';
import {connect} from 'react-redux';
import RecordActions from 'ducks/record/Actions';
import ModalActions from '../../ducks/modal/actions';
import {normalizePath} from '../../utils';
import {Route, Switch} from 'react-router';

import RecordsTable from 'components/RecordTable/RecordsTable';
import {RecordEditType, RecordFieldType} from 'components/RecordEdit/RecordEdit';

import {columnsFilter, columnsRender, recordInteractions} from 'components/RecordTable/constants';
import RecordEdit from 'components/RecordEdit/RecordEdit';

const entity = 'TextCollection';
const relations = [];

const interactions = [
  recordInteractions.view,
  recordInteractions.create,
  recordInteractions.update,
  recordInteractions.delete,
];

// Table view

const tableFields = [
  {
    title: 'Название',
    schema: 'name',
    render: columnsRender.editRecord,
    filter: columnsFilter.search,
  },
  {
    title: 'Описание',
    schema: 'description',
  }, {
    title: 'Тексты',
    render: columnsRender.advancedRender,
    advancedRender: (value, record, allData, history) => (
      <a onClick={() => history.push(`texts?collection=${record.id}`)}>Тексты</a>
    ),
    // advancedRender: (value, record, allData) => (
    //   <a>Тексты ({allData['Text'].filter(text => text['collection'] === record.id).length})</a>
    // ),
  },
];

// Edit

const editFields = [
  {
    title: 'Название',
    type: RecordFieldType.string,
    schema: 'name'
  },
  {
    title: 'Описание',
    type: RecordFieldType.text,
    schema: 'description'
  },
  {
    title: 'Коллекция',
    type: RecordFieldType.select,
    schema: 'collection_type'
  },
];

class CollectionContainer extends React.Component {

  render() {
    const path = normalizePath(`${this.props.match.path}`);

    return (
      <Switch>
        <Route
          key={'list'}
          path={`${path}`}
          exact={true}
          component={props =>
            <RecordsTable
              location={this.props.location}
              history={this.props.history}
              match={this.props.match}

              interactions={interactions}
              entity={entity}
              tableFields={tableFields}

              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
            />}
        />
        <Route
          key={'create'}
          path={`${path}/create`}
          component={props =>
            <RecordEdit
              type={RecordEditType.create}

              interactions={interactions}

              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
              entity={entity}

              editFields={editFields}

              showModal={this.props.showModal}
              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
              deleteRecord={this.props.deleteRecord}
              createRecord={this.props.createRecord}
              updateRecord={this.props.updateRecord}
            />}
        />
        <Route
          key={'edit'}
          path={`${path}/edit/:id`}
          component={props =>
            <RecordEdit
              type={RecordEditType.update}

              interactions={interactions}

              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
              entity={entity}

              editFields={editFields}

              showModal={this.props.showModal}
              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
              deleteRecord={this.props.deleteRecord}
              createRecord={this.props.createRecord}
              updateRecord={this.props.updateRecord}
            />}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    records: state.records
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modalType, modalProps) => dispatch(ModalActions.showModal(modalType, modalProps)),
    //fetchSchema: () => dispatch(SchemaActions.fetchSchema()),
    //fetchRecords: (entity, query) => dispatch(SchemaActions.fetchRecords(entity, query)),
    getRecords: (entity, query, relations) => dispatch(RecordActions.getRecords(entity, query, relations)),
    flushRecords: () => dispatch(RecordActions.flushRecords()),
    deleteRecord: (entity, values, record, navigate) => dispatch(RecordActions.deleteRecord(entity, values, record, navigate)),
    createRecord: (entity, values, navigate) => dispatch(RecordActions.createRecord(entity, values, navigate)),
    updateRecord: (entity, values, record, navigate) => dispatch(RecordActions.updateRecord(entity, values, record, navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionContainer);