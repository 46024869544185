import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import SourceActions from "ducks/source/Actions.js";
import ProjectActions from "ducks/project/projectActions.js";
import ProjectThunks from "ducks/project/Thunks.js";
import diglexActions from "ducks/diglex/Actions.js";
import SplitPane from "react-split-pane";
import Tree from "components/Tree/Tree.js";
import SourceEditorTabs from "components/SourceEditorTabs/SourceEditorTabs.js";
import DiglexStatusToolbar from "components/DiglexStatusToolbar/DiglexStatusToolbar.js";
import DiglexExtractResult from "components/DiglexExtractResult/DiglexExtractResult.js";
import SampleTextEditor from "components/SampleTextEditor/SampleTextEditor.js";
import {removeFileYamlExtension} from "utils";
import projectActions from 'ducks/project/projectActions';
import DiglexProjectToolbar from '../../components/diglex/DiglexProjectToolbar/DiglexProjectToolbar';
import NestedRoutes from '../../utils/NestedRoutes';

class DiglexContainer extends React.Component {

  state = {
    isSaveFileDisabled: true,
    isRenameFileDisabled: true,
    extractResult: null,
    isWaitingExtractResult: false,
    fileFilter: '',
  };

  setFilter = fileFilter => {
    this.setState({fileFilter})
  };

  constructor(props) {
    super(props);
    const {type, owner, projectPath} = this.props.match.params;
    this.fullPath = `/${type}/${owner}/${projectPath}`;
  }

  componentDidMount = () => {
    this.props.diglexClear();
    this.props.fetchProject(this.fullPath);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.availableProjects !== nextProps.availableProjects) {
      const project = nextProps.availableProjects.find(project => project.fullPath === this.fullPath);

      if (project) {
        const {projectPath, filePath} = this.props.match.params;
        // this.props.openProject(project);
        if (filePath) {
          // todo: fix file select
          this.props.fileSelected(projectPath, filePath);
        }
      }
    }
  }

  // saveFile = (path, data) => {
  //   console.log('saveFile')
  //   return axios
  //     .put(baseUrl + "/api/sources/save-file", {path: path, data: data})
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  // onNewFile = (path) => {
  //   console.log('new');
  //
  //   this.saveFile(path, "").then(res => {
  //     this.fileTree.refresh();
  //   });
  // };

  // onRenameFile(sourcePath, destinationPath) {
  //   console.log('rename')
  //   axios({
  //     method: "post",
  //     url: baseUrl + "/api/sources/rename-file",
  //     params: {source_path: sourcePath, destination_path: destinationPath}
  //   })
  //     .then(function (response) {
  //       // TODO не вызывается
  //       console.log("ok");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //
  //   this.fileTree.updateCurrentFileName(destinationPath);
  // }

  render() {
    if (!this.props.activeProject || this.props.activeProject.fullPath !== this.fullPath) {
      return null; // todo: spin
    }

    return (
      <>
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
        <SplitPane
          split="vertical"
          minSize={200}
          defaultSize={this.props.ui.diglexTree.width}
          maxSize={400}
          style={{position: "relative"}}
          pane1Style={{overflowY: "none", position: "relative"}}
        >
          <div className="panel-wrap">

            <DiglexProjectToolbar
              match={this.props.match}
              history={this.props.history}
              activeProject={this.props.activeProject}
              onNewFile={this.props.newFile}
              setFilter={this.setFilter}
              fileFilter={this.state.fileFilter}
            />

            <Tree
              tree={this.props.activeProject.files.filter(file => {
                if (!this.state.fileFilter) {
                  return true;
                }
                return file.name.toLowerCase().includes(this.state.fileFilter.toLowerCase());
              })}
              activeTab={this.props.activeTab}
              onFileRename={path => {
                let destinationPath = window.prompt(
                  "Введите новое имя файла",
                  removeFileYamlExtension(path)
                );
                if (destinationPath) {
                  this.props.renameFile(path, destinationPath);
                }
              }}
              onFileDelete={path => {
                if (window.confirm("Удалить " + path + "?")) {
                  this.props.deleteFile(path);
                }
              }}
              onFileSelect={path => {
                this.props.fileSelected(this.props.activeProject.name, path);
              }}
            />
          </div>
          <SplitPane
            split="vertical"
            defaultSize="50%"
            minSize={200}
            maxSize={this.props.ui.window.width - this.props.ui.diglexTree.width - 300}
          >
            <div className="panel-wrap">
              <SourceEditorTabs
                filesLocked={this.props.filesLocked}
                activeProject={this.props.activeProject}
                tabs={this.props.sourceTabs}
                saveQueue={this.props.saveQueue}
                onSourceChange={this.props.addFileToSaveQueue}
                forceLockFile={this.props.forceLockFile}
                onEdit={(name, action) => {
                  if (action === "remove") {
                    this.props.removeTab(name);
                  }
                }}
                activeTab={this.props.activeTab}
                onTabClick={key => {
                  const match = key.match(/^[\w -_]+#(.+)$/);
                  if(!match) {
                    return;
                  }
                  this.props.fileSelected(this.props.activeProject.name, match[1]);
                  this.props.activateTab(key);
                }}
              />
            </div>

            <SplitPane
              split="horizontal"
              defaultSize="50%"
              minSize={200}
              pane2Style={{background: '#fff'}}
            >
              <div className="panel-wrap">
                <DiglexStatusToolbar/>
                <SampleTextEditor
                  canExtract={this.props.activeProject}
                  activeProject={this.props.activeProject}
                  diglexExtract={this.props.diglexExtract}
                  diglexClear={this.props.diglexClear}
                  extractResult={this.props.extractResult}

                  openDiglexHelp={this.props.openDiglexHelp}
                  openServiceInfo={this.props.openServiceInfo}
                />
              </div>
              <DiglexExtractResult
                isWaitingExtractResult={this.props.isWaitingExtractResult}
                extractResult={this.props.extractResult}
              />
            </SplitPane>
          </SplitPane>
        </SplitPane>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ui: state.ui,
    availableProjects: state.project.form.list.values,

    activeProject: state.project.activeProject,

    extractResult: state.diglex.extractResult,
    isWaitingExtractResult: state.diglex.isWaitingExtractResult,
    filesLocked: state.project.filesLocked,
    sourceTabs: state.source.tabs,
    saveQueue: state.project.saveQueue,
    activeTab: state.source.activeTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: (fullPath) => dispatch(projectActions.fetchProjects('get', fullPath)),
    subscribe: (fullPath) => dispatch(projectActions.subscribe(fullPath)),
    // openProject: project => dispatch(ProjectActions.setActiveProject(project)),
    diglexExtract: (project, text) => {
      dispatch(ProjectThunks.saveFilesInQueue());
      dispatch(diglexActions.sendExtract(project, text));
    },
    diglexClear: () => {
      dispatch(diglexActions.diglexClear());
    },
    newTab: tab => dispatch(SourceActions.newTab(tab)),
    activateTab: tab => dispatch(SourceActions.activateTab(tab)),
    removeTab: name => dispatch(SourceActions.removeTab(name)),
    addFileToSaveQueue: (name, value) => {
      dispatch(ProjectActions.addFileToSaveQueue(name, value));
      // dispatch(ProjectThunks.saveFilesInQueue());
    },
    openServiceInfo: () => dispatch(ProjectActions.newFile()),
    // filesystem
    newProject: name => dispatch(ProjectThunks.newProject(name)),
    newFile: () => dispatch(ProjectActions.newFile()),
    renameFile: (sourcePath, destinationPath) => dispatch(ProjectActions.renameFile(sourcePath, destinationPath)),
    forceLockFile: (projectName, path) =>       dispatch(ProjectActions.forceLockFile(projectName, path)) ,
    deleteFile: path => dispatch(projectActions.deleteFile(path)),
    fileSelected: (projectName, path) =>       dispatch(ProjectActions.fileSelected(projectName, path))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DiglexContainer));
