exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexGroupPageContainer_container1280__29CgG, .DiglexGroupPageContainer_container__2R0nO {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexGroupPageContainer_container1280__29CgG, .DiglexGroupPageContainer_container__2R0nO {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexGroupPageContainer_navBar__1SpnI {\n  width: 256px;\n  position: absolute;\n  height: calc(100% - 65px);\n  left: 0;\n  flex-shrink: 0; }\n\n.DiglexGroupPageContainer_wrapper__32Qdu {\n  display: flex;\n  align-items: center; }\n  .DiglexGroupPageContainer_wrapper__32Qdu > * {\n    flex-shrink: 0;\n    flex-grow: 0; }\n  .DiglexGroupPageContainer_wrapper__32Qdu .DiglexGroupPageContainer_space__2sDRV {\n    flex-grow: 1; }\n  .DiglexGroupPageContainer_wrapper__32Qdu .DiglexGroupPageContainer_title__2FD_8 {\n    margin-left: 12px;\n    font-size: 16px;\n    flex-shrink: 1;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexGroupPageContainer_container1280__29CgG",
	"container": "DiglexGroupPageContainer_container__2R0nO",
	"navBar": "DiglexGroupPageContainer_navBar__1SpnI",
	"wrapper": "DiglexGroupPageContainer_wrapper__32Qdu",
	"space": "DiglexGroupPageContainer_space__2sDRV",
	"title": "DiglexGroupPageContainer_title__2FD_8"
};