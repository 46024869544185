exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SampleTextMark_mark__YFPvJ, .SampleTextMark_mark-active__37nWa, .SampleTextMark_markActive__3OgTR, .SampleTextMark_markActiveBegin__19cEe, .SampleTextMark_markActiveMiddle__Eg6iS, .SampleTextMark_markActiveEnd__PZZgv {\n  cursor: pointer;\n  font-size: 14px;\n  -webkit-transition: box-shadow 0.15s;\n  transition: box-shadow 0.15s;\n  position: relative; }\n\n.SampleTextMark_markActive__3OgTR {\n  box-shadow: -2px 0 0 2px #e10000, 2px 0 0 2px #e10000; }\n\n.SampleTextMark_markActiveBegin__19cEe {\n  box-shadow: -2px 0 0 2px #e10000; }\n\n.SampleTextMark_markActiveMiddle__Eg6iS {\n  box-shadow: 0 0 0 0 transparent, 0 2px 0 0 #e10000, 0 -2px 0 0 #e10000; }\n\n.SampleTextMark_markActiveEnd__PZZgv {\n  box-shadow: 2px 0 0 2px #e10000; }\n", ""]);

// Exports
exports.locals = {
	"mark": "SampleTextMark_mark__YFPvJ",
	"mark-active": "SampleTextMark_mark-active__37nWa",
	"markActive": "SampleTextMark_markActive__3OgTR",
	"markActiveBegin": "SampleTextMark_markActiveBegin__19cEe",
	"markActiveMiddle": "SampleTextMark_markActiveMiddle__Eg6iS",
	"markActiveEnd": "SampleTextMark_markActiveEnd__PZZgv"
};