import {eventChannel} from "@redux-saga/core";

const wsProtocol = document.location.protocol.replace('http', 'ws');
const host = window.location.hostname;

function createChannel(ws) {
  return eventChannel(emit => {
    ws.onmessage = event => {
      emit(JSON.parse(event.data));
    };
    ws.onclose = event => {
      emit({event: 'close'});
    };
    return () => {
      //
    };
  });
}

const wsCache = {};

export function closeWS({path}) {
  const ws = wsCache[path];

  try {
    ws.close();
  } catch (e) {
    //
  }
}

export function openWS({token, path, onConnect}) {
  let ws = wsCache[path];

  if (ws && ws.readyState === WebSocket.OPEN) {
    return new Promise(resolve => resolve(ws));
  }

  return new Promise((res, rej) => {
    ws = new WebSocket(`${wsProtocol}//${host}/${path}/`);
    wsCache[path] = ws;
    ws.isAuthorized = false;

    ws.onopen = function () {
      ws.send(JSON.stringify({"event": "authorization", data: 'JWT ' + token}));
    };

    const timeOut = setTimeout(() => {
      rej('auth fail by timeout.');
    }, 30e3);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.event) {
        case 'JWT-invalid': {
          if (ws.readyState === WebSocket.OPEN) {
            ws.close();
            rej('auth fail.');
          }
          break;
        }
        case 'JWT-valid': {
          clearTimeout(timeOut);
          ws.isAuthorized = true;
          ws.socketChannel = createChannel(ws);

          if(onConnect) {
            onConnect(ws);
          }

          res(ws);
          break;
        }
      }
    };
  });
}