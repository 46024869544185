import React from 'react';
import {Form, Button, Input, Spin, Layout, Select} from 'antd';
import styles from './RecordsEdit.module.scss';
import {recordInteractions} from '../RecordTable/constants';

export const RecordEditType = {
  'update': 'update',
  'create': 'create'
};

export const RecordFieldType = {
  'text': 'text',
  'select': 'select',
  'string': 'string',
  'selectRelation': 'selectRelation'
};

const formItemLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 12},
};

class RecordEdit extends React.Component {

  state = {
    notFound: false,
  };

  constructor(props) {
    super(props);
    try {
      if (this.props.type === RecordEditType.create) {
        this.id = 0;
      } else {
        this.id = parseInt(this.props.location.pathname.match(/(\d+)/)[1]);
      }
      //this.data = this.props.data.find(data => data.id === this.id);
      // if (!this.data) {
      //   this.notFound = true;
      // }
    } catch (e) {
      // if (!this.data) {
      //   this.notFound = true;
      // }
    }
  }

  componentDidMount() {
    if (this.props.records.entity !== this.props.entity
      || this.id !== this.props.records.query.id
    ) {
      this.props.flushRecords();
      this.props.getRecords(this.props.entity, {
        id: this.id
      }, this.props.relations);
    }

    if (!this.props.records.loading && this.props.records.data && this.props.records.schema) {
      const records = this.props.records.data[this.props.entity];

      if (!records) {
        this.setState({notFound: true});
        return null;
      }

      const data = records.find(record => record.id === this.id);

      if (!data) {
        this.setState({notFound: true});
        return null;
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.props.type === RecordEditType.update) {
        this.props.updateRecord(this.props.entity, values, this.getData(), {
          history: this.props.history,
          url: this.props.match.path,
        });
      }
      if (this.props.type === RecordEditType.create) {
        this.props.createRecord(this.props.entity, values, {
          history: this.props.history,
          url: this.props.match.path,
        });
      }
    });
  };

  renderFormItems = ({fields, history, path, data}) => {
    if (this.props.records.loading || !this.props.records.data || !this.props.records.schema) {
      return [];
    }

    const schema = this.props.records.schema[this.props.entity];
    const {getFieldDecorator} = this.props.form;
    const schemaFields = schema.fields;
    const formItems = [];

    fields.forEach(field => {
      const addItem = (options, body) => {
        formItems.push(
          <Form.Item
            key={formItems.length}
            label={field.title}
            {...formItemLayout}
          >
            {getFieldDecorator(field.schema, {
              initialValue: data ? data[field.schema] : null,
              ...options,
            })(body)}
          </Form.Item>
        );
      };

      switch (field.type) {
        case RecordFieldType.string: {
          addItem({
            rules: [{
              required: field.schema ? schemaFields[field.schema].required : false,
              message: 'Please input the title of collection!'
            }],
          }, (<Input/>));
          break;
        }
        case RecordFieldType.text: {
          addItem({
            rules: [{
              required: field.schema ? schemaFields[field.schema].required : false,
              message: 'Please input the title of collection!'
            }],
          }, (
            <Input.TextArea rows={12}/>
          ));
          break;
        }
        case RecordFieldType.selectRelation: {
          const relationData = this.props.records.data[schemaFields[field.schema].relation];
          const relation = {};

          Object.keys(relationData).forEach(key => {
            relation[relationData[key].id] = relationData[key];
          });

          addItem({
            initialValue: data ? relation[data[field.schema]].id.toString() : undefined,
            rules: [{
              required: field.schema ? schemaFields[field.schema].required : false,
              message: 'Please input the title of collection!'
            }],
          }, (
            <Select>
              {Object.entries(relation).map(relation => (
                <Select.Option key={relation[0]} value={relation[0]}>{relation[1].name}</Select.Option>
              ))}
            </Select>
          ));
          break;
        }
        case RecordFieldType.select: {

          addItem({
            //schemaFields[field.schema].choices[data[field.schema]]
            initialValue: data ? data[field.schema].toString() : undefined,
            rules: [{
              required: field.schema ? schemaFields[field.schema].required : false,
              message: 'Please input the title of collection!'
            }],
          }, (
            <Select>
              {Object.entries(schemaFields[field.schema].choices).map(choice => (
                <Select.Option key={choice[0]} value={choice[0]}>{choice[1]}</Select.Option>
              ))}
            </Select>
          ));
          break;
        }
        default:
          throw new Error(`Unknown type "${field.type}" for ${this.props.entity}`);
      }
    });

    return formItems;
  };

  renderDeleteButton = () => {
    if (!this.props.interactions.includes(recordInteractions.delete) || this.props.type === RecordEditType.create) {
      return;
    }

    const handleDelete = () => {
      const values = this.props.form.getFieldsValue();
      const remove = () => this.props.deleteRecord(this.props.entity, values, this.getData(), {
        history: this.props.history,
        url: this.props.match.path,
      });

      this.props.showModal('confirm', {
        okButtonProps: {'type': 'danger'},
        okText: 'Удалить',
        title: `Удалить?`,
        content: 'Вы уверены, что хотите удалить, это действие нельзя отменить.',
        onOk() {
          this.update({
            cancelButtonProps: {disabled: true},
          });
          remove();
          return new Promise(() => {});
        }
      });
    };

    return (
      <Button icon="delete" onClick={handleDelete} type="danger">Удалить</Button>
    );
  };

  renderSaveButton = () => {
    if (this.props.interactions.includes(recordInteractions.update) && !this.props.records.loading) {
      return (
        <Form.Item {...{wrapperCol: {span: 12, offset: 6}}}>
          <Button icon="save" loading={this.props.records.saving} type="primary" htmlType="submit">Сохранить</Button>
        </Form.Item>
      );
    }
  };

  getData = () => {
    if (this.props.type === RecordEditType.create) {
      return null;
    }
    if (this.props.records.loading || !this.props.records.data || !this.props.records.schema) {
      return null;
    }
    const records = this.props.records.data[this.props.entity];

    if (!records) {
      return null;
    }

    const data = records.find(record => record.id === this.id);

    if(!data) {
      return null;
    }

    return data
  };

  render() {
    if (this.state.notFound && this.props.type === RecordEditType.update) {
      return (
        <div>Not Found...</div>
      );
    }
    return (
      <Spin size={'large'} spinning={this.props.records.loading}>
        <div className={styles.header}>
          <div>{this.props.entity}</div>
          {this.renderDeleteButton()}
        </div>

        <Layout.Content className={styles.content}>
          <Form onSubmit={this.handleSubmit} layout="horizontal">
            {this.renderFormItems({
              history: this.props.history,
              path: this.props.match.path,
              fields: this.props.editFields,
              data: this.getData(),
            })}
            {this.renderSaveButton()}
          </Form>
        </Layout.Content>
      </Spin>
    );
  }
}

export default Form.create({})(RecordEdit);