export function getFileExtension(filename) {
  if (filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  return filename;
}

export function removeFileExtension(filename) {
  if (filename) {
    return filename.replace(/\.[^/.]+$/, "");
  }

  return filename;
}

// export function setFileYamlExtension(filename) {
//   if (getFileExtension(filename)) {
//     return filename.replace(/\.[^/.]+$/, ".yaml");
//   }
//   return filename + '.yaml';
// }

export function urlToList(url) {
  const urlList = url.split('/').filter(i => i);
  return urlList.map((urlItem, index) => `/${urlList.slice(0, index + 1).join('/')}`);
}

export function removeFileYamlExtension(filename) {
  const extension = getFileExtension(filename);
  if (extension === "yaml" || extension === "yml") {
    return removeFileExtension(filename);
  }

  return filename;
}

export function normalizePath(path) {
  if (Array.isArray(path)) {
    return path.map(item => normalizePath(item));
  }
  return path.replace('//', '/');
}

export function addFileYamlExtension(filename) {
  if (!getFileExtension(filename)) {
    return filename + ".yml";
  }

  return filename;
}

export {Color} from 'utils/color';
