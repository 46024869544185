import React from "react";
import {connect} from "react-redux";
import {Avatar, Card, Pagination, Spin, Table} from 'antd';
import styles from './ChatViewContainer.module.scss';
import {fetchChat, fetchChats} from 'ducks/movieBot/chat/chatActions';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

class ChatViewContainer extends React.Component {
  state = {
    id: null,
    search: '',
    page: 1,
    limit: 10,
  };

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.lastPost = React.createRef();

    this.state = {
      id: query.get('id') ? Number(query.get('id')) : null,
      search: query.get('search') ? query.get('search') : '',
      page: query.get('page') ? Number(query.get('page')) : 1,
      limit: query.get('limit') ? Number(query.get('limit')) : 10,
    };
  }

  createColumns = () => [
    {
      title: (
        <div>
          <div className={styles.title}>Chat List</div>
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id, user) =>
        <Link to={`/movie-bot/chat/${user.telegram_chat_id}`}>
          {user.telegram_username ? user.telegram_username : 'No username'}
        </Link>,
    },
  ];

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      const {search, page, limit, id} = this.state;
      this.props.fetchChats({page, limit, id});

      if (this.props.match.params.id) {
        this.props.fetchChat({chatID: this.props.match.params.id});
      }
    }
  }

  componentDidMount() {
    const {page, limit, id} = this.state;
    this.props.fetchChats({page, limit});
    if (this.props.match.params.id) {
      this.props.fetchChat({chatID: this.props.match.params.id});
    }
  }

  handleByName = (e) => {
    if (e.type === 'change') {
      return this.setState({search: e.target.value});
    }
    if (e.key !== 'Enter' && e.type !== 'blur') {
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    if (e.type === 'blur' && e.target.value === query.get('search')) {
      return;
    }
    query.set('page', '1');
    query.delete('id');
    query.set('search', e.target.value);
    this.setState({
      id: null,
      page: 1,
      search: e.target.value
    });
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  handleByID = (e) => {
    if (e.type === 'change') {
      if (!e.target.value || /[0-9]+/.test(e.target.value)) {
        this.setState({id: e.target.value});
      }
      return;
    }
    if (e.key !== 'Enter' && e.type !== 'blur') {
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    if (e.type === 'blur' && e.target.value === query.get('search')) {
      return;
    }
    query.set('page', '1');
    query.delete('search');
    query.set('id', e.target.value);
    this.setState({
      id: e.target.value,
      page: 1,
      search: ''
    });
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  handlePagination = (page, limit) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('limit', limit.toString());
    query.set('page', page.toString());
    this.setState({page, limit});
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  renderChat = () => {
    if (!this.props.match.params.id) {
      return null;
    }

    if (this.props.loadingChat || !this.props.chat) {
      return <Spin size={'large'} style={{margin: "64px 0", width: '100%'}}/>;
    }

    return [...this.props.chat].reverse().map((line, i) => {
      let align = 'right';
      let icon = 'robot';
      if (line.type_name === 'user') {
        align = 'left';
        icon = 'user';
      }
      const timestamp = (line.timestamp | 0)  * 1e3;
      const seconds = (line.timestamp % 1).toFixed(3) * 1e3;
      const date = new Date(timestamp + seconds);

      return (
        <div key={i} className={styles.chatLine}>
          <Avatar icon={icon} className={`${styles.chatAvatar} ${styles[align]}`}/>
          <div className={`${styles.chatBlob} ${styles[align]}`}>
            <ReactMarkdown
              source={line.text}
              escapeHtml={true}
            />
            {line.intent && <div className={`${styles.intent} ${styles[align]}`}>
              {line.intent.name} ({line.intent.confidence.toFixed(2)})
            </div>}

            <div className={`${styles.time} ${styles[align]}`}>
              {date.toLocaleString('ru-RU')}
            </div>
          </div>
        </div>
      );
    });
  };

  toLastPost = () => {
    if (this.lastPost && this.lastPost.current) {
      this.lastPost.current.scrollTop = this.lastPost.current.scrollHeight;
    }
  };

  render() {
    return (
      <Card
        className={styles.container}
        headStyle={{flexShrink: 0}}
        bodyStyle={{padding: '1px 0 0 0', height: 'calc(100% - 56px)'}}
        title={<div>Chats</div>}
      >
        <div className={styles.content}>
          <div className={styles.leftPanel}>
            <Table
              loading={this.props.loading}
              className={styles.table}
              columns={this.createColumns()}
              dataSource={this.props.chats || []}
              pagination={false}
            />

            <Pagination
              style={{
                textAlign: 'center',
                padding: 16,
              }}
              showSizeChanger
              onChange={this.handlePagination}
              onShowSizeChange={this.handlePagination}

              defaultPageSize={this.state.limit}
              defaultCurrent={this.state.page}

              total={this.props.count}
            />

          </div>
          <div className={styles.rightPanel}>
            <div className={styles.chatTitle}>
              <div className={styles.toLastPost} onClick={this.toLastPost}>
                To last message
              </div>
            </div>
            <div className={styles.scrollable} ref={this.lastPost}>
              {this.renderChat()}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location,

    loading: state.movieBotReducers.chat.loading,
    count: state.movieBotReducers.chat.count,
    chats: state.movieBotReducers.chat.chats,

    loadingChat: state.movieBotReducers.chat.loadingChat,
    chat: state.movieBotReducers.chat.chat,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchChats: ({page, limit, id}) => dispatch(fetchChats({page, page_size: limit, id})),
    fetchChat: ({chatID}) => dispatch(fetchChat({chatID})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatViewContainer);
