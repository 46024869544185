import React from "react";
import {connect} from "react-redux";
import projectActions from 'ducks/project/projectActions';
import DiglexProjectList from 'components/diglex/DiglexProjectList/DiglexProjectList';
import DiglexMemberList from '../DiglexMemberListContainer/DiglexMemberListContainer';

function createDiglexProjectList(type) {

  class DiglexProjectListContainer extends React.Component {
    render() {
      return (
        <DiglexProjectList
          type={type}

          history={this.props.history}
          location={this.props.location}

          groupId={type === 'group' ? this.props.groupId._id : ''}

          list={this.props.list}
          fetchProjects={() => this.props.fetchProjects(type)}
        />
      );
    }
  }

  const mapStateToProps = state => {
    return {
      groupId: state.group.activeGroup,
      list: state.project.form.list,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      fetchProjects: type => dispatch(projectActions.fetchProjects(type)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(DiglexProjectListContainer);
}

export default createDiglexProjectList;
