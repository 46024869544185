
export const recordInteractions = {
  'view': 'view',
  'create': 'create',
  'update': 'update',
  'delete': 'delete',
};

export const columnsRender = {
  'editRecord': 'editRecord',
  'relation': 'relation',
  'relationLink': 'relationLink',
  'advancedRender': 'advancedRender',
};

export const columnsFilter = {
  'search': 'search',
  'relations': 'relations'
};