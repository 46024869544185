import React from 'react';
import {Form, Button, Input, Icon, Card} from 'antd';
import styles from './LoginFormContainer.module.scss';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import userActions from 'ducks/user/userActions';

class LoginFormContainer extends React.Component {

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {email, password} = values;
        this.props.authorization({email, password});
      }
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.login.fieldErrors !== nextProps.user.login.fieldErrors) {
      this.props.form.setFields(nextProps.user.login.fieldErrors);
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className={styles.wrapper}>
        <div className={styles.space} />

        <Card
          title="Вход"
          headStyle={{textAlign: 'center'}}
          bordered={false}
          style={{width: 348}}
        >
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {type: 'email', message: 'Введите корректный e-mail'},
                  {required: true, message: 'Введите e-mail'},
                ],
              })(
                <Input
                  size='large'
                  prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                  placeholder="E-mail"
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{required: true, message: 'Введите пароль'}],
              })(
                <Input.Password
                  size='large'
                  prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                  placeholder="Пароль"
                />,
              )}
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
              <Button
                size='large'
                loading={this.props.user.login.loading}
                type="primary"
                htmlType="submit"
                className={styles.btn}
              >
                Войти
              </Button>

              <div className={styles.link}>
                Ещё нет аккаунта?&nbsp;
                <Link to={'/user/registration'}>Зарегистрируйтесь</Link>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div className={styles.space} />
        <div className={styles.space} />
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authorization: ({email, password}) => dispatch(userActions.authorization({email, password})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create({})(LoginFormContainer)));