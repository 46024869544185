import ActionTypes from "./ActionTypes.js";

const projectActions = {

  createProject: ({type, name, path, description, access}) => {
    return {
      type: ActionTypes.PROJECT_CREATE_REQUEST,
      payload: {type, name, path, description, access}
    };
  },

  forkProject: ({from, type, name, path, description, access}) => {
    return {
      type: ActionTypes.PROJECT_FORK_REQUEST,
      payload: {from, type, name, path, description, access}
    };
  },

  deleteProject: (id) => {
    return {
      type: ActionTypes.PROJECT_DELETE_REQUEST,
      payload: {id}
    };
  },

  updateProject: ({id, description, access}) => {
    return {
      type: ActionTypes.PROJECT_UPDATE_REQUEST,
      payload: {id, description, access}
    };
  },

  // openProject(project) {
  //   console.log('wtf?', project)
  //   return {
  //     // type: ActionTypes.,
  //     // payload: {project},
  //   };
  // },

  fetchProjects(type, fullPath) {
    return {
      type: ActionTypes.PROJECT_FETCH_REQUEST,
      payload: {type, fullPath},
    };
  },

  newFile() {
    return {
      type: ActionTypes.PROJECT_NEW_FILE,
      payload: {},
    };
  },

  renameFile(sourcePath, destinationPath) {
    return {
      type: ActionTypes.PROJECT_RENAME_FILE,
      payload: {sourcePath, destinationPath},
    };
  },

  deleteFile(path) {
    return {
      type: ActionTypes.PROJECT_DELETE_FILE,
      payload: {path},
    };
  },

  subscribe(fullPath) {
    return {
      type: ActionTypes.PROJECT_WS_SUBSCRIBE,
      payload: {fullPath},
    };
  },

  unsubscribe(fullPath) {
    return {
      type: ActionTypes.PROJECT_WS_UNSUBSCRIBE,
      payload: {fullPath},
    };
  },

  setActiveProject: project => {
    return {
      type: ActionTypes.SET_ACTIVE_PROJECT,
      payload: {project: project}
    };
  },

  fileSelected: (projectName, path) => {
    return {
      type: ActionTypes.PROJECT_SELECT_FILE,
      payload: {projectName, path}
    };
  },

  forceLockFile: (projectName, path) => {
    return {
      type: ActionTypes.PROJECT_FORCE_LOCK_FILE,
      payload: {projectName, path}
    };
  },

  /** old? */

  setFileTree: fileTree => {
    return {
      type: ActionTypes.SET_FILE_TREE,
      payload: {fileTree: fileTree}
    };
  },

  listFetched: projects => {
    return {
      type: ActionTypes.PROJECT_LIST_FETCHED,
      payload: {projects: projects}
    };
  },

  cleanSaveQueue: () => {
    return {
      type: ActionTypes.PROJECT_CLEAN_SAVE_QUEUE,
      payload: {}
    };
  },

  addFileToSaveQueue: (name, value) => {
    return {
      type: ActionTypes.PROJECT_ADD_FILE_TO_SAVE_QUEUE,
      payload: {name: name, value: value}
    };
  },

  removeFileFromSaveQueue: (name) => {
    return {
      type: ActionTypes.PROJECT_REMOVE_FILE_FROM_SAVE_QUEUE,
      payload: {name: name}
    };
  }
};

export default projectActions;
