import {call, put, all, takeEvery} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import {history} from '../store';
import fieldErrorsToUI from 'utils/errorsHelper';
import groupActions from './groupActions';

function* create({payload}) {
  const {name, path, description, access} = payload;

  try {
    const response = yield call(window.diglexAxios, {
      url: '/api/groups/new-group',
      method: 'POST',
      data: {name, path, description, access}
    });
    const {group} = response.data;

    yield put({
      type: ActionTypes.GROUP_CREATE_FULFILLED,
      payload: {group},
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.GROUP_CREATE_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.GROUP_CREATE_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* createRedirect({payload}) {
  history.push(`/group/${payload.group.path}`);
}

function* deleteRedirect({}) {
  history.push(`/groups`);
}

function* deleteGroup({payload}) {
  const {id} = payload;

  try {
    yield call(window.diglexAxios, {
      url: '/api/groups/delete',
      method: 'POST',
      data: {id}
    });

    yield put({
      type: ActionTypes.GROUP_DELETE_FULFILLED,
      payload: {},
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.GROUP_DELETE_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.GROUP_DELETE_FAIL,
          payload: {},
        });
      }
    }
  }
}


function* fetch({payload}) {
  const {type, path} = payload;

  try {
    const response = yield call(window.diglexAxios, {
      url: `/api/groups/${type}`,
      method: 'GET',
      params: type === 'get' ? {path} : null,
    });

    yield put({
      type: ActionTypes.GROUP_FETCH_FULFILLED,
      payload: {
        groups: response.data
      },
    });

    if (type === 'get' && response.data.length) {
      yield put(groupActions.setActiveGroup(response.data[0]))
    }

  } catch (e) {
    switch (e.response.status) {
      case 400: {
        yield put({
          type: ActionTypes.GROUP_FETCH_FAIL,
          payload: {},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.GROUP_FETCH_FAIL,
          payload: {},
        });
      }
    }
  }
}

export default function* userSaga() {
  yield all([
    yield takeEvery(ActionTypes.GROUP_DELETE_REQUEST, deleteGroup),
    yield takeEvery(ActionTypes.GROUP_CREATE_REQUEST, create),
    yield takeEvery(ActionTypes.GROUP_FETCH_REQUEST, fetch),

    yield takeEvery(ActionTypes.GROUP_CREATE_FULFILLED, createRedirect),
    yield takeEvery(ActionTypes.GROUP_DELETE_FULFILLED, deleteRedirect),
  ]);
}