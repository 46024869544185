import ActionTypes from "./ActionTypes.js";

const Actions = {
  newTab: tab => {
    return {
      type: ActionTypes.SOURCE_NEW_TAB,
      payload: {tab}
    };
  },

  removeTab: key => {
    return {
      type: ActionTypes.SOURCE_REMOVE_TAB,
      payload: {key}
    };
  },

  removeAllTabs: () => {
    return {
      type: ActionTypes.SOURCE_REMOVE_ALL_TABS,
      payload: {}
    };
  },

  activateTab: key => {
    return {
      type: ActionTypes.SOURCE_ACTIVATE_TAB,
      payload: {key}
    };
  },

  renameTab: (key, name) => {
    return {
      type: ActionTypes.SOURCE_RENAME_TAB,
      payload: {key, name}
    };
  },

  openHelp: () => {
    return {
      type: ActionTypes.SOURCE_OPEN_HELP,
      payload: {}
    };
  },
};

export default Actions;
