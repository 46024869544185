import ActionTypes from './actionTypes.js';

const memberActions = {

  fetchMembers: (type) => {
    return {
      type: ActionTypes.MEMBER_FETCH_REQUEST,
      payload: {type},
    };
  },

  remove: (type, userId) => {
    return {
      type: ActionTypes.MEMBER_REMOVE_REQUEST,
      payload: {type, userId},
    };
  },

  invite: (type, id, users, url) => {
    return {
      type: ActionTypes.MEMBER_INVITE_REQUEST,
      payload: {type, id, users, url},
    };
  },

};

export default memberActions;
