import ActionTypes from './actionTypes';

const initialState = {
  form: {
    create: {
      loading: false,
      errors: {},
    },
    delete: {
      loading: false,
      errors: {},
    },
    list: {
      values: [],
      loading: false,
      errors: {},
    }
  },
  activeGroup: undefined,
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.GROUP_CREATE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_CREATE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_CREATE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /* delete */

    case ActionTypes.GROUP_DELETE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_DELETE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_DELETE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /* */

    case ActionTypes.GROUP_FETCH_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: [],
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_FETCH_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: payload.groups,
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.GROUP_FETCH_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: [],
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.SET_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroup: payload.group
      };
    }

    default:
      return state;
  }
}
