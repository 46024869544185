exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assets/backgorund.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../assets/logo.png"));

// Module
exports.push([module.id, ".LoginLayout_background__2Y-7v {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") center center no-repeat; }\n\n.LoginLayout_header__1iFF3 {\n  display: flex;\n  margin-top: 40px;\n  position: absolute;\n  width: 100%; }\n  .LoginLayout_header__1iFF3 .LoginLayout_space__2aM7X {\n    flex-basis: 200px;\n    flex-grow: 1; }\n  .LoginLayout_header__1iFF3 .LoginLayout_logo__Uxebb {\n    flex-basis: 1248px;\n    max-width: 1248px;\n    background: url(" + ___CSS_LOADER_URL___1___ + ") left center no-repeat;\n    height: 28px; }\n", ""]);

// Exports
exports.locals = {
	"background": "LoginLayout_background__2Y-7v",
	"header": "LoginLayout_header__1iFF3",
	"space": "LoginLayout_space__2aM7X",
	"logo": "LoginLayout_logo__Uxebb"
};