import {BASE_URL} from "constants/constants.js";
import ProjectActions from "./projectActions.js";
import SourceActions from "ducks/source/Actions.js";
// import {removeExtension, addFileYamlExtension} from "utils";

const Thunks = {
  // openProject: project => {
  //   return (dispatch, getState) => {
  //     const state = getState();
  //     // const project = state.project.form.list.values.find(
  //     //   project => projectName === project["name"]
  //     // );
  //     //dispatch(push("/diglex/" + projectName));
  //
  //     dispatch();
  //     //dispatch(Thunks.fetchProjectFiles(project));
  //
  //     dispatch(SourceActions.removeAllTabs());
  //     dispatch(SourceActions.activateTab(''));
  //   };
  // },

  // listProjects: () => {
  //   return async dispatch => {
  //     const response = await window.diglexAxios({url: "/api/sources/projects", Method: "GET"});
  //
  //     dispatch(ProjectActions.listFetched(response.data));
  //     // return fetch("/api/sources/projects")
  //     //   .then(response => {
  //     //     if (!response.ok) {
  //     //       throw Error(response.statusText);
  //     //     }
  //     //     return response;
  //     //   })
  //     //   .then(response => response.json())
  //     //   .then(projects => {
  //     //     dispatch(ProjectActions.listFetched(projects));
  //     //     return projects;
  //     //   });
  //   };
  // },

  // fetchProjectFiles: project => {
  //
  //
  //
  //   //PROJECT_UPDATE
  //
  //   return dispatch => {
  //
  //     dispatch(ProjectActions.updateProject());
  //
  //     return window.diglexAxios
  //       .get("/api/sources/tree", {params: {projectId: project["_id"]}})
  //       .then(res => {
  //         dispatch(ProjectActions.setFileTree(res.data));
  //       });
  //   };
  // },

  fileSelected: (projectName, path) => {
    throw new Error('dont use it');
    return (dispatch, getState) => {
      const state = getState();

      return window.diglexAxios
        .get(BASE_URL + "/api/sources/open-file", {
          params: {path, projectId: state.project.activeProject._id}
        })
        .then(res => {
          const state = getState();

          if (!state.source.tabs.find(item => item.name === path)) {
            dispatch(SourceActions.newTab({name: path, source: res.data.toString(), type: 'source'}));
          }

          dispatch(SourceActions.activateTab(`source#${path}`));
          //dispatch(push(`/diglex/${projectName}/${path}`));
        });
    };
  },

  // newFile: () => {
  //   return async (dispatch, getState) => {
  //     let path = null;
  //
  //     while (1) {
  //       path = prompt("Имя файла", "");
  //       if (typeof path !== "string") {
  //         return;
  //       }
  //       if (path.length === 0) {
  //         alert('Необходимо ввести имя файла');
  //         continue;
  //       }
  //       break;
  //       // if (/^([^ !$`&*()+]|(\\[ !$`&*()+]))+/.test(path)) {
  //       //   break
  //       // }
  //       // alert('Некорректное имя файла');
  //     }
  //     let state = getState();
  //     let project = state.project.activeProject;
  //     let correctedPath = addFileYamlExtension(path);
  //
  //     await window.diglexAxios.post(BASE_URL + "/api/sources/new-file", {
  //       path: correctedPath,
  //       projectId: project._id,
  //     });
  //
  //     await dispatch(Thunks.fetchProjectFiles(project));
  //     await dispatch(Thunks.fileSelected(project["name"], correctedPath));
  //   };
  // },

  // renameFile: (sourcePath, destinationPath) => {
  //   return (dispatch, getState) => {
  //     const state = getState();
  //     const project = state.project.activeProject;
  //     const correctedDestinationPath = addFileYamlExtension(destinationPath);
  //
  //     dispatch(SourceActions.removeTab(`source#${sourcePath}`));
  //
  //     window.diglexAxios({
  //       method: "post",
  //       url: BASE_URL + "/api/sources/rename-file",
  //       params: {
  //         source_path: sourcePath,
  //         destination_path: correctedDestinationPath,
  //         projectId: project._id,
  //       }
  //     })
  //       .then(function (response) {
  //         dispatch(ProjectActions.updateProject()).then(() => {
  //           dispatch(
  //             SourceActions.renameTab(sourcePath, correctedDestinationPath)
  //           );
  //           dispatch(
  //             Thunks.fileSelected(project["name"], correctedDestinationPath)
  //           );
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   };
  // },

  deleteFile: path => {
    return (dispatch, getState) => {
      let state = getState();
      let project = state.project.activeProject;

      dispatch(SourceActions.removeTab(`source#${path}`));
      dispatch(ProjectActions.removeFileFromSaveQueue(path));

      return window.diglexAxios
        .post(`${BASE_URL}/api/sources/delete-file`, {
          path: path,
          projectId: project._id
        })
        .catch(error => {
          console.log(error);
        })
        .then(function () {
          dispatch(ProjectActions.updateProject());
        });
    };
  },

  saveFilesInQueue: (projectName, path) => {
    return (dispatch, getState) => {
      let state = getState();
      let queue = state.project.saveQueue;

      for (let path in queue) {
        const source = queue[path];

        window.diglexAxios
          .put(BASE_URL + "/api/sources/save-file", {
            path: path,
            projectId: state.project.activeProject._id,
            data: source
          })
          .catch(error => {
            console.log(error);
          });
      }

      return dispatch(ProjectActions.cleanSaveQueue());
    };
  }
};

export default Thunks;
