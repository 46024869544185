
const ActionTypes = {
  USER_RESTORE_DATA: 'USER_RESTORE_DATA',
  USER_LOGOUT: 'USER_LOGOUT',

  USER_AUTH_REQUEST: 'USER_AUTH_REQUEST',
  USER_AUTH_FULFILLED: 'USER_AUTH_FULFILLED',
  USER_AUTH_FAIL: 'USER_AUTH_FAIL',

  USER_PREFERENCES_REQUEST: 'USER_PREFERENCES_REQUEST',
  USER_PREFERENCES_FULFILLED: 'USER_PREFERENCES_FULFILLED',
  USER_PREFERENCES_FAIL: 'USER_PREFERENCES_FAIL',
  USER_CHANGE_PREFERENCES: 'USER_CHANGE_PREFERENCES',

  USER_REGISTRATION_REQUEST: 'USER_REGISTRATION_REQUEST',
  USER_REGISTRATION_FULFILLED: 'USER_REGISTRATION_FULFILLED',
  USER_REGISTRATION_FAIL: 'USER_REGISTRATION_FAIL',
};

export default ActionTypes;
