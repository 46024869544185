import ActionTypes from './actionTypes';

const initialState = {
  loading: false,
  count: 0,
  page: 0,
  tags: null,
  errors: {},
};

const reducers = {
  [ActionTypes.MOVIE_BOT_TAG_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    errors: {},
  }),
  [ActionTypes.MOVIE_BOT_TAG_FULFILLED]: (state, action) => ({
    ...state,
    loading: false,
    count: action.payload.count,
    tags: action.payload.results,
    errors: {},
  }),
  [ActionTypes.MOVIE_BOT_TAG_FAIL]: (state, action) => ({
    ...state,
    loading: false,
    tags: null,
    errors: {},
  }),

  [ActionTypes.MOVIE_BOT_TAG_DELETE]: (state, action) => ({
    ...state,
    tags: [...state.tags.filter(tag => tag.tag_id !== action.payload.tagID)]
  }),

  [ActionTypes.MOVIE_BOT_TAG_ADD]: (state, action) => ({
    ...state,
    tags: [...action.payload.tags, ...state.tags]
  }),
};

export default function (state = initialState, action) {
  const reducer = reducers[action.type];
  if (!reducer) {
    // todo: handle err?
    return state;
  }
  return reducer(state, action);
};