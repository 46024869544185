import {combineReducers} from "redux";

import movie from './movie/reducer';
import tag from './tag/reducer';
import chat from './chat/reducer';

export default combineReducers({
  movie,
  chat,
  tag,
});

