import React from "react";
import AceEditor from "react-ace";

import "brace/mode/yaml";
import "brace/theme/textmate";
import "./SourceEditor.css";
import ReactMarkdown from 'react-markdown/with-html';
import CodeEditor from '../CodeEditor/CodeEditor';
import styles from "../CodeEditor/CodeEditor.module.scss";
import {Button} from "antd";

class SourceEditor extends React.Component {
  state = {
    sourceCode: this.props.sourceCode
  };

  // handleSave = event => {
  //   this.setState({text: event.target.value});
  // };

  onChange = value => {
    this.setState({
      sourceCode: value
    });
    this.props.onChange(value);
  };

  saveFile() {
    this.props.setIsSaveFileDisabled(true);
    return this.props.saveFile(this.state.path, this.state.sourceCode);
  }

  renameFile() {
  }

  openFile(path, data) {
    /*
    console.log(path, data);
    this.setState({
      path: path,
      sourceCode: data
    });
    this.props.setIsSaveFileDisabled(true);
    this.props.setIsRenameFileDisabled(false);
    */
  }

  renderWarning = () => {
    if(!this.props.activeProject.permissions.includes('editFiles')) {
      return;
    }

    const file = this.props.filesLocked.find(file => file.name === this.props.name);
    if(!file || file.self) {
      return;
    }

    return (
      <div className="warning">
        Пользователь {file.username} редактирует данный файл.
        <Button
          type="danger"
          onClick={() => this.props.forceLockFile(this.props.activeProject.fullPath, file.name)}
        >
          Редактировать
        </Button>
      </div>
    );
  };

  isReadOnly = () => {
    if(!this.props.activeProject.permissions.includes('editFiles')) {
      return true
    }
    const file = this.props.filesLocked.find(file => file.name === this.props.name);
    return !(file && file.self);
  };

  render() {
    return this.props.type === 'markdown' ?
      <div className={"markdown-wrap"}>
        <ReactMarkdown
          source={this.state.sourceCode}
          escapeHtml={false}
        />
      </div>
      :
      <div className="ace-wrap">
        {this.renderWarning()}

        <CodeEditor
          code={this.state.sourceCode}
          onChange={this.props.onChange}
          options={{readOnly: this.isReadOnly()}}
        />
      </div>;
  }
}

export default SourceEditor;
