import {all, fork} from 'redux-saga/effects';

import recordSaga from './record/sagas';
import sourceSaga from './source/sagas';
import modalSaga from './modal/sagas';
import userSaga from './user/userSagas';
import memberSagas from './member/memberSagas';
import groupSagas from './group/groupSagas';
import projectSagas from './project/projectSagas';
import drawerSagas from './drawer/drawerSagas';
import diglexSagas from './diglex/diglexSagas';

import movieBotSagas from './movieBot/movieBotSagas';

export default function* rootSaga() {
  yield all([
    yield fork(sourceSaga),
    yield fork(modalSaga),
    yield fork(recordSaga),
    yield fork(userSaga),
    yield fork(groupSagas),
    yield fork(projectSagas),
    yield fork(memberSagas),
    yield fork(drawerSagas),
    yield fork(diglexSagas),
    yield fork(movieBotSagas),
  ]);
}