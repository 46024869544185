const ActionTypes = {
  RECORD_FLUSH: 'RECORD_FLUSH',

  RECORD_GET_REQUEST: 'RECORD_GET_REQUEST',
  RECORD_GET_RECEIVED: 'RECORD_GET_RECEIVED',
  RECORD_GET_FAIL: 'RECORD_GET_FAIL',

  RECORD_SCHEMA_FETCH_REQUEST: 'RECORD_SCHEMA_FETCH_REQUEST',
  RECORD_SCHEMA_FETCH_RECEIVED: 'RECORD_SCHEMA_FETCH_RECEIVED',
  RECORD_SCHEMA_FETCH_FAIL: 'RECORD_SCHEMA_FETCH_FAIL',

  RECORD_FETCH_REQUEST: 'RECORD_FETCH_REQUEST',
  RECORD_FETCH_RECEIVED: 'RECORD_FETCH_RECEIVED',
  RECORD_FETCH_FAIL: 'RECORD_FETCH_FAIL',

  RECORD_DELETE_REQUEST: 'RECORD_DELETE_REQUEST',
  RECORD_DELETE_RECEIVED: 'RECORD_DELETE_RECEIVED',
  RECORD_DELETE_FAIL: 'RECORD_DELETE_FAIL',

  RECORD_UPDATE_REQUEST: 'RECORD_UPDATE_REQUEST',
  RECORD_UPDATE_RECEIVED: 'RECORD_UPDATE_RECEIVED',
  RECORD_UPDATE_FAIL: 'RECORD_SAVE_FAIL',

  RECORD_CREATE_REQUEST: 'RECORD_CREATE_REQUEST',
  RECORD_CREATE_RECEIVED: 'RECORD_CREATE_RECEIVED',
  RECORD_CREATE_FAIL: 'RECORD_CREATE_FAIL',
};

export default ActionTypes;
