import React, {Component} from "react";
import NestedRoutes from 'utils/NestedRoutes';

class PathLayout extends Component {
  render() {
    return (
      <NestedRoutes routes={this.props.routes} match={this.props.match}/>
    );
  }
}

export default PathLayout;