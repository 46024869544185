import actionTypes from "./actionTypes.js";

export const fetchTags = ({search, page, page_size, id}) => ({
  type: actionTypes.MOVIE_BOT_TAG_REQUEST,
  payload: {search, page, page_size, id},
});

export const changeTag = ({movieID, tagID, value}) => ({
  type: actionTypes.MOVIE_BOT_TAG_CHANGE,
  payload: {movieID, tagID, value},
});

export const deleteTag = ({movieID, tagID, search, page, page_size}) => ({
  type: actionTypes.MOVIE_BOT_TAG_DELETE,
  payload: {movieID, tagID, search, page, page_size},
});

export const addTags = ({movieID, tags}) => ({
  type: actionTypes.MOVIE_BOT_TAG_ADD,
  payload: {tags},
});

