import actionTypes from "./actionTypes.js";

export const fetchChats = ({page, page_size, id}) => ({
  type: actionTypes.MOVIE_BOT_CHATS_REQUEST,
  payload: {page, page_size, id},
});

export const fetchChat = ({chatID}) => ({
  type: actionTypes.MOVIE_BOT_CHAT_REQUEST,
  payload: {chatID},
});
