import React from "react";
import {connect} from "react-redux";
import {Button, Card, Input, InputNumber, Modal, Pagination, Select, Slider, Spin, Table, Tag} from 'antd';
import styles from './FilmEditContainer.module.scss';
import {Link} from 'react-router-dom';
import {fetchMovies} from 'ducks/movieBot/movie/movieActions';
import {Color} from 'utils/color';
import {fetchTags} from 'ducks/movieBot/tag/tagActions';
import {addTags, changeTag, deleteTag} from '../../../ducks/movieBot/tag/tagActions';
import {MOVIE_BOT_URL} from '../../../constants/constants';
import * as axios from 'axios';

const marks = {
  0.0: '0.0',
  0.5: '0.5',
  1.0: '1.0',
};

const tagsCache = {};

class FilmEditContainer extends React.Component {

  state = {
    ready: false,
    search: '',
    page: 1,
    limit: 10,

    tags: [],

    tagSearch: '',
    tagsFetched: [],
    tagsSelected: [],
    fetchingTags: false,
    tagModalOpen: false,
  };

  constructor(props) {
    super(props);

    this.tagThrottle = {};

    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      ...this.state,
      search: query.get('search') ? query.get('search') : '',
      page: query.get('page') ? Number(query.get('page')) : 1,
      limit: query.get('limit') ? Number(query.get('limit')) : 10,
    };
  }

  componentDidMount() {
    this.props.fetchMovie(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {search, page, limit} = this.state;

    if (this.props.tags !== prevProps.tags) {
      this.setState({
        tags: this.props.tags.map(tag => {
          return {
            ...tag,
            relevanceTemp: tag.relevance,
          };
        })
      });
    }

    if (!this.state.ready && this.props.movies && this.props.movies.length) {
      const movie = this.props.movies[0];
      if (movie.id.toString() !== this.props.match.params.id.toString()) {
        return;
      }
      this.movie = movie;
      this.props.fetchTags(search, page, limit, this.movie.id);
      this.setState({ready: true});
    }

    if (prevProps.location.key !== this.props.location.key && this.movie) {
      this.props.fetchTags(search, page, limit, this.movie.id);
    }
  }

  deleteTag = (tagID) => {
    this.props.deleteTag(this.movie.id, tagID, this.state.search, this.state.page, this.state.limit);
  };

  handlePagination = (page, limit) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('limit', limit.toString());
    query.set('page', page.toString());
    this.setState({page, limit});
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  handleByName = (e) => {
    if (e.type === 'change') {
      return this.setState({search: e.target.value});
    }
    if (e.key !== 'Enter' && e.type !== 'blur') {
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    if (e.type === 'blur' && e.target.value === query.get('search')) {
      return;
    }
    query.set('page', '1');
    query.delete('id');
    query.set('search', e.target.value);
    this.setState({
      id: null,
      page: 1,
      search: e.target.value
    });
    this.props.history.push(`${this.props.location.pathname}?${query.toString()}`);
    const table = document.querySelector(`.${styles.table}`);
    table.scrollTop = 0;
  };

  onChangeTag = (value, id) => {
    clearTimeout(this.tagThrottle[id]);

    const tag = this.state.tags.find(tag => tag.tag_id === id);

    tag.relevance = value;
    tag.relevanceTemp = value;

    this.setState({tags: this.state.tags});

    this.tagThrottle[id] = setTimeout(() => {
      this.props.changeTag(this.movie.id, id, value);
    }, 500);
  };

  createColumns = () => [
    {
      title: (
        <div>
          <div className={styles.title}>Tag Name</div>
          <Input
            value={this.state.search}
            onChange={this.handleByName}
            // onBlur={this.handleByName}
            onKeyUp={this.handleByName}
            placeholder="Search by Film Name"
          />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Weight',
      dataIndex: 'relevanceTemp',
      key: 'relevance',
      render: (relevance, tag) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <InputNumber
            min={0}
            max={1}
            step={0.01}
            style={{marginRight: 16, width: 90}}
            value={relevance}
            onChange={val => this.onChangeTag(val, tag.tag_id)}
          />
          <Slider
            style={{width: '100%', marginTop: 8}}
            min={0}
            max={1}
            marks={marks}
            step={0.000001}
            value={relevance}
            onChange={val => this.onChangeTag(val, tag.tag_id)}
          />
        </div>
      )
    },
    {
      title: (
        <Button style={{width: 90}} type="primary" onClick={this.openModal}>Add tags</Button>
      ),
      dataIndex: 'tag_id',
      key: 'tag_id',
      render: id => <Button style={{width: 90}} type="danger" onClick={() => this.deleteTag(id)}>Delete</Button>
    },
  ];

  closeModal = () => {
    this.setState({tagModalOpen: false, tagSearch: '', tagsFetched: []});
  };

  openModal = () => {
    this.setState({tagModalOpen: true});
  };

  fetchTags = search => {
    const find = async (search) => {
      if (!search.length) {
        this.setState({tagsFetched: [], fetchingTags: false, tagSearch: search});
        return;
      }
      this.setState({tagsFetched: [], fetchingTags: true, tagSearch: search});

      const response = await axios({
        url: `${MOVIE_BOT_URL}/tags/`,
        method: 'GET',
        params: {search, page: 1, page_size: 100}
      });

      const tagsFetched = [];
      response.data.results.forEach(tag => {
        tagsCache[tag.id] = tag.title;

        if (this.state.tags.find(t => t.tag_id.toString() === tag.id.toString())) {
          return;
        }

        tagsFetched.push({
          text: tag.title,
          value: tag.id,
        });
      });
      this.setState({tagsFetched, fetchingTags: false});
    };
    clearTimeout(this.findTimeOut);
    this.findTimeOut = setTimeout(find, 500, search);
  };

  handleChangeTags = values => {
    this.setState({tagsSelected: values});
  };

  handleCleanTags = () => {
    this.setState({tagsSelected: []});
  };

  handleAddTags = () => {
    this.props.addTags(this.state.tagsSelected.map(tag_id => {
      return {
        tag_id,
        key: tag_id,
        relevance: 0,
        title: tagsCache[tag_id],
      };
    }));
    this.handleCleanTags();
    this.closeModal();
  };

  render() {
    if (!this.state.ready) {
      return null;
    }

    if (this.props.movieLoading) {
      return null;
    }

    return (
      <Card
        className={styles.container}
        headStyle={{flexShrink: 0}}
        bodyStyle={{padding: '1px 0 0 0', height: 'calc(100% - 56px)'}}
        title={(
          <React.Fragment>
            <Link to={'/movie-bot/movies'}>Film Select</Link>
            <span className={styles.delimiter}>/</span>
            {this.movie.title}
          </React.Fragment>
        )}
      >
        <div style={{display: 'flex', height: '100%'}}>
          <div className={styles.leftPanel}>
            <div className={styles.movieProps}>
              Film name: <span className={styles.value}>{this.movie.title}</span>
            </div>
            <div className={styles.movieProps}>
              Localized Name: <span className={styles.value}>{this.movie.title_ru}</span>
            </div>
            <div className={styles.movieProps}>
              Year: <span className={styles.value}>{this.movie.year}</span>
            </div>
            <div className={styles.movieProps}>
              Genres: {
              this.movie.genres.map(tag => {
                const name = tag.toUpperCase();
                const color = Color.fromStr(name);
                const darkColor = Color.fromMultiply([color, new Color(1, 1, 1)]);

                return (
                  <Tag
                    key={tag}
                    style={{
                      color: darkColor.toHex(),
                      background: color.toRGBA({alpha: .5}),
                      borderColor: darkColor.toHex(),
                      margin: 3,
                    }}
                  >
                    {name}
                  </Tag>
                );
              })}
            </div>
            <div className={styles.movieProps}>
              Links: {this.movie.links.map((link, i) => (
              <span key={i}>{i ? ', ' : ''}<a target="_blank" href={link.url}>{link.name}</a></span>
            ))}
            </div>
            <div className={styles.movieProps}>
              Actors???: {this.movie.actor_set.map((link, i) => (
              <span key={i}>{i ? ', ' : ''}<a target="_blank" href={link}>{link}</a></span>
            ))}
            </div>

            <div className={styles.similar}>
              Similar Movies

              <Button
                style={{
                  lineHeight: 1,
                  float: 'right',
                  margin: '11px 0',
                }}
                type='primary'
              >
                Update Similar
              </Button>

            </div>

          </div>
          <div className={styles.rightPanel}>
            <Table
              loading={this.props.tagLoading}
              className={styles.table}
              columns={this.createColumns()}
              dataSource={this.state.tags || []}
              pagination={false}
            />

            <Pagination
              style={{
                textAlign: 'center',
                padding: 16,
              }}
              showSizeChanger
              onChange={this.handlePagination}
              onShowSizeChange={this.handlePagination}

              defaultPageSize={this.state.limit}
              defaultCurrent={this.state.page}

              total={this.props.count}
            />
          </div>
        </div>

        <Modal
          className={styles.modal}
          visible={this.state.tagModalOpen}
          title="Add Tags"
          onOk={this.handleAddTags}
          onCancel={this.closeModal}
          footer={[
            <Button key="clean" onClick={this.handleCleanTags}>
              Clean Tags
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleAddTags}>
              Add Tags
            </Button>
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select tags"
            notFoundContent={
              this.state.fetchingTags
                ? <Spin size="small"/>
                : this.state.tagSearch.length === 0
                ? "enter query"
                : "not found"
            }
            filterOption={false}
            onSearch={this.fetchTags}
            onChange={this.handleChangeTags}
            style={{width: '100%'}}
            value={this.state.tagsSelected}
          >
            {this.state.tagsFetched.map(tag => (
              <Select.Option key={tag.value}>{tag.text}</Select.Option>
            ))}
          </Select>
        </Modal>

      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    count: state.movieBotReducers.tag.count,
    tagLoading: state.movieBotReducers.tag.loading,
    tags: state.movieBotReducers.tag.tags,
    movieLoading: state.movieBotReducers.movie.loading,
    movies: state.movieBotReducers.movie.movies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTags: (search, page, page_size, id) => dispatch(fetchTags({search, page, page_size, id})),
    fetchMovie: (id) => dispatch(fetchMovies({id})),
    addTags: (tags) => dispatch(addTags({tags})),
    changeTag: (movieID, tagID, value) => dispatch(changeTag({movieID, tagID, value})),
    deleteTag: (movieID, tagID, search, page, page_size) => dispatch(deleteTag({
      movieID,
      tagID,
      search,
      page,
      page_size
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilmEditContainer);
