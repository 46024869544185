import React from "react";
import {connect} from "react-redux";
import {Button, Modal} from 'antd';
import styles from './DiglexGroupSettingsContainer.module.scss';
import groupActions from 'ducks/group/groupActions';

class DiglexGroupSettingsContainer extends React.Component {

  confirm = () => {
    const modal = Modal.confirm({
      title: `Удалить ${this.props.activeGroup.name}`,
      content: 'Удаленная группа не может быть восстановлена! Вы уверены?',
      okType: 'danger',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => {
        this.props.delete(this.props.activeGroup._id);
        modal.destroy();
      }
    });
  };

  render() {
    return (
      <div className={styles.page}>

        <div className={styles.pageHeader}>
          <div className={styles.space}/>

          {this.props.activeGroup.permissions.includes('delete') && (
            <Button
              onClick={this.confirm}
              type={"danger"}
              loading={this.props.loading}
            >
              Удалить группу
            </Button>
          )}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeGroup: state.group.activeGroup,
    loading: state.group.form.delete.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    delete: (id) => dispatch(groupActions.deleteGroup(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexGroupSettingsContainer);
