import ActionTypes from './ActionTypes';

export const initialState = {
  locale: 'ru',
};

export default function intl(state = initialState, action) {
  if (action.type !== ActionTypes.SET_LOCALE) {
    return state;
  }
  return {...state, ...action.payload};
}