exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexMemberInviteContainer_container1280__3Opvf {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexMemberInviteContainer_container1280__3Opvf {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexMemberInviteContainer_container__K6K-0 {\n  width: 100%;\n  height: 100%; }\n\n.DiglexMemberInviteContainer_title__1qix9 {\n  text-align: center;\n  font-size: 16px;\n  margin: 24px; }\n\n.DiglexMemberInviteContainer_url__1_z_6 {\n  color: #999;\n  font-size: 12px; }\n\n.DiglexMemberInviteContainer_radio__1S_Gb {\n  display: block;\n  height: 18px;\n  line-height: 18px; }\n\n.DiglexMemberInviteContainer_radioInfo__1Yf7H {\n  line-height: 16px;\n  color: #999;\n  margin: 4px 0 16px 24px; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexMemberInviteContainer_container1280__3Opvf",
	"container": "DiglexMemberInviteContainer_container__K6K-0",
	"title": "DiglexMemberInviteContainer_title__1qix9",
	"url": "DiglexMemberInviteContainer_url__1_z_6",
	"radio": "DiglexMemberInviteContainer_radio__1S_Gb",
	"radioInfo": "DiglexMemberInviteContainer_radioInfo__1Yf7H"
};