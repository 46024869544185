import React from "react";
import {Spin, Table} from "antd";
import {Scrollbars} from 'react-custom-scrollbars';

import "./DiglexExtractResult.css";

const columns = [
  {
    title: "Текст",
    dataIndex: "text",
    key: "name"
  },
  {
    title: "Класс",
    dataIndex: "class",
    key: "class",
    render: (text, object) => (
      <div className="diglex-class-tag"
           style={{backgroundColor: object.color.toRGBA()}}
      >
        {text}
      </div>
    )
  },
  {
    title: "Шаблон",
    dataIndex: "template",
    key: "template"
  },
  {
    title: "Свойства",
    dataIndex: "properties",
    key: "properties",
    render: properties => (
      <ul>
        {Object.entries(properties).map(([key, value]) => {
          return (
            <li key={"object- " + " " + key}>
              {key} = {value}
            </li>
          );
        })}
      </ul>
    )
  }
];

class DiglexExtractResult extends React.Component {
  render() {
    if (this.props.isWaitingExtractResult) {
      return (
        <div className="diglex-extract-result-wrap">
          <Spin/>
        </div>
      );
    }

    if (this.props.extractResult) {
      if ("diglex-stream-error" in this.props.extractResult) {
        return (
          <div className="diglex-extract-result-wrap">
            {this.props.extractResult["diglex-stream-error"]};
          </div>
        );
      }
      let objects = this.props.extractResult.objects;

      return (
        <Scrollbars>
          <Table
            style={{'background':'#fff'}}
            rowClassName={record => record.uid}
            rowKey={record => record.uid}
            dataSource={objects}
            columns={columns}
            pagination={false}
            onRow={record => ({
              onMouseEnter: () => {
                record.markRefs.forEach((mark, index) => mark.setActiveState(true, index, record.markRefs.length))
              },
              onMouseLeave: () => {
                record.markRefs.forEach(mark => mark.setActiveState(false))
              },
            })}
          />
        </Scrollbars>
      );
      // return (
      //   <div className="diglex-extract-result-wrap">
      //     Объектов: {this.props.extractResult.objects_count}
      //     {objects.map(function(object, index) {
      //       if (object) {
      //         var properties = object.properties;
      //         return (
      //           <div key={"object-" + index}>
      //             <br />
      //             Текст: {object.text} <br />
      //             Класс: {object.class} <br />
      //             Шаблон: {object.template} <br />
      //             Свойства:{" "}
      //             <ul>
      //               {" "}
      //               {Object.entries(properties).map(([key, value]) => {
      //                 return (
      //                   <li key={"object- " + index + " " + key}>
      //                     {key} = {value}
      //                   </li>
      //                 );
      //               })}{" "}
      //             </ul>
      //           </div>
      //         );
      //       }
      //     })}
      //   </div>
      // );
    } else {
      return (
        <div className="diglex-extract-result-wrap">
          Окно вывода результатов.
        </div>
      );
    }
  }
}

export default DiglexExtractResult;
