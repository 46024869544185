import React, {Component} from 'react';
import {Avatar, Button, Dropdown, Icon, Input, Menu} from 'antd';
import styles from './DiglexProjectToolbar.module.scss';
import {Link} from 'react-router-dom';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';

class DiglexProjectToolbar extends Component {
  state = {
    toggled: false,
    toggledSearch: false,
    filterVisible: false,
  };

  handleVisibleChange = flag => {
    this.setState({filterVisible: flag});
  };

  toggle = toggled => this.setState({toggled});

  handler = ({key}) => {
    this.toggle(false);
    switch (key) {
      case "settings": {
        this.props.history.push(`${this.props.match.url}/settings`);
        break;
      }
      case "members": {
        this.props.history.push(`${this.props.match.url}/members`);
        break;
      }
      default:
    }
  };

  render() {
    const groupMenu = (
      <Menu onClick={this.handler}>
        <Menu.Item key={'members'}>
          <Icon type="team"/>
          Учасники проекта
        </Menu.Item>
        <Menu.Item key={'settings'}>
          <Icon type="setting"/>
          Настройки проекта
        </Menu.Item>
      </Menu>
    );

    const filterMenu = (
      <div className={styles.filterMenu}>
        <Input
          value={this.props.fileFilter}
          placeholder="Поиск файлов"
          onChange={(e) => this.props.setFilter(e.target.value)}
        />
        <a
          className={styles.reset}
          onClick={() => {
            this.props.setFilter('');
            this.handleVisibleChange(false);
          }}
        >
          Очистить
        </a>
      </div>
    );

    return (
      <div>
        <div className={styles.wrapper}>
          {this.props.activeProject.type === 'user' ?
            <Avatar icon="user" title="user project"/>
            :
            <Avatar icon="team" title="group project"/>
          }

          <div className={styles.title}>
            {this.props.activeProject.name}
          </div>

          {this.props.activeProject.access === 'public' ?
            <Icon className={styles.icon} type="book" theme="outlined" title="публичный проект"/>
            :
            <Icon className={styles.icon} type="lock" theme="filled" title="закрытый проект"/>
          }

          <div className={styles.space}/>

          {false ?
            <Link to={`/projects/fork?fullPath=${this.props.activeProject.fullPath}`} title={"клонировать"}>
              <Icon type="share-alt" className={styles.iconLink}/>
            </Link>
            :
            <HeaderDropdown
              overlay={groupMenu}
              overlayClassName={styles.dropDown}
              onVisibleChange={this.toggle}
              placement="bottomLeft"
              trigger={['click']}
            >
              <div className={styles.toggleWrapper}>
                <Icon className={this.state.toggled ? styles.iconToggled : styles.iconMenu} type="control"/>
              </div>
            </HeaderDropdown>
          }
        </div>

        <div className={styles.panel}>
          <div className={styles.files}>Файлы</div>

          <div className={styles.space}/>

          <Dropdown
            overlay={filterMenu}
            trigger={['click']}
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.filterVisible}
          >
            <Icon
              className={`${styles.btn} ${styles.filter} ${this.props.fileFilter ? styles.active : null}`}
              theme={this.props.fileFilter ? "filled" : "outlined"}
              type="filter"
              title="Поиск файлов"
            />
          </Dropdown>

          <Button
            disabled={!this.props.activeProject.permissions.includes('editFiles')}
            className={styles.btn}
            type="default"
            icon="file-add"
            title="Создать Новый файл"
            onClick={this.props.onNewFile}
          >
          </Button>
        </div>

      </div>
    );
  }
}

export default DiglexProjectToolbar;