const ActionTypes = {
  MOVIE_BOT_CHATS_REQUEST: 'MOVIE_BOT_CHATS_REQUEST',
  MOVIE_BOT_CHATS_FULFILLED: 'MOVIE_BOT_CHATS_FULFILLED',
  MOVIE_BOT_CHATS_FAIL: 'MOVIE_BOT_CHATS_FAIL',

  MOVIE_BOT_CHAT_REQUEST: 'MOVIE_BOT_CHAT_REQUEST',
  MOVIE_BOT_CHAT_FULFILLED: 'MOVIE_BOT_CHAT_FULFILLED',
  MOVIE_BOT_CHAT_FAIL: 'MOVIE_BOT_CHAT_FAIL',
};

export default ActionTypes;
