exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MovieBotLayout_header__zVxvv {\n  background: #001529;\n  color: #fff;\n  height: 40px;\n  line-height: 40px;\n  padding: 0 12px;\n  display: flex;\n  box-shadow: 0 2px 3px rgba(0, 21, 41, 0.35);\n  z-index: 10; }\n  .MovieBotLayout_header__zVxvv > .MovieBotLayout_action__rjhvF {\n    color: #fff; }\n  .MovieBotLayout_header__zVxvv > .MovieBotLayout_space__1u3Eo {\n    flex-grow: 1; }\n\n.MovieBotLayout_menu__1BB7a, .MovieBotLayout_menuToggled__33Kxe {\n  box-sizing: border-box;\n  font-size: 14px;\n  margin: 4px;\n  padding: 0 12px;\n  line-height: 32px;\n  height: 32px;\n  -webkit-transition: background-color ease .3s;\n  transition: background-color ease .3s;\n  border-radius: 6px;\n  cursor: pointer;\n  color: rgba(255, 255, 255, 0.65); }\n  .MovieBotLayout_menu__1BB7a:hover, .MovieBotLayout_menuToggled__33Kxe:hover {\n    color: #fff; }\n\n.MovieBotLayout_menuToggled__33Kxe {\n  color: #fff;\n  background: #1890ff; }\n\n.MovieBotLayout_dropDown__3n84X {\n  min-width: 200px; }\n", ""]);

// Exports
exports.locals = {
	"header": "MovieBotLayout_header__zVxvv",
	"action": "MovieBotLayout_action__rjhvF",
	"space": "MovieBotLayout_space__1u3Eo",
	"menu": "MovieBotLayout_menu__1BB7a",
	"menuToggled": "MovieBotLayout_menuToggled__33Kxe",
	"dropDown": "MovieBotLayout_dropDown__3n84X"
};