exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FilmEditContainer_container1280__3TlSD, .FilmEditContainer_container__3RYIF {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .FilmEditContainer_container1280__3TlSD, .FilmEditContainer_container__3RYIF {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.FilmEditContainer_container__3RYIF {\n  height: 1px;\n  min-height: calc(100vh - 104px);\n  display: flex;\n  flex-direction: column; }\n\n.FilmEditContainer_delimiter__9rVXN {\n  margin: 0 8px;\n  font-weight: 300; }\n\n.FilmEditContainer_title__2r6X1 {\n  margin-bottom: 4px; }\n\n.FilmEditContainer_leftPanel__3O5ze {\n  flex-basis: 400px; }\n\n.FilmEditContainer_rightPanel__2IGUE {\n  border-left: 1px solid #e8e8e8;\n  flex-basis: 600px;\n  flex-grow: 1; }\n\n.FilmEditContainer_table__1s4e4 {\n  width: 100%;\n  height: 100%;\n  max-height: calc(100% - 64px);\n  overflow: auto; }\n  .FilmEditContainer_table__1s4e4 thead > tr > th:nth-child(2) {\n    width: 50%;\n    padding: 0 24px; }\n  .FilmEditContainer_table__1s4e4 thead > tr > th {\n    padding: 10px 24px; }\n  .FilmEditContainer_table__1s4e4 tbody > tr > td:nth-child(2) {\n    width: 50%;\n    padding: 0 24px; }\n  .FilmEditContainer_table__1s4e4 tbody > tr > td {\n    padding: 16px 24px; }\n\n.FilmEditContainer_movieProps__2QUkB {\n  padding: 12px 24px 0 24px; }\n  .FilmEditContainer_movieProps__2QUkB .FilmEditContainer_value__20amY {\n    font-weight: bold;\n    display: inline-block; }\n\n.FilmEditContainer_similar__2JKC9 {\n  background: #fafafa;\n  height: 56px;\n  line-height: 56px;\n  margin-top: 12px;\n  padding: 0 24px;\n  border-bottom: 1px solid #e8e8e8;\n  border-top: 1px solid #e8e8e8; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "FilmEditContainer_container1280__3TlSD",
	"container": "FilmEditContainer_container__3RYIF",
	"delimiter": "FilmEditContainer_delimiter__9rVXN",
	"title": "FilmEditContainer_title__2r6X1",
	"leftPanel": "FilmEditContainer_leftPanel__3O5ze",
	"rightPanel": "FilmEditContainer_rightPanel__2IGUE",
	"table": "FilmEditContainer_table__1s4e4",
	"movieProps": "FilmEditContainer_movieProps__2QUkB",
	"value": "FilmEditContainer_value__20amY",
	"similar": "FilmEditContainer_similar__2JKC9"
};