import React, {Component} from 'react';

import styles from './DiglexInfo.module.scss';
import ReactMarkdown from 'react-markdown';
import {Scrollbars} from 'react-custom-scrollbars';

// import {DIGLEX_HELP_TEXT} from '../../../constants/text/diglexHelp';

class DiglexInfo extends Component {

  render() {
    return (
      <div className={styles.markdownWrap}>
        <Scrollbars>
          <ReactMarkdown
            className={styles.markdown}
            // source={DIGLEX_HELP_TEXT}
            escapeHtml={false}
          />
        </Scrollbars>
      </div>
    );
  }
}

export default DiglexInfo;