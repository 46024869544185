import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import styles from './MarkdownContainer.module.scss';
import './styles.scss';
import ReactMarkdown from 'react-markdown';
import {Scrollbars} from 'react-custom-scrollbars';
import AceEditor from "react-ace";
import {Link} from 'react-router-dom';
import scrollIntoView from 'scroll-into-view';
import VisibilitySensor from 'react-visibility-sensor';
import {baseHelpPath, helpRoutes} from 'config/helpRoutes';
import {Dropdown, Icon, Menu, Switch} from 'antd';
import {connect} from 'react-redux';
import userActions from 'ducks/user/userActions';

function copyToClipboard(text) {
  const input = document.createElement('input');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(input);
}

function codeBlock(props) {
  const className = props.language && "language-".concat(props.language);
  const code = createElement('code', className ? {
    className: className
  } : null, props.value);
  return createElement('pre', getCoreProps(props), code);
}

function getCoreProps(props) {
  return props['data-sourcepos'] ? {
    'data-sourcepos': props['data-sourcepos']
  } : {};
}

const markdownNavigation = (navigation) => {
  return navigation.map(props => {
    return (
      <div
        onClick={() => {
          const node = document.querySelector(`#${props.uid}`);
          if (node) {
            scrollIntoView(node, {
              align: {
                top: 0,
              },
            });
          }
        }}
        key={props.key}
        title={props.text}
        id={`help-navigation-${props.uid}`}
        className={`${styles.menuItem} ${styles[props.level]}`}
      >
        {props.text}
      </div>
    );
  });
};

class MarkdownContainer extends React.Component {
  headings = [];

  state = {
    navigation: [],
    ready: false,
  };

  constructor(props) {
    super(props);
    this.path = Object.keys(helpRoutes).find(path => {
      return helpRoutes[path].title === this.props.title && helpRoutes[path].source === this.props.source;
    });
    if (props.isModal) {
      this.hash = decodeURI(props.hash).replace('#', '');
    } else {
      this.hash = decodeURI(window.location.hash).replace('#', '');
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.title !== this.props.title || nextProps.source !== this.props.source) {
      this.headings = [];
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.title !== this.props.title || prevProps.source !== this.props.source) {
      this.setState({
        ready: true,
        navigation: this.headings,
      });
    }
  }

  componentDidMount() {
    this.setState({
      ready: true,
      navigation: this.headings,
    });
  };

  helpContents = (
    <Menu>
      {Object.keys(helpRoutes).map(path => {
        if (this.props.isModal) {
          return (
            <Menu.Item key={path}>
              <a onClick={() => this.props.showDrawer(helpRoutes[path])}>
                {helpRoutes[path].title}
              </a>
            </Menu.Item>
          );
        }
        return (
          <Menu.Item key={path}>
            <Link to={baseHelpPath + path}>
              {helpRoutes[path].title}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  link = (props) => {
    const renderNode = () => props.children[0];

    if (props.href.includes('://')) {
      return <a target="_blank" href={props.href}>{renderNode()}</a>;
    }

    if (this.props.isModal) {
      const url = new URL(document.location.origin + props.href);
      const hash = url.hash.replace('#', '');

      return <a onClick={() => this.props.showDrawer(helpRoutes[url.pathname], hash)}>
        {renderNode()}
      </a>;
    }

    return <Link to={baseHelpPath + props.href}>{renderNode()}</Link>;
  };

  heading = (props) => {
    const key = `${this.headingCount}-${props.level}-${props.children[0].props.value}`;

    let current = this.headings.find(node => node.key === key);

    if (!current) {
      current = {
        key,
        visible: false,
        level: props.level,
        text: props.children[0].props.value,
        uid: `helpNavigation-${this.headingCount}`,
      };
      this.headings.push(current);
    }

    const $h = `h${props.level}`;

    if (!$h) {
      console.error('workaround');
    }

    this.headingCount++;

    const classLink = current.text.replace(/[^ _а-яА-Яa-zA-Z0-9ёЁ]/g, '').replace(/ /g, '_');
    const className = `heading ${classLink} ${styles.heading}`;

    const link = () => (
      <span
        onClick={() => copyToClipboard(`${document.location.origin}${baseHelpPath}${this.path}#${classLink}`)}
        title={"Скопировать ссылку на параграф"}
      >
        <Icon type="link" className={styles.headingIcon}/>
      </span>
    );

    if (!this.state.ready) {
      return (
        <div className={styles.headingWrapper}>
          <$h children={props.children} id={current.uid} className={className}/>
          {link()}
        </div>
      );
    }

    return (
      <VisibilitySensor
        containment={document.querySelector('#help-markdown')}
        onChange={visible => {
          current.visible = visible;
          // const node = document.querySelector(`#help-navigation-${current.uid}`);
          // node && node.classList.toggle('help-navigation-active', visible);

          const firstVisible = Object.entries(this.state.navigation).find(e => e[1].visible);
          if (firstVisible) {
            Object.entries(this.state.navigation).forEach(e => {
              const node = document.querySelector(`#help-navigation-${e[1].uid}`);
              node && node.classList.remove('help-navigation-active');
            });
            const node = document.querySelector(`#help-navigation-${firstVisible[1].uid}`);
            node && node.classList.add('help-navigation-active');
          }

        }}>
        <div className={styles.headingWrapper}>
          <$h children={props.children} id={current.uid} className={className}/>
          {link()}
        </div>

      </VisibilitySensor>
    );
  };

  code = (props) => {
    if (props.language.toLowerCase() !== 'yaml') {
      return codeBlock(props);
    }
    return (
      <div className={styles.aceWrap}>
        <AceEditor
          className={styles.ace}
          fontSize={12}
          readOnly={true}
          mode="yaml"
          theme="textmate"
          width="100%"
          maxLines={Infinity}
          editorProps={{$blockScrolling: true}}
          showPrintMargin={false}
          highlightActiveLine={false}
          showGutter={false}
          value={props.value}
        />
      </div>
    );
  };

  render() {
    this.headingCount = 0;

    if (this.state.ready && this.hash) {
      const node = document.querySelector(`.heading.${this.hash}`);
      scrollIntoView(node, {
        time: 0,
        align: {
          top: 0,
        },
      });
    }

    return (
      <div className={styles.page}>

        <div className={styles.pageHeader}>
          <div className={styles.text}>
            <Dropdown overlay={this.helpContents} overlayClassName={styles.pageHeaderDropdown} trigger={['click']}>
              <a href="#">
                {this.props.title} <Icon type="down"/>
              </a>
            </Dropdown>
          </div>
        </div>

        <div className={styles.navigation}>
          {markdownNavigation(this.state.navigation)}
        </div>

        <div className={styles.markdownWrap}>
          <Scrollbars>
            <ReactMarkdown
              id={"help-markdown"}
              className={styles.markdown}
              source={this.props.source ? this.props.source : 'DIGLEX_HELP_TEXT'}
              escapeHtml={false}
              renderers={{
                link: this.link,
                heading: this.heading,
                code: this.code,
              }}
            />
          </Scrollbars>
        </div>
        {this.props.isModal && this.props.footer && (
          <div className={styles.pageFooter}>
            <div className={styles.text}>
              <Switch
                onChange={checked => {
                  const preferences = {...this.props.preferences, showHelpAtStart: checked};
                  this.props.changePreferences(preferences);
                }}
                checked={this.props.preferences.showHelpAtStart}
                checkedChildren="показывать при входе"
                unCheckedChildren="показывать при входе"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

MarkdownContainer.propTypes = {
  isModal: PropTypes.bool,
  title: PropTypes.string,
  source: PropTypes.string,
  footer: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    preferences: state.user.preferences,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePreferences: (preferences) => dispatch(userActions.changePreferences(preferences)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkdownContainer);