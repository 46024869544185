export default {
    title: 'Diglex Stream',
    source: `
Diglex Stream
============

**Diglex Stream** (diglex_stream) это консольное приложение, которое принимает команды в формате JSON через STDIN и отдаёт результат в формате JSON в STDOUT.

## Простой пример
Запуск diglex_stream для однократной обработки одного фрагмента текста:

Linux:
\`\`\`bash
$ echo '{"action": "extract", "text": "В СССР был ..."}' | diglex_stream <путь-к-словарю>
\`\`\`
Windows:
\`\`\`cmd
echo {"action": "extract", "text": "В СССР был ..."} | diglex_stream <путь-к-словарю> 
\`\`\`


## Запуск diglex_stream
В примере выше осуществляется передача данных на STDIN diglex_stream, после чего diglex_stream выводит результат в STDOUT и завершает свою работу.

Может быть удобным, загрузив словарь однажды, применять его для поиска на разных текстах, не завершая работу diglex_stream, в таком случае diglex_stream следует запускать следующим способом:

\`\`\`bash
diglex_stream <путь-к-словарю>
\`\`\`

При таком способе запуска diglex_stream загрузит словарь и будет реагировать на команды пришедшие на STDIN, пока не придёт специальная команда завершения или сигнал от клавиатурной комбинации **CTRL**+**C**.

Приходящие на STDIN данные должны быть представленны в виде виде JSON комманд.

## Команды

**Комада поиска объектов на тексте**
\`\`\`json
{"action": "extract", "text": "текст"}
\`\`\`

**Команда вывода краткой мета-информации о загруженном словаре**
\`\`\`json
"{\"action\":\"tree\"}"
\`\`\`

**Команда обновления/добавления/удаления содержимого файла**
\`\`\`json
{"action":"reload","path":"<имя файла>"}
\`\`\`

**Команда завершения работы**
\`\`\`json
{"action":"end"}
\`\`\`

## Описание команд

**Поиск объектов**

В случае если содержимое текста соответствует какому-либо шаблону из словаря, будет выдан ответ в JSON формате, содержащий:

1. количество извлечённых объектов (objects_count)
2. список объектов (objects)

Каждый объект в списке представлен данными:

1. начало и конец вхождения в текст ("begin"/"end")
2. информация к какому классу принадлежит объект ("class")
3. имя шаблона, по которому был найден объект ("template")
4. текст который соответствует шаблону ("text")
5. Вес шаблона, создавшего объект ("weight")
6. Набор всех характеристик объекта ("properties"), если объект не обладает
характеристиками, в поле properties будет всего одна характеристика "Value", соотвествующая
описанному выше полю "text".

\`\`\`json
{
    "objects_count": 1,
    "objects": [
        {
            "text": "СССР",
            "begin": 0,
            "end": 4,
            "class": "СССР",
            "template": "СССР",
            "properties": {
                "Value": "СССР"
            },
            "weight": 0
        }
    ]
}
\`\`\`

**Вывод метаинформация о словаре**

Данная команда может использоваться клиентами diglex_stream, 
Выводит два списка объектов словаря. Первый спискок - шаблонов, второй - классов. В объектах списка представлена информация о файле в котором определён объект и имени объекта. Так же для шаблонов указан класс, к которому они принадлежат, для классов указываются классы -родители (если есть).

\`\`\`json
{
    "templates": [
        {
            "path": "../../../sample_data/org_example/test_case_section.yml",
            "name": "ФМШ при  НГУ",
            "classes": [
                "школа"
            ]
        }
    ],
    "classes": [
        {
            "path": "../../../sample_data/org_example/test_case_section.yml",
            "name": "школа",
            "parents": []
        }
    ]
}
\`\`\`

**Обновление/Добавление/Удаление содержимого файла**

С помощью данной команды можно обновить содержимое изменившегося файла, без необходимости перезагружать весь словарь.

В случае если в измененном файле есть ошибки вызов данной команды не приведёт к изменениям в загруженных данных.

С помощью данной команды можно не только изменять содержимое существующего файла, но и добавить содержимое из вновь созданного файла, или удалить содержимое, для удаления необходимо удалить файл из файловой системы и отправить команду с именем удалённого файла:
\`\`\`json
{"action":"reload","path":"<имя удалённого файла>"}
\`\`\`
## Сообщения об ошибках
При возникновении ошибки diglex_stream выводит в STDIN сообщение о возникшей проблеме в JSON формате, в общем виде, имеющее такой формат:

\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[тип-исключения] exception-text=[описание причины]" }
\`\`\`

Такой формат исключений принят на неверные входные данные.

При ошибке возникшей на более низком уровне (возможные причины: не достаточно качественная проверка входных данных или внутренняя логическая ошибка в библиотеке Диглекс) исключения
выдаются в более развёрнутом виде:
\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[тип-исключения] from-emmiter=[имя класса, пославшего исключение] exception-file=[файл в котором находится пославший исключение клас] exception-line=[строка исходного кода в которой было выброшено исключение] exception-text=[описание причины]" }
\`\`\`

В большинстве случаев будут выводиться тексты исключений первого типа (менее детальные).


**Несколько примеров сообщений об ошибках**

1\\. исключение возникшее из-за неопределённой ссылки "ОИИИФФ"
\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[FileParser::ParserSemanticException] exception-text=[[createTemplatesAndClasses] make Exception ParserSemanticException because: not found templates by reference names :\n  reference_name = [ОИИФФ]\n]"}
\`\`\`

2\\. исключение возникшее из-за семантической ошибки в файле, т.е. ошибки уровня языка Диглекс (пропущен один из специальных символов **< >**)
\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[FileParser::ParserSemanticException] exception-text=[In Parsing file [../../../sample_data/org_example/институт.yml]  Unpaired special symbols '< >']" }
\`\`\`

3\\. исключение возникшее из-за синтаксической ошибки уровня yaml
\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[FileParser::ParserStructureException] exception-text=[In Parsing file [../../../sample_data/org_example/институт.yml]  In createClass erroryaml-cpp: error at line 282, column 21: end of sequence not found]" }
\`\`\`

4\\. исключение возникшее из-за наличия символа не причисленного ни к одному из известных библиотеке Диглекс типов (в таком случае нужно добавить символ к одному из типов или определить новый тип, на данный момент типы определяютя в исходном коде, в последующих версиях определение будет перенесено в yaml)
\`\`\`json
{ "diglex-lib-handled-exception": "exception-type=[FileParser::ParserSemanticException] exception-text=[In Parsing file [../../../sample_data/org_example/институт.yml]  Unknow symbol [恋] type detected]" }
\`\`\`

Видно что в 1 не хватает сообщения о файле, а в 2 и 4 о позиции в файле, в поздних версиях diglex_strem сообщения об ошибках будут приведены к более общему виду.

`
};
