import diglexLang from '../constants/helpTexts/diglexLang';
import about from '../constants/helpTexts/about';
import diglexStream from '../constants/helpTexts/diglexStream';
import diglexLibrary from '../constants/helpTexts/diglexLibrary';

export const baseHelpPath = '/help';
export const helpRoutes = {
    '/about': about,
    '/diglexLang': diglexLang,
    '/diglexStream': diglexStream,
    '/diglexLibrary': diglexLibrary,
};

// export const helpContents = {
//   title: 'Справка',
//   markdown: Object.keys(helpRoutes).reduce((text, path, i) => {
//       // helpRoutes[path].title;
//       return text + `[${helpRoutes[path].title}]()`;
//     },
//     '')
// };
