exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".RegistrationFormContainer_wrapper__2EY2y {\n  height: 100vh;\n  display: flex;\n  flex-flow: column;\n  justify-content: space-around;\n  align-items: center; }\n\n.RegistrationFormContainer_space__ppLJS {\n  flex-shrink: 1;\n  flex-basis: 200px; }\n\n.RegistrationFormContainer_btn__kwagp {\n  margin-top: 12px;\n  width: 100%; }\n\n.RegistrationFormContainer_link__KIyZ4 {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "RegistrationFormContainer_wrapper__2EY2y",
	"space": "RegistrationFormContainer_space__ppLJS",
	"btn": "RegistrationFormContainer_btn__kwagp",
	"link": "RegistrationFormContainer_link__KIyZ4"
};