import ActionTypes from './actionTypes';

const initialState = {
  window: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  diglexExtract: {
    width: window.innerWidth / 2 - 250,
  },
  diglexEditor: {
    width: window.innerWidth / 2 - 250,
  },
  diglexTree: {
    width: 250,
  }
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.UI_UPDATE: {
      const {element, prop, value} = payload;

      return {
        ...state,
        [element]: {
          ...state[element],
          [prop]: value,
        }
      };
    }
    default:
      return state;
  }
}
