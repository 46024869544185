import React from "react";
import {connect} from "react-redux";
import {Button, Card, Cascader, Form, Icon, Input, Radio, Spin} from 'antd';
import styles from './DiglexProjectCreateContainer.module.scss';
import groupActions from 'ducks/group/groupActions';
import projectActions from 'ducks/project/projectActions';

const url = new URL(document.location);

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
    lg: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 0},
    lg: {span: 12, offset: 0},
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 6},
    lg: {span: 12, offset: 6},
  },
};

class DiglexProjectCreateContainer extends React.Component {

  componentDidMount() {
    this.props.fetchGroups();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createProject(values);
      }
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setup();
    if (this.props.groupsList !== nextProps.groupsList) {
      this.props.form.setFields({});
      this.inited = false;
    }
    if (this.props.projectCreateForm.errors !== nextProps.projectCreateForm.errors) {
      this.props.form.setFields(nextProps.projectCreateForm.errors);
    }
  }

  setup = () => {
    if (!this.inited && this.props.groupsList.values.length) {
      const query = new URLSearchParams(this.props.location.search);
      const id = query.get('group');
      if (id) {
        this.props.form.setFieldsValue({type: ['group', id]});
      }
      this.inited = true;
    }
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    if (this.props.groupsList.loading) {
      return <Spin size={'large'} style={{margin: 64, width: '100%'}}/>;
    }

    const projectTypes = [
      {
        value: 'user',
        label: 'Персональный',
      },
      {
        value: 'group',
        label: 'Группы',
        disabled: this.props.groupsList.values.length === 0,
        children: this.props.groupsList.values.map(group => ({value: group._id, label: group.name})),
      },
    ];

    let prefixSelector = null;
    let type = this.props.form.getFieldValue('type');

    if (type) {
      if (type[0] === 'user') {
        prefixSelector = (
          <div className={styles.url}>{`${url.protocol}//${url.host}/user/${this.props.profile.username}/`}</div>
        );
      } else {
        const group = this.props.groupsList.values.find(group => group._id === type[1]);
        prefixSelector = (
          <div className={styles.url}>{`${url.protocol}//${url.host}/group/${group.path}/`}</div>
        );
      }
    }

    return (
      <Card
        className={styles.container}
        title="Новый проект"
        headStyle={{textAlign: 'center'}}
      >
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="Тип проекта">
            {getFieldDecorator('type', {
              initialValue: ['user'],
              rules: [
                {type: 'array', required: true, message: 'Выберите тип проекта'},
              ],
            })(<Cascader options={projectTypes}/>)}
          </Form.Item>

          <Form.Item label="Название проекта">
            {getFieldDecorator('name', {
              rules: [
                {required: true, message: 'Введите название проекта'},
              ],
            })(
              <Input
                placeholder="Название проекта"
              />
            )}
          </Form.Item>

          <Form.Item label="URL проекта">
            {getFieldDecorator('path', {
              rules: [
                {required: true, message: 'Выберите URL проекта'},
                {pattern: /^[A-Za-z0-9\-_]+$/, message: 'Выберите URL без специальных символов'},
              ],
            })(<Input addonBefore={prefixSelector} style={{width: '100%'}}/>)}
          </Form.Item>

          <Form.Item label="Описание проекта">
            {getFieldDecorator('description')(
              <Input.TextArea
                style={{minHeight: 86}}
                placeholder="Описание проекта"
              />
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator('access', {initialValue: 'private'})(
              <Radio.Group>
                <Radio className={styles.radio} value={'private'}>
                  <Icon style={{fontSize: 15}} type="lock" theme="filled"/>
                  {' '}
                  Закрытый проект
                </Radio>
                <div className={styles.radioInfo}>
                  Доступ к проекту должен быть предоставлен явно каждому пользователю.
                </div>
                <Radio className={styles.radio} value={'public'}>
                  <Icon style={{fontSize: 15}} type="book"/>
                  {' '}
                  Публичный проект
                </Radio>
                <div className={styles.radioInfo}>
                  Доступ к проекту может получить любой зарегистрированный пользователь (только на чтение и клонирование).
                </div>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{marginBottom: 0}}>
            <Button
              loading={this.props.projectCreateForm.loading}
              style={{minWidth: '45%'}}
              size={'large'}
              type='primary'
              htmlType='submit'
            >
              Создать проект
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile,
    groupsList: state.group.form.list,
    projectCreateForm: state.project.form.create,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(groupActions.fetchGroups('user-groups')),
    createProject: ({type, name, path, description, access}) => dispatch(projectActions.createProject({
      type,
      name,
      path,
      description,
      access
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(DiglexProjectCreateContainer));
