import React from 'react';
import {Menu, Icon} from 'antd';
import styles from './HeaderLogo.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';

export default class HeaderLogo extends React.Component {

  render() {
    return (
        <div className={styles.menu}>
          <div className={styles.logo} />
          {/*<span className={styles.capital}>D</span>iglex*/}
        </div>
    );
  }
}
