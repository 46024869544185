import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {normalizePath} from '../../utils';
//import SchemaActions from 'ducks/schema/projectActions';
import {columnsRender, recordInteractions, columnsFilter} from 'components/RecordTable/constants';
import RecordEdit, {RecordEditType, RecordFieldType} from 'components/RecordEdit/RecordEdit';
import ModalActions from '../../ducks/modal/actions';
import RecordsTable from '../../components/RecordTable/RecordsTable';
import RecordActions from '../../ducks/record/Actions';

const entity = 'Text';
const relations = ['collection'];

const interactions = [
  recordInteractions.view,
  recordInteractions.create,
  recordInteractions.update,
  recordInteractions.delete,
];

const tableFields = [
  {
    title: 'Название',
    schema: 'title',
    render: columnsRender.editRecord,
    filter: columnsFilter.search,
  },
  {
    title: 'Коллекция (id)',
    schema: 'collection',
    render: columnsRender.relation,
    filter: columnsFilter.search,
  }, {
    title: 'Результаты анализа',
    schema: 'title',
    render: (v, r, data) => {
      return 'un done';
    },
  },
];

// edit

const editFields = [
  {
    title: 'Название',
    type: RecordFieldType.string,
    schema: 'title'
  },
  {
    title: 'Текст',
    type: RecordFieldType.text,
    schema: 'text'
  },
  {
    title: 'Коллекция',
    type: RecordFieldType.selectRelation,
    schema: 'collection'
  },
];

class TextsContainer extends React.Component {

  render() {
    const path = normalizePath(`${this.props.match.path}`);

    return (
      <Switch>
        <Route
          key={'list'}
          path={`${path}`}
          exact={true}
          component={props =>
            <RecordsTable
              location={this.props.location}
              history={this.props.history}
              match={this.props.match}

              interactions={interactions}
              entity={entity}
              relations={relations}
              tableFields={tableFields}

              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
            />}
        />
        <Route
          key={'create'}
          path={`${path}/create`}
          component={props =>
            <RecordEdit
              type={RecordEditType.create}

              interactions={interactions}

              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
              entity={entity}
              relations={relations}
              editFields={editFields}

              showModal={this.props.showModal}
              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
              deleteRecord={this.props.deleteRecord}
              createRecord={this.props.createRecord}
              updateRecord={this.props.updateRecord}
            />}
        />
        <Route
          key={'edit'}
          path={`${path}/edit/:id`}
          component={props =>
            <RecordEdit
              type={RecordEditType.update}

              interactions={interactions}

              location={this.props.location}
              history={this.props.history}
              match={this.props.match}
              entity={entity}
              relations={relations}
              editFields={editFields}

              showModal={this.props.showModal}
              records={this.props.records}
              getRecords={this.props.getRecords}
              flushRecords={this.props.flushRecords}
              deleteRecord={this.props.deleteRecord}
              createRecord={this.props.createRecord}
              updateRecord={this.props.updateRecord}
            />}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    records: state.records
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modalType, modalProps) => dispatch(ModalActions.showModal(modalType, modalProps)),
    //fetchSchema: () => dispatch(SchemaActions.fetchSchema()),
    //fetchRecords: (entity, query) => dispatch(SchemaActions.fetchRecords(entity, query)),
    getRecords: (entity, query, relations) => dispatch(RecordActions.getRecords(entity, query, relations)),
    flushRecords: () => dispatch(RecordActions.flushRecords()),
    deleteRecord: (entity, values, record, navigate) => dispatch(RecordActions.deleteRecord(entity, values, record, navigate)),
    createRecord: (entity, values, navigate) => dispatch(RecordActions.createRecord(entity, values, navigate)),
    updateRecord: (entity, values, record, navigate) => dispatch(RecordActions.updateRecord(entity, values, record, navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextsContainer);