import React from "react";
import {Tabs, Button, Layout} from "antd";
import {Spin, Icon} from "antd";

import SourceEditor from "components/SourceEditor/SourceEditor.js";
import "./SourceEditorTabs.css";

const TabPane = Tabs.TabPane;

function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function appendAsteriskWhenUnsaved(name, saveQueue) {
  if (name in saveQueue) {
    return name + " *";
  } else {
    return name;
  }
}

class SourceEditorTabs extends React.Component {
  render() {
    const indicator = (
      <div>
        <Spin/>
      </div>
    );

    const autoSaveStatus = (
      <div className="saving">
        {isEmptyObject(this.props.saveQueue) ? "" : indicator}
      </div>
    );

    return (
      <div className="card-container">
        <Tabs
          type="editable-card"
          hideAdd="True"
          activeKey={this.props.activeTab ? this.props.activeTab.key : null}
          onTabClick={this.props.onTabClick}
          onEdit={this.props.onEdit}
          tabBarExtraContent={autoSaveStatus}
        >
          {this.props.tabs.map(tab => (
            <TabPane tab={tab.name} key={tab.key}>
              <SourceEditor
                filesLocked={this.props.filesLocked}
                activeProject={this.props.activeProject}
                forceLockFile={this.props.forceLockFile}
                setIsSaveFileDisabled={value =>
                  this.setState({isSaveFileDisabled: value})
                }
                setIsRenameFileDisabled={value =>
                  this.setState({isRenameFileDisabled: value})
                }
                onChange={value => this.props.onSourceChange(tab.name, value)}
                saveFile={this.saveFile}
                ref={instance => {
                  this.sourceEditor = instance;
                }}
                sourceCode={tab.source}
                type={tab.type}
                name={tab.name}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default SourceEditorTabs;
