import React from 'react';

const btnClass = '.gitter-open-chat-button';
const chatClass = '.gitter-chat-embed';

export class GitterChat extends React.Component {

  state = {
    btn: null,
    chat: null,
    timeout: 0,
  };

  setVisible = () => {
    const btn = document.querySelector(btnClass);
    const chat = document.querySelector(chatClass);

    if(btn && chat) {
      btn.style.display = 'block';
      chat.style.display = 'block';
      this.setState({btn, chat});
    } else {
      setTimeout(this.setVisible, 100)
    }
  };

  componentDidMount() {
    this.setVisible();
  }

  componentWillUnmount() {
    clearInterval(this.state.timeout);
    if(this.state.btn) {
      this.state.btn.style.display = 'none';
    }
    if(this.state.chat) {
      this.state.chat.style.display = 'none';
    }
  }

  render() {
    return null;
  }
}

export default GitterChat;