
export default function fieldErrorsToUI(fieldErrors) {
  const errors = {};

  Object.entries(fieldErrors).forEach(([key, object]) => {
    errors[key] = {
      value: object.value,
      errors: object.errors.map(err => new Error(err)),
    }
  });

  return errors;
}