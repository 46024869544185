import {call, put, all, takeEvery, select, take, delay, fork} from 'redux-saga/effects';
import ActionTypes from './ActionTypes';
import {history} from '../store';
import fieldErrorsToUI from 'utils/errorsHelper';
import ProjectActions from './projectActions';
import SourceActions from '../source/Actions';
import {addFileYamlExtension} from '../../utils';
import {BASE_URL} from '../../constants/constants';
import {openWS} from "utils/ws";
import {closeWS} from "../../utils/ws";

function* getWS() {
  const state = yield select();

  return  yield openWS({
    token: state.user.token,
    path: 'ws-ailab',
    onConnect: ws => {
      ws.send(JSON.stringify({
        event: 'projectSubscribe',
        payload: {project: state.project.activeProject.fullPath},
      }));
    }
  });
}

function* create({payload}) {
  const {name, type, path, description, access} = payload;

  try {
    const response = yield call(window.diglexAxios, {
      url: '/api/projects/new-project',
      method: 'POST',
      data: {name, type, path, description, access}
    });
    const {project} = response.data;

    yield put({
      type: ActionTypes.PROJECT_CREATE_FULFILLED,
      payload: {project},
    });
  } catch (e) {
    switch (e.response.status) {
      case 422: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.PROJECT_CREATE_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.PROJECT_CREATE_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* forkProject({payload}) {
  const {from, name, type, path, description, access} = payload;

  try {
    const response = yield call(window.diglexAxios, {
      url: '/api/projects/fork',
      method: 'POST',
      data: {from, name, type, path, description, access}
    });
    const {project} = response.data;

    yield put({
      type: ActionTypes.PROJECT_FORK_FULFILLED,
      payload: {project},
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.PROJECT_FORK_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.PROJECT_FORK_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* update({payload}) {
  const {id, description, access} = payload;

  try {
    yield call(window.diglexAxios, {
      url: '/api/projects/update',
      method: 'POST',
      data: {id, description, access}
    });

    const state = yield select();
    const {fullPath} = state.project.activeProject;

    yield call(fetch, {payload: {type: 'get', fullPath}});

    yield put({
      type: ActionTypes.PROJECT_UPDATE_FULFILLED,
      payload: {},
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.PROJECT_UPDATE_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.PROJECT_UPDATE_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* deleteProject({payload}) {
  const {id} = payload;

  try {
    yield call(window.diglexAxios, {
      url: '/api/projects/delete',
      method: 'POST',
      data: {id}
    });

    yield put({
      type: ActionTypes.PROJECT_DELETE_FULFILLED,
      payload: {},
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = fieldErrorsToUI(e.response.data.fieldErrors);
        yield put({
          type: ActionTypes.PROJECT_DELETE_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.PROJECT_DELETE_FAIL,
          payload: {},
        });
      }
    }
  }
}


function* fetch({payload}) {
  const {type, fullPath} = payload;
  let params = {};

  const state = yield select();

  if (type === 'get') {
    params.fullPath = fullPath;
  }

  if (type === 'group') {
    params.groupId = state.group.activeGroup._id;
  }

  if (state.project.activeProject) {
    yield put(ProjectActions.unsubscribe(state.project.activeProject.fullPath));
  }

  try {
    const response = yield call(window.diglexAxios, {
      url: `/api/projects/${type}`,
      method: 'GET',
      params,
    });

    yield put({
      type: ActionTypes.PROJECT_FETCH_FULFILLED,
      payload: {
        projects: response.data
      },
    });

    if (type === 'get' && response.data.length) {
      yield put(ProjectActions.setActiveProject(response.data[0]));
    }
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        yield put({
          type: ActionTypes.PROJECT_FETCH_FAIL,
          payload: {},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.PROJECT_FETCH_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* createRedirect({payload}) {
  const {project} = payload;
  yield history.push(`/project${project.fullPath}`);
}

function* deleteRedirect() {
  yield history.push(`/`);
}

function* setActiveProject({payload}) {
  const {project} = payload;
  yield put(ProjectActions.subscribe(project.fullPath));
  yield put(SourceActions.removeAllTabs());
  yield put(SourceActions.activateTab(''));
}

function* newFile() {
  let path = null;

  while (1) {
    path = prompt("Имя файла", "");
    if (typeof path !== "string") {
      return;
    }
    if (path.length === 0) {
      alert('Необходимо ввести имя файла');
      continue;
    }
    break;
    // if (/^([^ !$`&*()+]|(\\[ !$`&*()+]))+/.test(path)) {
    //   break
    // }
    // alert('Некорректное имя файла');
  }
  const state = yield select();

  const project = state.project.activeProject;
  const correctedPath = addFileYamlExtension(path);

  const response = yield window.diglexAxios.post(BASE_URL + "/api/sources/new-file", {
    path: correctedPath,
    projectId: project._id,
  });

  yield put(ProjectActions.fetchProjects('get', project.fullPath));
  yield put(ProjectActions.fileSelected(project["name"], response.data));
}

function* renameFile({payload}) {
  const {sourcePath, destinationPath} = payload;

  const state = yield select();

  const project = state.project.activeProject;
  const correctedDestinationPath = addFileYamlExtension(destinationPath);

  yield put(SourceActions.removeTab(`source#${sourcePath}`));

  yield window.diglexAxios({
    method: "post",
    url: BASE_URL + "/api/sources/rename-file",
    params: {
      source_path: sourcePath,
      destination_path: correctedDestinationPath,
      projectId: project._id,
    }
  });

  yield put(SourceActions.renameTab(sourcePath, correctedDestinationPath));
  yield put(ProjectActions.fileSelected(project["name"], correctedDestinationPath));
}

function* deleteFile({payload}) {
  const {path} = payload;
  const state = yield select();
  const project = state.project.activeProject;

  yield put(SourceActions.removeTab(`source#${path}`));
  yield put(ProjectActions.removeFileFromSaveQueue(path));

  yield window.diglexAxios.post(`${BASE_URL}/api/sources/delete-file`, {
    path: path,
    projectId: project._id
  });
}

function* projectSubscribe({payload}) {
  const {fullPath} = payload;

  const ws = yield getWS();
  const {socketChannel} = ws;
  let alive = true;

  let state = yield select();
  if(state.source.activeTab && state.source.activeTab.name) {
    ws.send(JSON.stringify({
      event: 'fileLock',
      payload: {project: state.project.activeProject.fullPath, file: state.source.activeTab.name},
    }));
  }

  while (alive) {
    const message = yield take(socketChannel);
    const {payload} = message;

    switch (message.event) {
      case 'close': {
        alive = false;
        let state = yield select();
        if(state.project.activeProject.fullPath === fullPath) {
          yield delay(1000);
          yield fork(projectSubscribe, {payload: {fullPath}});
        }
        break;
      }

      case 'fileLock': {
        yield put({
          type: ActionTypes.PROJECT_WS_FILES_LOCKED,
          payload: {files: [payload]}
        });
        break;
      }

      case 'fileUnlock': {
        yield put({
          type: ActionTypes.PROJECT_WS_FILES_UNLOCKED,
          payload: {files: [payload]}
        });
        break;
      }

      case 'filesLocked': {
        yield put({
          type: ActionTypes.PROJECT_WS_FILES_LOCKED,
          payload: {files: payload.files}
        });
        break;
      }
    }
  }

}

function* projectUnSubscribe({}) {
  closeWS({path: 'ws-ailab'});
}

function* projectForceLockFile({payload}) {
  const {path} = payload;
  let state = yield select();

  const editFiles = state.project.activeProject.permissions.includes('editFiles');
  if(!editFiles) {
    return ;
  }

  const ws = yield getWS();

  ws.send(JSON.stringify({
    event: 'forceLockFile',
    payload: {project: state.project.activeProject.fullPath, file: path},
  }));
}

function* projectSelectFile({payload}) {
  const {path} = payload;
  let state = yield select();

  const response = yield call(window.diglexAxios, {
    url: BASE_URL + '/api/sources/open-file',
    method: 'GET',
    params: {path, projectId: state.project.activeProject._id}
  });

  state = yield select();

  if (!state.source.tabs.find(item => item.name === path)) {
    yield put(SourceActions.newTab({name: path, source: response.data.toString(), type: 'source'}));
  }

  yield put(SourceActions.activateTab(`source#${path}`));

  const editFiles = state.project.activeProject.permissions.includes('editFiles');
  if(!editFiles) {
    return ;
  }

  const ws = yield getWS();

  if (state.source.activeTab && state.source.activeTab.name) {
    ws.send(JSON.stringify({
      event: 'fileUnlock',
      payload: {project: state.project.activeProject.fullPath, file: state.source.activeTab.name},
    }));
  }

  ws.send(JSON.stringify({
    event: 'fileLock',
    payload: {project: state.project.activeProject.fullPath, file: path},
  }));
}

function* projectOnUnlockFiles({payload}) {
  const {files} = payload;
  let state = yield select();

  if(!state.source.activeTab) {
    return ;
  }

  const file = files.find(file => file.name === state.source.activeTab.name);

  if(file) {
    const ws = yield getWS();
    ws.send(JSON.stringify({
      event: 'fileLock',
      payload: {project: state.project.activeProject.fullPath, file: file.name},
    }));
  }
}

export default function* projectSagas() {
  yield all([
    yield takeEvery(ActionTypes.PROJECT_CREATE_REQUEST, create),
    yield takeEvery(ActionTypes.PROJECT_CREATE_FULFILLED, createRedirect),

    yield takeEvery(ActionTypes.PROJECT_FORK_REQUEST, forkProject),
    yield takeEvery(ActionTypes.PROJECT_FORK_FULFILLED, createRedirect),

    yield takeEvery(ActionTypes.PROJECT_DELETE_REQUEST, deleteProject),
    yield takeEvery(ActionTypes.PROJECT_DELETE_FULFILLED, deleteRedirect),

    yield takeEvery(ActionTypes.PROJECT_UPDATE_REQUEST, update),

    yield takeEvery(ActionTypes.PROJECT_FETCH_REQUEST, fetch),
    yield takeEvery(ActionTypes.SET_ACTIVE_PROJECT, setActiveProject),
    yield takeEvery(ActionTypes.PROJECT_NEW_FILE, newFile),
    yield takeEvery(ActionTypes.PROJECT_RENAME_FILE, renameFile),
    yield takeEvery(ActionTypes.PROJECT_DELETE_FILE, deleteFile),

    yield takeEvery(ActionTypes.PROJECT_SELECT_FILE, projectSelectFile),
    yield takeEvery(ActionTypes.PROJECT_FORCE_LOCK_FILE, projectForceLockFile),
    yield takeEvery(ActionTypes.PROJECT_WS_SUBSCRIBE, projectSubscribe),
    yield takeEvery(ActionTypes.PROJECT_WS_UNSUBSCRIBE, projectUnSubscribe),
    yield takeEvery(ActionTypes.PROJECT_WS_FILES_UNLOCKED, projectOnUnlockFiles),

  ]);
}