exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexProjectPageContainer_container1280__3ZapS, .DiglexProjectPageContainer_container__37qjK {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexProjectPageContainer_container1280__3ZapS, .DiglexProjectPageContainer_container__37qjK {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexProjectPageContainer_navBar__XajuW {\n  width: 256px;\n  position: absolute;\n  height: calc(100% - 65px);\n  left: 0;\n  flex-shrink: 0; }\n\n.DiglexProjectPageContainer_wrapper__1B8eJ {\n  display: flex;\n  align-items: center; }\n  .DiglexProjectPageContainer_wrapper__1B8eJ > * {\n    flex-shrink: 0;\n    flex-grow: 0; }\n  .DiglexProjectPageContainer_wrapper__1B8eJ .DiglexProjectPageContainer_space__2r4zH {\n    flex-grow: 1; }\n  .DiglexProjectPageContainer_wrapper__1B8eJ .DiglexProjectPageContainer_title__Q3ajB {\n    margin-left: 12px;\n    font-size: 16px;\n    flex-shrink: 1;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden; }\n  .DiglexProjectPageContainer_wrapper__1B8eJ .DiglexProjectPageContainer_icon__2-9F1 {\n    margin-left: 12px;\n    color: #999;\n    font-size: 12px; }\n  .DiglexProjectPageContainer_wrapper__1B8eJ .DiglexProjectPageContainer_iconLink__28kCG {\n    color: rgba(0, 0, 0, 0.65);\n    -webkit-transition: background-color ease .3s;\n    transition: background-color ease .3s;\n    font-size: 18px;\n    vertical-align: middle; }\n    .DiglexProjectPageContainer_wrapper__1B8eJ .DiglexProjectPageContainer_iconLink__28kCG:hover {\n      color: #40a9ff; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexProjectPageContainer_container1280__3ZapS",
	"container": "DiglexProjectPageContainer_container__37qjK",
	"navBar": "DiglexProjectPageContainer_navBar__XajuW",
	"wrapper": "DiglexProjectPageContainer_wrapper__1B8eJ",
	"space": "DiglexProjectPageContainer_space__2r4zH",
	"title": "DiglexProjectPageContainer_title__Q3ajB",
	"icon": "DiglexProjectPageContainer_icon__2-9F1",
	"iconLink": "DiglexProjectPageContainer_iconLink__28kCG"
};