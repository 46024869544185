import React, {Component} from 'react';
import {Layout} from 'antd';
import styles from './Header.module.scss';
import HeaderUser from '../HeaderUser/HeaderUser';
import HeaderProject from '../HeaderProject/HeaderProject';
import HeaderGroup from '../HeaderGroup/HeaderGroup';
import HeaderCreate from '../HeaderCreate/HeaderCreate';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HeaderInfo from '../HeaderInfo/HeaderInfo';
import {helpRoutes} from '../../../../config/helpRoutes';

class Header extends Component {

  render() {
    return (
      <Layout.Header className={styles.header}>
        <HeaderLogo />
        <HeaderProject
          history={this.props.history}
        />
        <HeaderGroup
          history={this.props.history}
        />

        <div className={styles.space}/>

        <HeaderCreate
          history={this.props.history}
        />

        <HeaderInfo
          helpRoutes={helpRoutes}
          showDrawer={this.props.showDrawer}
        />

        <HeaderUser
          logout={this.props.logout}
        />
      </Layout.Header>
    );
  }
}

export default Header;