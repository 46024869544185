import {call, put, all, takeEvery, takeLatest} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import * as axios from 'axios';
import {MOVIE_BOT_URL} from 'constants/constants';
import {notification} from 'antd';

function* fetchTags({payload}) {
  const {search, page, page_size, id} = payload;
  try {
    const response = yield call(axios, {
      url: `${MOVIE_BOT_URL}/movies/${id}/tags/`,
      method: 'GET',
      params: {search, page, page_size}
    });
    const {results, count} = response.data;

    yield put({
      type: ActionTypes.MOVIE_BOT_TAG_FULFILLED,
      payload: {
        count,
        results: results.map(tag => {
          tag.key = tag.tag_id;
          return tag;
        }),
      },
    });

  } catch (e) {
    yield put({
      type: ActionTypes.MOVIE_BOT_TAG_FAIL,
      payload: {},
    });
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

function* changeTag({payload}) {
  const {movieID, tagID, value} = payload;
  try {
    yield call(axios, {
      url: `${MOVIE_BOT_URL}/movies/${movieID}/tags/${tagID}/`,
      method: 'POST',
      data: {relevance: value}
    });
  } catch (e) {
    // todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

function* deleteTag({payload}) {
  const {movieID, tagID, search, page, page_size} = payload;
  try {
    yield changeTag({payload: {movieID, tagID, value: 0}});
    //yield put(actionFetchTags({search, page, page_size, id: movieID}))
  } catch (e) {
    // todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export default function* userSaga() {
  yield all([
    yield takeLatest(ActionTypes.MOVIE_BOT_TAG_REQUEST, fetchTags),

    yield takeEvery(ActionTypes.MOVIE_BOT_TAG_DELETE, deleteTag),
    yield takeEvery(ActionTypes.MOVIE_BOT_TAG_CHANGE, changeTag),
  ]);
}