import React from "react";

import {Layout, Button, Switch, Icon} from 'antd';
import styles from "./SampleTextEditor.module.scss";

import {Scrollbars} from 'react-custom-scrollbars';
import SampleTextMark from "components/SampleTextMark/SampleTextMark.js";

class SampleTextEditor extends React.Component {
  state = {
    text: "",
    tempText: null,
    editMode: true,
  };

  constructor(props) {
    super(props);
    this.textAreaRef = React.createRef();
    this.textWrapperRef = React.createRef();
    this.state.text = props.activeProject.source;
  }

  componentDidMount() {
    this.setState({...this.state, tempText: this.state.text});
  }

  fixScroll = () => {
    requestAnimationFrame(() => {
      this.scrollContainer.container.scrollLeft = 0;
      this.textWrapperRef.childNodes.forEach(node => {
        node.scrollTop = 0;
      })
    });
  };

  handleChange = event => {
    if (!this.state.editMode) {
      this.setState({...this.state, editMode: true});
    }
    if (this.props.extractResult) {
      this.props.diglexClear();
    }

    let text = event.target.textContent;
    if (event.target.childNodes.length > 1) {
      text = [...event.target.childNodes].map(node => node.textContent).join('\n');
    }

    this.setState({...this.state, text});
  };

  handleSubmit = () => {
    this.setState({
      ...this.state,
      tempText: this.state.text,
      editMode: false
    });
    this.props.diglexExtract(this.props.activeProject, this.state.text);
  };

  renderText = () => {
    if (!this.props.extractResult || this.state.editMode) {
      return this.state.tempText;
    }

    const uid = this.props.extractResult.extractId;
    let key = 0;

    function renderPart(text, objects) {
      if (objects && objects.length) {
        return (
          <SampleTextMark
            key={`mark-${uid}-${key++}`}
            objects={objects}
            text={text}
          />
        );
      }
      return (<span key={`mark-${uid}-${key++}`}>{text}</span>);
    }

    const objects = Array.from(this.props.extractResult.objects);
    const text = this.state.text;
    const out = [];

    const rawPoints = new Set([0, text.length]);
    objects.forEach(object => {
      rawPoints.add(object.begin);
      rawPoints.add(object.end);
    });

    const points = [...rawPoints].sort((a, b) => a - b);
    points.slice(0, points.length - 1).forEach((start, index) => {
      const end = points[index + 1];
      out.push(
        renderPart(
          text.slice(start, end),
          objects.filter(object => object.begin <= start && object.end >= end)
        )
      );
    });

    return out;
  };

  handleDoubleClick = (e) => {
    if (!this.state.editMode) {
      const select = document.getSelection();
      const nodesBefore = [];

      let found = false;
      this.textAreaRef.current.childNodes.forEach(node => {
        if (node === select.baseNode.parentNode) {
          found = true;
          return;
        }
        if (!found) {
          nodesBefore.push(node);
        }
      });

      const textLen = nodesBefore.reduce((p, c) => p + c.innerText ? c.innerText.length : 0, 0);
      const start = textLen + select.baseOffset;
      const end = textLen + select.focusOffset;

      this.setState({editMode: true}, () => {
        try {
          const startNode = this.textAreaRef.current.firstChild;
          const endNode = this.textAreaRef.current.lastChild;
          const range = document.createRange();

          range.setStart(startNode, start);
          range.setEnd(endNode, end);

          select.removeAllRanges();
          select.addRange(range);
        } catch (e) {

        }
      });
    }
  };

  onPaste = e => {
    e.preventDefault();
    document.execCommand("insertHTML", false, e.clipboardData.getData("text/plain"));
  };

  render = () => {
    return (
      <Layout className={styles.editorWrapper} style={{height: "100%"}}>

        <div>
          <h2>Пример текста:</h2>
          {/*<Icon*/}
          {/*className={styles.help}*/}
          {/*type="question-circle"*/}
          {/*onClick={this.props.openHelp}*/}
          {/*/>*/}
        </div>

        <div
          className={styles.textWrapper}
          onDoubleClick={this.handleDoubleClick}
          ref={ref => this.textWrapperRef = ref}
        >
          <Scrollbars
            ref={ref => this.scrollContainer = ref}
          >
            <div>
              <div>
                <div className={`${styles.textArea} ${this.state.editMode ? styles.textEdit : null}`}
                     ref={this.textAreaRef}
                     suppressContentEditableWarning={true}
                     contentEditable={this.state.editMode}
                     onInput={this.handleChange}
                     onKeyDown={this.fixScroll}
                     onPaste={this.onPaste}
                >
                  {this.renderText()}
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>

        <div className={styles.updateToolbar}>
          <Switch
            onChange={checked => this.setState({...this.state, editMode: checked})}
            checked={this.state.editMode}
            checkedChildren="Редактировать"
            unCheckedChildren="Редактировать"
          />

          <Button
            disabled={!this.props.canExtract}
            type="primary"
            onClick={this.handleSubmit}
          >
            Извлечь сущности
          </Button>
        </div>

      </Layout>
    );
  };
}

export default SampleTextEditor;
