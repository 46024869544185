import React from "react";
import {Avatar, Button, Empty, Icon, List, Spin} from 'antd';
import styles from './DiglexGroupList.module.scss';
import {Link} from 'react-router-dom';

class DiglexGroupList extends React.Component {

  componentDidMount() {
    this.props.fetchGroups();
  }

  renderGroup = group => {
    return (
      <List.Item className={styles.item}>
        <div>
          <Avatar icon="team?" title="group"/>
        </div>

        <div className={styles.title}>
          <Link to={`/group/${group.path}`}>
            {group.name}
          </Link>
        </div>

        <div className={styles.desc}>
          {group.description}
        </div>

        <div className={styles.space}/>

        <Link to={`/group/${group.path}/projects`}>
          <Icon className={styles.iconLink} type="database"/>
        </Link>

        <Link to={`/group/${group.path}/members`}>
          <Icon className={styles.iconLink} type="team"/>
        </Link>

        <Link to={`/group/${group.path}/settings`}>
          <Icon className={styles.iconLink} type="setting"/>
        </Link>

      </List.Item>
    );
  };

  renderList = () => {
    if (this.props.list.loading) {
      return <Spin size={'large'} style={{margin: 64, display: 'block'}}/>;
    }

    if (this.props.list.values.length === 0) {
      return <Empty description={' '} style={{margin: 24}}/>;
    }

    return (
      <List
        className={styles.list}
        dataSource={this.props.list.values}
        renderItem={this.renderGroup}
      />
    );
  };


  render() {
    return (
      <div className={styles.page}>

        <div className={styles.pageHeader}>
          <div className={styles.space}/>

          <Button
            type="primary"
            onClick={() => this.props.history.push(`/groups/new`)}
          >
            Создать группу
          </Button>
        </div>

        {this.renderList()}
      </div>
    );
  }
}

export default DiglexGroupList;
