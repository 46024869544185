exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LoginFormContainer_wrapper__2nJNy {\n  height: 100vh;\n  display: flex;\n  flex-flow: column;\n  justify-content: space-around;\n  align-items: center; }\n\n.LoginFormContainer_space__3MgmV {\n  flex-shrink: 1;\n  flex-basis: 200px; }\n\n.LoginFormContainer_btn__g0fwG {\n  margin-top: 12px;\n  width: 100%; }\n\n.LoginFormContainer_link__3LxHD {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "LoginFormContainer_wrapper__2nJNy",
	"space": "LoginFormContainer_space__3MgmV",
	"btn": "LoginFormContainer_btn__g0fwG",
	"link": "LoginFormContainer_link__3LxHD"
};