import ActionTypes from './actionTypes';

const initialState = {
  login: {
    loading: false,
    fieldErrors: {},
  },
  registration: {
    loading: false,
    fieldErrors: {},
  },
  token: null,
  refreshToken: null,
  profile: null,
  preferences: null,
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.USER_RESTORE_DATA: {
      const {profile, token, refreshToken} = payload;
      return {
        ...state,
        refreshToken: refreshToken ? refreshToken : state.refreshToken,
        profile: profile ? profile : state.profile,
        token: token ? token : state.token,
      };
    }
    // case ActionTypes.USER_LOGOUT: {
    //   return {
    //     ...state,
    //   }
    // }

    case ActionTypes.USER_AUTH_REQUEST: {
      return {
        ...state,
        login: {
          loading: true,
          fieldErrors: {},
        }
      };
    }
    case ActionTypes.USER_AUTH_FULFILLED: {
      const {profile, token, refreshToken} = payload;
      return {
        ...state,
        login: {
          loading: false,
          fieldErrors: {},
        },
        refreshToken,
        profile,
        token,
      };
    }
    case ActionTypes.USER_AUTH_FAIL: {
      return {
        ...state,
        login: {
          loading: false,
          fieldErrors: payload.errors,
        }
      };
    }

    case ActionTypes.USER_PREFERENCES_REQUEST: {
      return {
        ...state,
        preferences: null,
      };
    }
    case ActionTypes.USER_PREFERENCES_FULFILLED: {
      const {preferences} = payload;
      return {
        ...state,
        preferences,
      };
    }
    case ActionTypes.USER_PREFERENCES_FAIL: {
      return {
        ...state,
        preferences: null,
      };
    }

    case ActionTypes.USER_CHANGE_PREFERENCES: {
      const {preferences} = payload;
      return {
        ...state,
        preferences,
      };
    }

    case ActionTypes.USER_REGISTRATION_REQUEST: {
      return {
        ...state,
        registration: {
          loading: true,
          fieldErrors: {},
        }
      };
    }
    case ActionTypes.USER_REGISTRATION_FULFILLED: {
      return {
        ...state,
        // registration: {
        //   loading: false,
        //   fieldErrors: {},
        // }
      };
    }
    case ActionTypes.USER_REGISTRATION_FAIL: {
      return {
        ...state,
        registration: {
          loading: false,
          fieldErrors: payload.errors,
        }
      };
    }

    default:
      return state;
  }
}
