import ActionTypes from './actionTypes';

const uiActions = {
  update: (element, prop, value) => {
    return {
      type: ActionTypes.UI_UPDATE,
      payload: {element, prop, value},
    };
  },
};

export default uiActions;
