exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("assets/logo.png"));

// Module
exports.push([module.id, ".HeaderLogo_menu__8kj2g, .HeaderLogo_menuToggled__3atJc {\n  position: relative;\n  margin-right: 12px; }\n  .HeaderLogo_menu__8kj2g .HeaderLogo_logo__2qwTb, .HeaderLogo_menuToggled__3atJc .HeaderLogo_logo__2qwTb {\n    background: url(" + ___CSS_LOADER_URL___0___ + ") center center no-repeat;\n    background-size: contain;\n    height: 20px;\n    margin: 10px 0;\n    width: 112px;\n    -webkit-filter: invert(1);\n            filter: invert(1); }\n\n.HeaderLogo_menuToggled__3atJc {\n  color: #fff;\n  background: #1890ff; }\n\n.HeaderLogo_dropDown__rZsDT {\n  min-width: 200px;\n  position: relative;\n  margin: 0;\n  padding: 4px 0;\n  text-align: left;\n  list-style-type: none;\n  background-color: #fff;\n  background-clip: padding-box;\n  border-radius: 4px;\n  outline: none;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }\n", ""]);

// Exports
exports.locals = {
	"menu": "HeaderLogo_menu__8kj2g",
	"menuToggled": "HeaderLogo_menuToggled__3atJc",
	"logo": "HeaderLogo_logo__2qwTb",
	"dropDown": "HeaderLogo_dropDown__rZsDT"
};