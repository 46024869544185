import React from "react";
import styles from "./SampleTextMark.module.scss";

import scrollIntoView from 'scroll-into-view';
import {Color} from 'utils'
import ContextMenu from "components/ContextMenu/ContextMenu.js";

class SampleTextMark extends React.Component {

  state = {
    isActive: false,
    popup: {
      visible: false,
      x: 0,
      y: 0
    }
  };

  componentDidMount = () => {
    this.props.objects.forEach(object => {
      object.markRefs.push(this);
    });
  };

  componentWillUnmount = () => {
    this.props.objects.forEach(object => {
      object.markRefs = object.markRefs.filter(i => i !== this);
      const node = document.querySelector(`.${object.uid}`);
      if (node) {
        node.classList.remove('.ant-table-row-hover');
      }
    });
  };

  setActiveState(active, number, all) {
    if (active) {
      active = 'markActive';

      if (all >= 2) {
        if (number === 0) {
          active = 'markActiveBegin'
        } else if (number === all - 1) {
          active = 'markActiveEnd'
        } else {
          active = 'markActiveMiddle'
        }
      }
    }

    this.setState({
      ...this.state,
      isActive: active
    })
  }

  onClick = event => {
    function scrollToObject(object) {
      const node = document.querySelector(`.${object.uid}`);
      if (node) {
        scrollIntoView(node, {
          align: {
            top: 0,
          },
        });
      }
    }

    if (this.props.objects.length === 1) {
      scrollToObject(this.props.objects[0]);
      return
    }

    const onClickOutside = () => {
      this.setState({...this.state, popup: {visible: false}});
      document.removeEventListener('click', onClickOutside);
    };
    document.addEventListener('click', onClickOutside);

    const actions = this.props.objects.map(object => {
      return {
        Icon: 'edit',
        title: object.text,
        onClick: () => scrollToObject(object)
      }
    });

    this.setState({
      ...this.state,
      popup: {
        actions,
        visible: true,
        x: event.clientX,
        y: event.clientY
      }
    });
  };

  onMouseEnterHandler = () => {
    const markRefs = this.props.objects.reduce((refs, object) => {
      refs.push(...object.markRefs);
      return refs
    }, []);

    markRefs.forEach((mark, index) => mark.setActiveState(true, index, markRefs.length));

    this.props.objects.forEach(object => {
      const node = document.querySelector(`.${object.uid}`);
      if (node) {
        node.classList.add('ant-table-row-hover');
      }
    });
  };

  onMouseLeaveHandler = () => {
    this.props.objects.forEach(object => {
      object.markRefs.forEach(mark => {
        mark.setActiveState(false)
      });

      const node = document.querySelector(`.${object.uid}`);
      if (node) {
        node.classList.remove('ant-table-row-hover');
      }
    });
  };

  getColor = () => {
    return Color.fromMultiply(this.props.objects.map(obj => obj.color)).toRGBA({alpha: .5})
  };

  render() {
    return (
      <span
        className={`${this.state.isActive ? styles[this.state.isActive] : styles.mark}`}
        style={{backgroundColor: this.getColor()}}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        <ContextMenu {...this.state.popup} />
        {this.props.text}
      </span>
    );
  }
}

export default SampleTextMark;
