import React from 'react';
import styles from './RecordsTable.module.scss';
import {Button, Icon, Input, Pagination, Spin, Table} from 'antd';
import {columnsFilter, columnsRender, recordInteractions} from './constants';

export class RecordsTable extends React.Component {
  state = {
    page: 0,
    offset: 0,
    limit: 0,
  };

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      page: query.get('page') ? Number(query.get('page')) : 1,
      limit: query.get('limit') ? Number(query.get('limit')) : 10,
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    query.delete('limit');
    query.delete('page');

    const values = {
      limit: this.state.limit,
      offset: (this.state.page - 1) * this.state.limit,
    };

    for (let value of query.entries()) {
      values[value[0]] = value[1];
    }

    if (this.props.records.entity !== this.props.entity
      || Object.keys(values).some(key => values[key] !== this.props.records.query[key])
      || Object.keys(this.props.records.query).some(key => values[key] !== this.props.records.query[key])
    ) {
      this.props.flushRecords();
      this.props.getRecords(this.props.entity, values, this.props.relations);
    }
  }

  getColumnSearchProps = (dataIndex) => {
    const query = new URLSearchParams(this.props.location.search);

    const handleSearch = (selectedKeys, confirm) => {
      query.set(dataIndex, selectedKeys);
      query.delete('page');
      this.props.history.push(`${this.props.history.location.pathname}?${query.toString()}`);
    };

    const handleReset = (clearFilters) => {
      query.delete(dataIndex);
      query.delete('page');
      this.props.history.push(`${this.props.history.location.pathname}?${query.toString()}`);
    };

    return {
      filteredValue: query.get(dataIndex) ? [query.get(dataIndex)] : [],
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters,}) => (
        <div style={{padding: 8}}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Поиск по ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (<Icon
        type="search"
        style={{
          fontSize: '14px',
          width: '50px',
          color: filtered ? '#1890ff' : undefined,
          backgroundColor: filtered ? '#ccc' : undefined,
        }}
      />),
      // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      // onFilterDropdownVisibleChange: (visible) => {
      //   if (visible) {
      //     setTimeout(() => this.searchInput.select());
      //   }
      // },
    };
  };

  makeTableColumns({schema, fields, history, path, data, allData}) {
    if (!schema || !fields) {
      return [];
    }

    const additional = {
      // schema,
      // data,
      // allData,
    };

    const schemaFields = schema.fields;
    const columns = [];

    fields.forEach(field => {
      let baseRender = value => value;
      let render = null;
      let filter = null;

      if (field.schema && Object.keys(schemaFields[field.schema].choices).length) {
        baseRender = index => schemaFields[field.schema].choices[index];
      }

      switch (field.render) {
        case columnsRender.editRecord: {
          render = (value, record) => (
            <a onClick={() => history.push(`${path}/edit/${record.id}`)}>{baseRender(value)}</a>
          );
          break;
        }
        case columnsRender.relation: {
          const relations = allData[schema['fields'][field.schema]['relation']];
          render = (value, record) => (
            <span>
              {baseRender(relations.find(relation => relation['id'] === value)['name'])}
            </span>
          );
          break;
        }
        case columnsRender.advancedRender: {
          render = (value, record) => field.advancedRender(value, record, allData, history);
          break;
        }
        default:
          render = field.render ? field.render.bind(additional) : baseRender;
      }

      switch (field.filter) {
        case columnsFilter.search: {
          filter = this.getColumnSearchProps(field.schema);
          break;
        }
        case columnsFilter.relations: {
          filter = this.getColumnSearchProps(field.schema);
          break;
        }
        default:
          filter = {
            filter: field.filter
          };
      }

      columns.push({
        title: field.title,
        key: columns.length,
        dataIndex: field.schema,
        render,
        ...filter,
      });
    });

    return columns;
  }

  columns = () => {
    if (this.props.records.loading || !this.props.records.data || !this.props.records.schema) {
      return [];
    }
    return this.makeTableColumns({
      history: this.props.history,
      path: this.props.match.path,
      schema: this.props.records.schema[this.props.entity],
      data: this.props.records.data[this.props.entity],
      allData: this.props.records.data,
      fields: this.props.tableFields,
    });
  };

  renderAddButton = () => {
    if (this.props.interactions.includes(recordInteractions.create)) {
      return (
        <Button
          icon="plus-circle"
          type="primary"
          onClick={() => this.props.history.push(`${this.props.match.path}/create`)}
        >
          {`Add ${this.props.entity}`}
        </Button>
      );
    }
  };

  getData = () => {
    if (this.props.records.loading
      || !this.props.records.data
      || !this.props.records.schema
      || !Array.isArray(this.props.records.data[this.props.entity])) {
      return [];
    }
    return this.props.records.data[this.props.entity];
  };

  render() {
    return (
      <Spin size={'large'} spinning={this.props.records.loading}>

        <div className={styles.header}>
          <div>{this.props.entity}</div>
          {this.renderAddButton()}
        </div>

        <div className={styles.content} style={{padding: 0}}>
          <Table
            dataSource={this.getData()}
            columns={this.columns()}
            rowKey={record => record.id}
            pagination={false}
          />

          <Pagination
            style={{
              textAlign: 'right',
              padding: '18px 8px',
            }}
            showSizeChanger
            onChange={(page, limit) => {
              const query = new URLSearchParams(this.props.history.location.search);
              query.set('limit', limit.toString());
              query.set('page', page.toString());
              this.props.history.push(`${this.props.history.location.pathname}?${query.toString()}`);
            }}
            onShowSizeChange={(page, limit) => {
              const query = new URLSearchParams(this.props.history.location.search);
              query.set('limit', limit.toString());
              query.set('page', page.toString());
              this.props.history.push(`${this.props.history.location.pathname}?${query.toString()}`);
            }}

            defaultPageSize={this.state.limit}
            defaultCurrent={this.state.page}

            total={this.props.records.count}
          />
        </div>
      </Spin>
    );
  }
}

export default RecordsTable;