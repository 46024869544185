import {call, put, all, takeEvery, select} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import {history} from '../store';

function* fetch({payload}) {
  const {type} = payload;

  const state = yield select();

  try {
    const response = yield call(window.diglexAxios, {
      url: `/api/members/${type}`,
      method: 'GET',
      params: {
        id: type === 'project' ? state.project.activeProject._id : state.group.activeGroup._id,
      },
    });

    yield put({
      type: ActionTypes.MEMBER_FETCH_FULFILLED,
      payload: {
        members: response.data
      },
    });
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        yield put({
          type: ActionTypes.MEMBER_FETCH_FAIL,
          payload: {},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.MEMBER_FETCH_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* invite({payload}) {
  const {type, id, users, url} = payload;

  try {
    const response = yield call(window.diglexAxios, {
      url: `/api/members/invite`,
      method: 'POST',
      data: {type, id, users}
    });

    yield put({
      type: ActionTypes.MEMBER_FETCH_FULFILLED,
      payload: {
        members: response.data
      },
    });

    history.push(url);
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        yield put({
          type: ActionTypes.MEMBER_FETCH_FAIL,
          payload: {},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.MEMBER_FETCH_FAIL,
          payload: {},
        });
      }
    }
  }
}

function* remove({payload}) {
  const {type, userId} = payload;
  const state = yield select();
  const id = type === 'project' ? state.project.activeProject._id : state.group.activeGroup._id;

  try {
    yield call(window.diglexAxios, {
      url: `/api/members/remove`,
      method: 'POST',
      data: {type, id, userId}
    });

    yield put({
      type: ActionTypes.MEMBER_REMOVE_FULFILLED,
      payload: {},
    });

    history.push(type === 'project' ? '/projects' : '/groups');
  } catch (e) {
    switch (e.response.status) {
      case 400: {
        yield put({
          type: ActionTypes.MEMBER_REMOVE_FAIL,
          payload: {},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.MEMBER_REMOVE_FAIL,
          payload: {},
        });
      }
    }
  }
}

export default function* userSaga() {
  yield all([
    yield takeEvery(ActionTypes.MEMBER_FETCH_REQUEST, fetch),
    yield takeEvery(ActionTypes.MEMBER_INVITE_REQUEST, invite),
    yield takeEvery(ActionTypes.MEMBER_REMOVE_REQUEST, remove),
  ]);
}