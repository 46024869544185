export default {
  title: 'О сервисе',
  footer: true,
  source: `

Добро пожаловать в Diglex
============

Diglex это комплексное решение для извлечения информации из текстов на естественном языке, которое включает:
* [Diglex Language](/diglexLang) - язык описания лексико-семантических шаблонов.
* [Diglex Library](/diglexLibrary) - C++ библиотека("движок") для применения лексико-семантических шаблонов к тексту.
* [Diglex Stream](/diglexStream) - приложение с stdin/stdout интерфейсом вокруг библиотеки DiglexLibrary.
* Diglex Service - REST и WebSocket API.
* Diglex Editor - веб-редактор лексико-семантических шаблонов в котором вы сейчас находитесь.

О Diglex Editor
============

Редактор диглекс оперирует понятиями:

* **Пользователь**
* **Группа пользователей**
* **Проект**

Пользователи могут создавать и группы и добавлять в них других пользователей.

Проект может принадлежать:
* **пользователю**
* **группе ползователей**

У каждого проекта есть человекочитаемое название и URL. В URL содержится принадлежность проекта к пользователю или группе плюс наименование проекта латиницей. URL Path, т. е. часть URL не содержащая домена Diglex испоьзуется в качестве идентификатора проекта для работы по API.

Проеты бывают следующих видов:
* **Закрытый проект** - доступ к проекту должен быть предоставлен явно каждому пользователю.
* **Публичный проект** - доступ к проекту может получить любой зарегистрированный пользователь (только на чтение и клонирование).


`
};
