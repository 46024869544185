import React from "react";
import {connect} from "react-redux";
import styles from './DiglexProjectSettingsContainer.module.scss';
import {Button, Input, Form, Icon, Modal, Radio} from 'antd';
import projectActions from 'ducks/project/projectActions';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
    lg: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 24, offset: 0},
    lg: {span: 24, offset: 0},
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 24, offset: 0},
    lg: {span: 24, offset: 0},
  },
};

class DiglexProjectSettingsContainer extends React.Component {

  confirm = () => {
    const modal = Modal.confirm({
      title: `Удалить ${this.props.activeProject.name}`,
      content: 'Удаленный проект не может быть восстановлен! Вы уверены?',
      okType: 'danger',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => {
        this.props.delete(this.props.activeProject._id);
        modal.destroy();
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.id = this.props.activeProject._id;
        this.props.update(values);
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className={styles.page}>

        <div className={styles.pageHeader}>
          <div className={styles.space}/>

          {this.props.activeProject.permissions.includes('delete') && (
            <Button
              onClick={this.confirm}
              type={"danger"}
              loading={this.props.deleteLoading}
            >
              Удалить проект
            </Button>
          )}
        </div>

        {this.props.activeProject.permissions.includes('editProject') && (
          <Form
            {...formItemLayout}
            layout="vertical"
            className={styles.form}
            onSubmit={this.handleSubmit}
          >
            <Form.Item label="Описание проекта">
              {getFieldDecorator('description', {initialValue: this.props.activeProject.description})(
                <Input.TextArea
                  style={{minHeight: 86}}
                  placeholder="Описание проекта"
                />
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              {getFieldDecorator('access', {initialValue: this.props.activeProject.access})(
                <Radio.Group>
                  <Radio className={styles.radio} value={'private'}>
                    <Icon style={{fontSize: 15}} type="lock" theme="filled"/>
                    {' '}
                    Закрытый проект
                  </Radio>
                  <div className={styles.radioInfo}>
                    Доступ к проекту должен быть предоставлен явно каждому пользователю.
                  </div>
                  <Radio className={styles.radio} value={'public'}>
                    <Icon style={{fontSize: 15}} type="book"/>
                    {' '}
                    Публичный проект
                  </Radio>
                  <div className={styles.radioInfo}>
                    Доступ к проекту может получить любой зарегистрированный пользователь.
                  </div>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout} style={{marginBottom: 0}}>
              <Button
                loading={this.props.updateLoading}
                style={{minWidth: '45%'}}
                size={'large'}
                type='primary'
                htmlType='submit'
              >
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeProject: state.project.activeProject,
    deleteLoading: state.project.form.delete.loading,
    updateLoading: state.project.form.update.loading,

    loading: state.project.form.list.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    delete: (id) => dispatch(projectActions.deleteProject(id)),
    update: ({id, description, access}) => dispatch(projectActions.updateProject({id, description, access})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(DiglexProjectSettingsContainer));
