import React from 'react';
import {Menu, Icon} from 'antd';
import styles from './HeaderCreate.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';

export default class HeaderCreate extends React.Component {
  state = {
    toggled: false
  };

  toggle = toggled => this.setState({toggled});

  handler = ({key}) => {
    this.toggle(false);

    switch (key) {
      case "newProject": {
        this.props.history.push('/projects/new');
        break;
      }
      case "newGroup": {
        this.props.history.push('/groups/new');
        break;
      }
      default:
    }
  };

  render() {
    const createMenu = (
      <Menu onClick={this.handler}>
        {/*<Menu.Divider />*/}
        <Menu.Item key={'newProject'}>Новый проект</Menu.Item>
        <Menu.Item key={'newGroup'}>Создать группу</Menu.Item>
      </Menu>
    );
    return (
      <HeaderDropdown
        overlay={createMenu}
        overlayClassName={styles.dropDown}
        onVisibleChange={this.toggle}
        placement="bottomRight"
        trigger={['click']}
      >
        <div>
          <Icon
            type="plus"
            className={`${this.state.toggled ? styles.iconToggled : styles.icon}`}
          />
        </div>
      </HeaderDropdown>
    );
  }
}
