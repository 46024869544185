import {all, fork} from 'redux-saga/effects';

import movieSaga from './movie/sagas';
import tagSaga from './tag/sagas';
import chatSaga from './chat/sagas';

export default function* rootSaga() {
  yield all([
    yield fork(movieSaga),
    yield fork(tagSaga),
    yield fork(chatSaga),
  ]);
}