import React from "react";
import {Avatar, Button, Empty, Icon, List, Spin} from 'antd';
import styles from './DiglexMemberList.module.scss';

class DiglexMemberList extends React.Component {

  componentDidMount() {
    this.props.fetchMembers();
  }

  btnLeave = member => {
    return null;
    // if (member.user.username === this.props.username) {
    //   return (
    //     <Button
    //       type="link"
    //       loading={this.props.removeLoading}
    //       onClick={() => this.props.removeMember(member.user._id)}
    //     >
    //       Покинуть группу
    //     </Button>
    //   );
    // }
  };

  btnRemove = member => {
    if (member.user.username !== this.props.username && this.props.active.permissions.includes("grantPermission")) {
      return (
        <Button
          type="link"
          loading={this.props.removeLoading}
          onClick={() => this.props.removeMember(member.user._id)}
        >
          Удалить пользователя
        </Button>
      );
    }
  };

  renderUser = member => {
    return (
      <List.Item className={styles.item}>

        <div>
          <Avatar icon="user" title="user"/>
        </div>

        <div className={styles.title}>
          {member.user.username}
        </div>

        <div className={styles.space}/>

        {this.btnLeave(member)}
        {this.btnRemove(member)}
      </List.Item>
    );
  };

  renderList = () => {
    if (this.props.loading) {
      return <Spin size={'large'} style={{margin: 64, display: 'block'}}/>;
    }

    if (this.props.values.length === 0) {
      return <Empty description={' '} style={{margin: 24}}/>;
    }

    return (
      <List
        className={styles.list}
        dataSource={this.props.values}
        renderItem={this.renderUser}
      />
    );
  };

  render() {
    return (
      <div className={styles.page}>

        <div className={styles.pageHeader}>
          <div className={styles.space}/>

          {this.props.active.permissions.includes("grantPermission") && <Button
            type="primary"
            onClick={() => this.props.history.push(`${this.props.location.pathname}/invite`)}
          >
            Добавить участников
          </Button>}
        </div>

        {this.renderList()}
      </div>
    );
  }
}

export default DiglexMemberList;
