import ActionTypes from './ActionTypes';
import ModalActions from '../modal/actions';
import {CORPORA_URL} from 'constants/constants';
import {put, takeEvery, takeLatest, all, call, select} from 'redux-saga/effects';
import * as axios from 'axios';
import {notification} from 'antd';

const entityToRequest = {
  'TextCollection': 'text-collections',
  'Text': 'texts',
  'Analyzer': 'analyzers',
  'AnalyzerRunTask': 'analyzer-tasks',
  'AnalyzerRunTaskResult': 'analyzer-results',
};

const relationToEntity = {
  'collection': 'TextCollection',
};

function* getRecords({payload}) {
  const {entity, query, relations} = payload;

  yield call(fetchRecords, {payload: {entity, query}});

  const state = yield select(state => state);
  const records = state.records.data[entity];

  const requests = relations.map(relation => call(fetchRecords, {
    payload: {
      entity: relationToEntity[relation],
      query: {
        'id__in': records.map(record => record[relation]).join()
      }
    }
  }));

  if (!state.records.schema) {
    requests.push(call(fetchSchema, {}));
  }

  if (requests.length) {
    yield all(requests);
  }

  yield put({
    type: ActionTypes.RECORD_GET_RECEIVED,
    payload: {}
  });
}

export function* fetchSchema() {
  try {
    const response = yield call(axios, {url: `${CORPORA_URL}/schema/`, method: 'GET'});
    yield put({
      type: ActionTypes.RECORD_SCHEMA_FETCH_RECEIVED,
      payload: {
        schema: response.data
      }
    });
  } catch (e) {
    console.log('err', e);
    //todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export function* fetchRecords({payload}) {
  const url = new URL(`${CORPORA_URL}/${entityToRequest[payload.entity]}/`);

  Object.entries(payload.query).forEach(query => url.searchParams.set(query[0], query[1]));

  try {
    const response = yield call(axios, {url, method: 'GET'});

    yield put({
      type: ActionTypes.RECORD_FETCH_RECEIVED,
      payload: {
        data: response.data.results ? response.data.results : response.data,
        count: response.data.count ? response.data.count : null,
        query: payload.query,
        entity: payload.entity,
      }
    });
  } catch (e) {
    console.log(e);
    //todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export function* deleteRecord(action) {
  try {
    const response = yield call(axios, {
      url: action.payload.record.url,
      method: 'DELETE',
    });

    yield put({
      type: ActionTypes.RECORD_DELETE_RECEIVED,
      payload: {
        data: response.data,
        entity: action.payload.entity,
      }
    });

    if (action.payload.navigate) {
      setTimeout(() => action.payload.navigate.history.push(action.payload.navigate.url), 0);
    }

    yield notification.success({
      duration: 2,
      message: `Успешно удален.`,
      description: '',
    });
  } catch (e) {
    console.log(e);
    //todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  } finally {
    yield put(ModalActions.hideModal());
  }
}

function* createRecord(action) {

  try {
    const response = yield call(axios, {
      url: `http://corpora.forkode.ru/api/${entityToRequest[action.payload.entity]}/`,
      method: 'POST',
      data: action.payload.values
    });

    yield put({
      type: ActionTypes.RECORD_UPDATE_RECEIVED,
      payload: {
        data: response.data,
        entity: action.payload.entity,
      }
    });

    if (action.payload.navigate) {
      setTimeout(() => action.payload.navigate.history.push(action.payload.navigate.url), 0);
    }

    yield notification.success({
      duration: 2,
      message: `Успешно сохранен.`,
      description: '',
    });
  } catch (e) {
    console.log(e);
    //todo: handle err
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

function* updateRecord(action) {
  try {
    const response = yield call(axios, {
      url: `${CORPORA_URL}/${entityToRequest[action.payload.entity]}/${action.payload.record.id}/`,
      method: 'PUT',
      data: action.payload.values
    });

    yield put({
      type: ActionTypes.RECORD_UPDATE_RECEIVED,
      payload: {
        data: response.data,
        entity: action.payload.entity,
      }
    });

    if (action.payload.navigate) {
      setTimeout(() => action.payload.navigate.history.push(action.payload.navigate.url), 0);
    }

    yield notification.success({
      duration: 2,
      message: `Успешно сохранен.`,
      description: '',
    });

  } catch (e) {
    console.log(e);
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export default function* recordSaga() {
  yield all([
    yield takeLatest(ActionTypes.RECORD_SCHEMA_FETCH_REQUEST, fetchSchema),
    yield takeLatest(ActionTypes.RECORD_GET_REQUEST, getRecords),

    yield takeEvery(ActionTypes.RECORD_FETCH_REQUEST, fetchRecords),
    yield takeEvery(ActionTypes.RECORD_CREATE_REQUEST, createRecord),
    yield takeEvery(ActionTypes.RECORD_DELETE_REQUEST, deleteRecord),
    yield takeEvery(ActionTypes.RECORD_UPDATE_REQUEST, updateRecord),
  ]);
}