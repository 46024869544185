import React from "react";
import {Avatar, Button, Empty, Icon, List, Spin} from 'antd';
import styles from './DiglexProjectList.module.scss';
import {Link} from 'react-router-dom';

class DiglexProjectList extends React.Component {

  componentDidMount() {
    this.props.fetchProjects();
  }

  renderProject = project => {
    return (
      <List.Item className={styles.item}>
        <div>
          {project.type === 'user' ?
            <Avatar icon="user" title="user project"/>
            :
            <Avatar icon="team" title="group project"/>
          }
        </div>

        <div className={styles.title}>
          <Link to={`/project${project.fullPath}`}>
            {project.name}
          </Link>

          {project.access === 'public' ?
            <Icon className={styles.icon} type="book" theme="outlined" title="публичный проект"/>
            :
            <Icon className={styles.icon} type="lock" theme="filled" title="закрытый проект"/>
          }
        </div>

        {/*<div className={styles.desc}>*/}
        {/*{project.description}*/}
        {/*</div>*/}

        <div className={styles.space}/>

        {/*<Link to={`/project${project.fullPath}`}>*/}
        {/*<Icon className={styles.iconLink} type="form"/>*/}
        {/*</Link>*/}

        {this.props.type === 'explore-project' && (
          <>
            <Link to={`/projects/fork?fullPath=${project.fullPath}`} title={"клонировать"}>
              <Icon type="share-alt" className={styles.iconLink}/>
            </Link>
          </>
        )}

        {this.props.type !== 'explore-project' && (
          <>
            <Link to={`/projects${project.fullPath}/members`}>
              <Icon className={styles.iconLink} type="team"/>
            </Link>

            <Link to={`/projects${project.fullPath}/settings`}>
              <Icon className={styles.iconLink} type="setting"/>
            </Link>
          </>
        )}
      </List.Item>
    );
  };

  renderList = () => {
    if (this.props.list.loading) {
      return <Spin size={'large'} style={{margin: 64, display: 'block'}}/>;
    }

    if (this.props.list.values.length === 0) {
      return <Empty description={' '} style={{margin: 24}}/>;
    }

    return (
      <List
        className={styles.list}
        dataSource={this.props.list.values}
        renderItem={this.renderProject}
      />
    );
  };

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.pageHeader}>
          <div className={styles.space}/>

          <Button
            type="primary"
            onClick={() => this.props.history.push(`/projects/new${this.props.type === 'group' ? `?group=${this.props.groupId}` : ''}`)}
          >
            Новый проект
          </Button>
        </div>

        {this.renderList()}
      </div>
    );
  }
}

export default DiglexProjectList;
