import React, {Component} from 'react';
import './DiglexStatusToolbar.css';
import "antd/dist/antd.css";

// <div class="diglex-toolbar-wrap">
//   <div>Активный проект:</div> <Select styles={{'width': 120}} options={options} />
// </div>

class DiglexStatusToolbar extends Component {

  render() {
    // const options = [
    //   {value: 'chocolate', label: 'Chocolate'},
    //   {value: 'strawberry', label: 'Strawberry'},
    //   {value: 'vanilla', label: 'Vanilla'}
    // ];

    return (
      <div />
    )
  }
}


export default DiglexStatusToolbar;
