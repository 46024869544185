export default {
    title: 'Diglex Library',
    source: `
Diglex Library
============

Библиотека Diglex - динамическая библиотека реализованная на языке
программирования C++, обепечивающая интерпретацию словарей языка Diglex, по
описанным в словарях шаблонам, библиотека Diglex создаёт "объекты Diglex",
содержащие информацию о принадлежности к тексту и возможно набор свойств вида
имя-значения, из предметной области найденного объекта.

# Сборка и подключение к проектам

**Linux**:

В директории projects/CMake/BUILD/ необходимо выполнить
\`\`\`bash
cmake .. && make
\`\`\`

Готовая к использованию библиотека будет расположена в

\`\`\`bash
projects/CMake/BUILD/DiglexProcessorDll/libDIGLEX_PROCESSOR_DYNAMIC_LIB.so
\`\`\`


**Windows**:

Для сборки Diglex необходимо наличие установленных:
cmake >= 3.14.2.

Microsoft Visual Stdudio Community 16.3.0 (В предыдущих версиях Diglex может не собраться).


1\\. В директории projects\\CMake\\BUILD необходимо выполнить

\`\`\`cmd
cmake ..
\`\`\`
2\\. Собрать в VisualStudio сгенерированный файл проекта Diglex.sln

После сборки библиотека будет находится в каталоге
\`\`\`cmd
projects\\CMake\\BUILD\\\<имя-конфигурации-сборки>\\DIGLEX_PROCESSOR_DYNAMIC_LIB.dll
\`\`\`

# Сборка приложения с библиотекой

Разрабатываемое приложение линкуется с библиотекой стандартным образом. Пример:
\`\`\`makefile
g++ source_files.cpp -Wl, -rpath <путь к библиотеке>
\`\`\`

# Заголовочные файлы

Библиотека Diglex постепенно переводится от интерфейса процедурного стиля, к объектно ориентированному интерфейсу.
На данный момент не вся необходимая функциональность перенесена, поэтому иногда приходится использовать старый интерфейс.
\`\`\`cpp
"DiglexProcessorDll/DiglexManager.h" // объявление интерфейсов менеджера библиотеки
"DiglexProcessorDll/DiglexProcessorDll.h" // старый процедурный интерфейс
"DiglexUtils/DiglexParserException.h" // описание базовых классов порождаемых библиотекой иключений
"DiglexUtils/Misc.h" // интерфейсы ф-й которые могут пригодится при работе.

\`\`\`

# Исключения
При работе с библиотекой необходимо ловить исключения следующих типов:

\`\`\`cpp
    catch (const FileParser::Exception &e)  // ошибки чтения словарей
    catch (const DiglexUtils::Exception &e) // ошибки работы поискового ядра
    catch (const wchar_t *e) {              // другие ошибки
\`\`\`

Исключения служат сигнальным механизмом о возникшей ошибке и при правильной обработке не означают аварийное завершения работы библиотеки.

# Основные интерфейсы библиотеки

Ниже перечислены основные классы и методы, которые необходимы при работе с библиотекой.
Это не полный, но достаточный для прикладного использования перечень.

## DiglexManager

\`\`\`cpp

Объект представляющий непосредственно библиотеку Diglex.
Рсположение: include/DiglexProcessorDll/DiglexManager.h

class DiglexManager {

    // Получение сеанса работы с библиотекой
    DiglexManager &getInstance();

    // Выбор формата словаря
    // ParsingMode - перечисление: ParsingMode::XML, ParsingMode::YAML.
    void setParserMode(ParsingMode mode);

    // Загрузка словаря
    // iFileName указывает:
    // для YAML указывает на имя каталога со словарём
    // для XML указывает на имя файла (устаревший формат).
    void LoadTemplates(const char *iFileName)

    // Изменение ранее загруженных элементов словаря, внесение новых или удаление старых
    // (подробнее см. руководство по diglex_stream)
    void reloadTemplates(const char *iFileName);

    // Загрузка текста для извлечения объектов
    void SetText(const wchar_t *iWideCharText);

    // Поиск объектов на загруженном тексте из загруженного словаря
    void FindObjects();

    // Количество найденных объектов
    int GetObjectsCount() const;

    // По переданному порядковому номеру объекта
    // Возвращает указатель на найденный объект
    // (от нуля до GetObjectsCount() - 1)
    const CppDiglexObjectModel::IDiglexObject *GetDiglexObject(unsigned iid) const;

}
\`\`\`

## IDiglexObject

\`\`\`cpp

Интерфейс для найденного объекта.
Расположение: include/CppDiglexObjectModel/IDiglexObject.h

class CppDiglexObjectModel::IDiglexObject {

    // Возвращает начало объекта в тексте
    virtual unsigned int GetBegin() const = 0;

    // Возвращает конец объекта в тексте
    virtual unsigned int GetEnd() const = 0;

    // Возвращает указатель на шаблон, по которому был создан объект.
    virtual ITemplate *GetTemplate() const = 0;

    // Возвращает количество свойств объекта
    virtual size_t GetPropertiesCount() const = 0;

    // Возвращает ссылку на список свойств объекта
    virtual const std::set<CppDiglexObjectModel::Property> &GetProperties() const = 0;
}
\`\`\`

## Property

\`\`\`cpp

Свойство объекта.
Расположение: include/CppDiglexObjectModel/Property.h

class CppDiglexObjectModel::Property {

    // Получить имя свойства
    const std::wstring & GetName() const;

    // Получить тип свойства
    // PropertyType - перечисление:
    // PropertyType::Boolean, PropertyType::Integer,
    // PropertyType::Float, PropertyType::String
    PropertyType GetType() const;

    // Получить текстовое значение свойства
    const std::wstring &GetValue() const;

}
\`\`\`

## ITemplate

\`\`\`cpp

Пользовательское представление шаблона т,е. данные, которым представлен шаблон
Расположение: include/CppDiglexObjectModel/ITemplate.h

class CppDiglexObjectModel::ITemplate {

    // Имя шаблона
    virtual const std::wstring &GetName() const = 0;

    // Спиок идентефикаторов родительских классов шаблона
    virtual const std::vector<unsigned int> &GetParentIds() const = 0;

    // Ссылка на контейнер интерфесов варианта шаблона
    virtual const std::vector<const IMatchCase *> &GetMatchCases() const = 0;

    // Возвращает вектор Id классов, к которым принадлежит шаблон
    // По размеру данного вектора можно узнать количество классов к которым
    // принадлежит шаблон.
    virtual const std::vector<unsigned int> &GetParentIds() const = 0
}
\`\`\`

## Функции старого API

\`\`\`cpp

// Возвращает имя класса родителя шаблона
// iTempl указывает на шаблон iId индекс родительского класса. 
// Обычно родительский класс один, поэтому его индекс равен 0.
const wchar_t *DiglexGetTemplateClassName(const ITemplate *iTempl, unsigned iId);

// Произвести поиск объектов, но только принадлежащих только указанному классу
// или указанному классу и его иерархии (родительским классам).
// iClassId идентификатор класса
// iFlagHierarchy если true находиться будут так же и объекты принадлежащие иерархии.
void DiglexFindObjectsByClass(unsigned int iClassId, bool iFlagHierarchy);

// Функция аналогичная вышеописанной, но вместо идентификатора класса передаётся его имя
void DiglexFindObjectsByClassName(const wchar_t *iClassName, bool iFlagHierarchy);
\`\`\`


# Пример

\`\`\`cpp

// 1. Настройка локали
// До начала передчачи текстов в библиотеку необходимо
// настроить рантайм на корректную работу с кодировками.
// функция setupSystemLocale обеспечивает правильную работу
// с кодировками приложения, использующего библиотеку Diglex.
DiglexUtils::Misc::setupSystemLocale();

// 2. Получение ссылки на менеджер - объект взаимодействия с библиотекой.
auto &diglex = DiglexManager::getInstance();

// 3. Устанавливаем формат словаря - YAML
diglex.setParserMode(DiglexManager::YAML);

// 4. Загрузка словаря
diglex.LoadTemplates("путь-к-словарю(директория)");

// 5. Загрузка текста для поиска в нём объектов
diglex.SetText("Премия Совета Министров СССР присуждена ...");

// 6. Поиск объектов в тексте
diglex.FindObjects();

// 7. После завершения поиска множество полученных объектов
// извлекается из библиотеки итеративно
size_t objCount = diglex.GetObjectsCount(); // запрос количества найденных объектов

for (size_t i = 0; i < objCount; ++i) {
    // получение указателя на объект.
    const IDiglexObject *diglexObject = diglex.GetDiglexObject(i);

    size_t o_beg = diglexObject->GetBegin(); // Начало вхождения объекта в текст
    size_t o_end = diglexObject->GetEnd(); // Конец вхождения объекта в текст

    // получение константной ссылки на контейнер принадлежащих объекту
    // свойств и итерирование через стандартные C++ средства
    for (const auto & property : diglexObject->GetProperties()) {
        // получение имени свойства
        property.GetName();

        // получение значения объекта (объект в словаре может иметь строковое,
        // булево, целочисленное, или с плавающей точкой значение, но в выдачи результата значение
        // преобразуется в соответствущую строку)
        property.GetValue();
    }

    // Получение указателя на шаблон пользовательского представления
    const ITemplate *objTemplate = diglexObject->GetTemplate();

    // Получение имени классов к которым принадлежит найденный по шаблону объект
    for (size_t i = 0; i < objTemplate.GetParentIds().size(); ++i) {
        DiglexGetTemplateClassName(objTemplate, i);
    }

    // Получить имя шаблона
    std::wstring templateName = objTemplate->GetName();

\`\`\`


В качестве полного примера использования библиотеки может послужить
diglex_streamer/main.cpp, в котором использован основной паттерн использования
библиотеки Diglex (инициализация, загрузка словаря, загрузка текста, поиск,
извлечение результата).
`
};
