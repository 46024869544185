exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SampleTextEditor_editorWrapper__75vr1 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 5px;\n  position: relative; }\n\n.SampleTextEditor_sample-text-editor-wrap__2IxrS .SampleTextEditor_header1__18uXj {\n  height: 2em;\n  width: 100%; }\n\n.SampleTextEditor_sample-text-editor-wrap__2IxrS .SampleTextEditor_footer__2R4ZI {\n  margin: 5px 0 5px 5px;\n  height: 30px;\n  width: 100%; }\n\n.SampleTextEditor_textWrapper__57Ioq {\n  flex-grow: 1;\n  border: rgba(0, 0, 0, 0.25) 1px solid;\n  border-radius: 6px;\n  background: #fff;\n  height: 1%; }\n\n.SampleTextEditor_textArea__wEdVb {\n  border-radius: 6px;\n  white-space: pre-wrap;\n  font-size: 14px;\n  padding: 6px 12px 6px 6px;\n  outline: none;\n  box-sizing: border-box; }\n\n.SampleTextEditor_updateToolbar__QFILl {\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding-top: 5px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n\n.SampleTextEditor_help__2c_Mw {\n  -webkit-transition: color ease .3s;\n  transition: color ease .3s;\n  position: absolute;\n  right: 12px;\n  top: 14px;\n  font-size: 18px; }\n  .SampleTextEditor_help__2c_Mw:hover {\n    color: #1890ff;\n    cursor: pointer; }\n", ""]);

// Exports
exports.locals = {
	"editorWrapper": "SampleTextEditor_editorWrapper__75vr1",
	"sample-text-editor-wrap": "SampleTextEditor_sample-text-editor-wrap__2IxrS",
	"header1": "SampleTextEditor_header1__18uXj",
	"footer": "SampleTextEditor_footer__2R4ZI",
	"textWrapper": "SampleTextEditor_textWrapper__57Ioq",
	"textArea": "SampleTextEditor_textArea__wEdVb",
	"updateToolbar": "SampleTextEditor_updateToolbar__QFILl",
	"help": "SampleTextEditor_help__2c_Mw"
};