import React, {Component} from "react"
import {connect} from 'react-redux'
import NestedRoutes from 'utils/NestedRoutes'
import {Layout} from 'antd';
import userActions from 'ducks/user/userActions';

class EmptyLayout extends Component {
  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(userActions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout);