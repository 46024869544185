import React, {Component} from "react";
import {connect} from 'react-redux';
import NestedRoutes from 'utils/NestedRoutes';
import {Layout} from 'antd';
import Header from 'components/diglex/Header/Header/Header';
import userActions from 'ducks/user/userActions';
import SourceActions from 'ducks/source/Actions';
import drawerActions from 'ducks/drawer/drawerActions';
import {helpRoutes} from 'config/helpRoutes';
import GitterChat from 'components/GitterChat/GitterChat';

class DiglexLayout extends Component {

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.preferences === null && nextProps.preferences) {
      if(nextProps.preferences.showHelpAtStart) {
        const markdown = helpRoutes['/about'];
        this.props.showDrawer(markdown);
      }
    }
  }

  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <GitterChat />
        <Header
          history={this.props.history}
          profile={this.props.profile}
          logout={this.props.logout}

          helpRoutes={helpRoutes}
          showDrawer={this.props.showDrawer}
        />
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    preferences: state.user.preferences,
    profile: state.user.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(userActions.logout()),
    openHelp: () => dispatch(SourceActions.openHelp()),
    showDrawer: (markdown, hash) => dispatch(drawerActions.showDrawer(markdown, hash)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexLayout);