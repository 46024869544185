import ActionTypes from './actionTypes';

const initialState = {
  form: {
    invite: {
      loading: false,
      errors: {},
    },
    remove: {
      loading: false,
      errors: {},
    }
  },
  loading: false,
  values: [],
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.MEMBER_FETCH_REQUEST: {
      return {
        ...state,
        values: [],
        loading: true,
      };
    }
    case ActionTypes.MEMBER_FETCH_FULFILLED: {
      const {members} = payload;
      return {
        ...state,
        values: members,
        loading: false,
      };
    }
    case ActionTypes.MEMBER_FETCH_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }


    case ActionTypes.MEMBER_REMOVE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          remove: {
            loading: true,
            errors: {},
          }
        },
      };
    }
    case ActionTypes.MEMBER_REMOVE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          remove: {
            loading: false,
            errors: {},
          }
        },
      };
    }
    case ActionTypes.MEMBER_REMOVE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          remove: {
            loading: false,
            errors: {},
          }
        },
      };
    }


    case ActionTypes.MEMBER_INVITE_REQUEST: {
      return {
        ...state,
        invite: {
          loading: true,
          errors: {},
        }
      };
    }
    case ActionTypes.MEMBER_INVITE_FULFILLED: {
      const {} = payload;
      return {
        ...state,
        invite: {
          loading: false,
          errors: {},
        }
      };
    }
    case ActionTypes.MEMBER_INVITE_FAIL: {
      return {
        ...state,
        invite: {
          loading: false,
          errors: {},
        }
      };
    }

    default:
      return state;
  }
}
