import {call, put, all, takeEvery} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import * as axios from 'axios';
import {applicationId, apiKey} from 'config/config';
import userActions from './userActions';
import {notification} from 'antd';
import {store} from '../store';
import {SSO_URL} from 'constants/constants';

let refreshTimeout = null;

function updateToken(refreshToken) {
  return new Promise(res => {
    const promise = axios({
      url: `${SSO_URL}/jwt/refresh`,
      method: 'POST',
      data: {
        refreshToken,
      }
    });
    promise.then(response => {
      const token = response.data.token;

      localStorage.setItem('token', token);
      window.diglexAxios = diglexAxiosCreate(token, refreshToken);

      store.dispatch({
        type: ActionTypes.USER_RESTORE_DATA,
        payload: {
          token
        },
      });

      res(response);
    }).catch(e => {
      store.dispatch({
        type: ActionTypes.USER_LOGOUT,
        payload: {},
      });
    });
  });
}

function diglexAxiosCreate(token = "", refreshToken) {
  const diglexAxios = axios.create({
    headers: {
      'Authorization': `JWT ${localStorage.getItem('token')}`,
    }
  });

  const expDate = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
  const now = new Date();

  if (now > expDate) {
    updateToken(refreshToken);
  } else {
    clearTimeout(refreshTimeout);
    setTimeout(updateToken, expDate - now - 2000, refreshToken);
  }

  diglexAxios.interceptors.response.use(null, async error => {
    if (error.response.status === 401) {
      const response = await updateToken(refreshToken);
      error.config.headers.Authorization = `JWT ${response.data.token}`;
      return diglexAxios.request(error.config);
    }

    return Promise.reject(error);
  });

  return diglexAxios;
}

function* getUserPreferences() {
  try {
    const response = yield call(window.diglexAxios, {
      url: '/api/user/preferences',
      method: 'GET'
    });
    const preferences = response.data;
    yield put({
      type: ActionTypes.USER_PREFERENCES_FULFILLED,
      payload: {preferences},
    });
  } catch (e) {
    switch (e.response.status) {
      default: {
        yield put({
          type: ActionTypes.USER_PREFERENCES_FAIL,
          payload: {},
        });
      }
    }
  }
}

function * userChangePreferences({payload}) {
  try {
    yield call(window.diglexAxios, {
      url: '/api/user/preferences',
      method: 'POST',
      data: payload.preferences,
    });
  } catch (e) {
    // todo: handle error
  }
}

function* restore({payload}) {
  if (payload.token && payload.refreshToken) {
    window.diglexAxios = diglexAxiosCreate(payload.token, payload.refreshToken);
    yield put({type: ActionTypes.USER_PREFERENCES_REQUEST, payload: {}});
  }
}

function* logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('profile');
}

function* login({payload}) {
  yield put({type: ActionTypes.USER_PREFERENCES_REQUEST, payload: {}});
}

function* registration({payload}) {
  const {username, email, password} = payload;

  try {
    const response = yield call(axios, {
      url: `${SSO_URL}/user/registration`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiKey,
      },
      data: {
        registration: {
          applicationId,
          username,
        },
        user: {
          username,
          email,
          password,
        }
      }
    });

    const {user} = response.data;
    localStorage.setItem('profile', JSON.stringify(user));

    yield put({
      type: ActionTypes.USER_REGISTRATION_FULFILLED,
      payload: {},
    });
    yield put(userActions.authorization({email, password}));

  } catch (e) {
    switch (e.response.status) {
      case 400: {
        const errors = {};

        Object.entries(e.response.data.fieldErrors).forEach(([key, values]) => {
          key = key.replace('user.', '');
          if (values[0].code.includes('[duplicate]user.email')) {
            errors[key] = {
              value: email,
              errors: [new Error('Пользователь с таким email уже зарегистрирован')],
            };
          }
          if (values[0].code.includes('[duplicate]user.username')) {
            errors[key] = {
              value: username,
              errors: [new Error('Пользователь с таким username уже зарегистрирован')],
            };
          }
          if (values[0].code.includes('[tooShort]user.password')) {
            errors[key] = {
              value: password,
              errors: [new Error('Длина пароля не может быть меньше 8 символов')],
            };
          }
        });

        yield put({
          type: ActionTypes.USER_REGISTRATION_FAIL,
          payload: {errors},
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.USER_REGISTRATION_FAIL,
          payload: {},
        });

        if (e.response.data.generalErrors) {
          yield notification.error({
            duration: 5,
            message: 'Что-то пошло не так.',
            description: e.response.data.generalErrors[0].message,
          });
        }
      }
    }
  }
}

function* authorization({payload}) {
  try {
    const response = yield call(axios, {
      url: `${SSO_URL}/login`,
      method: 'POST',
      data: {
        applicationId,
        loginId: payload.email,
        password: payload.password,
      }
    });

    const {token, user, refreshToken} = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('profile', JSON.stringify(user));
    window.diglexAxios = diglexAxiosCreate(token, refreshToken);

    yield put({
      type: ActionTypes.USER_AUTH_FULFILLED,
      payload: {
        refreshToken,
        token,
        profile: user,
      },
    });
  } catch (e) {
    switch (e.response.status) {
      case 404: {
        yield put({
          type: ActionTypes.USER_AUTH_FAIL,
          payload: {
            errors: {
              email: {
                value: payload.email,
                errors: [new Error('Неверный пароль или email')],
              }
            }
          },
        });
        break;
      }
      default: {
        yield put({
          type: ActionTypes.USER_AUTH_FAIL,
          payload: {},
        });

        if (e.response.data.generalErrors) {
          yield notification.error({
            duration: 5,
            message: 'Что-то пошло не так.',
            description: e.response.data.generalErrors[0].message,
          });
        }
      }
    }
  }
}

export default function* userSaga() {
  yield all([
    yield takeEvery(ActionTypes.USER_AUTH_REQUEST, authorization),
    yield takeEvery(ActionTypes.USER_REGISTRATION_REQUEST, registration),
    yield takeEvery(ActionTypes.USER_PREFERENCES_REQUEST, getUserPreferences),
    yield takeEvery(ActionTypes.USER_CHANGE_PREFERENCES, userChangePreferences),

    yield takeEvery(ActionTypes.USER_RESTORE_DATA, restore),
    yield takeEvery(ActionTypes.USER_LOGOUT, logout),
    yield takeEvery(ActionTypes.USER_AUTH_FULFILLED, login),
  ]);
}