import React, {Component} from "react";
import NestedRoutes from 'utils/NestedRoutes';

function createRouterTitleComponent(title) {

  return class RouterTitleComponent extends Component {

    componentDidMount() {
      this.oldTitle = document.title;
      document.title = title;
    }

    componentWillUnmount() {
      document.title = this.oldTitle;
    }

    render() {
      return (
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      );
    }
  };

}

export default createRouterTitleComponent;