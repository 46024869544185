import ActionTypes from './actionTypes';
import {Modal} from 'antd';
import DiglexInfo from 'components/drawer/DiglexInfo/DiglexInfo';
import React from 'react';

const initialState = {
  modalLink: null,
  modalType: null,
  modalProps: {
    destroyOnClose: true,
    onOk: () => {},
    onCancel: () => {},
  },
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.MODAL_SHOW:
      const modalProps = {
        ...initialState.modalProps,
        ...payload.modalProps,
      };

      const modalLink = Modal[payload.modalType]({
        ...modalProps,
        onOk: () => modalProps.onOk.bind(modalLink)(),
        onCancel: () => modalProps.onCancel.bind(modalLink)(),
      });

      return {
        modalType: payload.modalType,
        modalLink,
        modalProps,
      };
    case ActionTypes.MODAL_HIDE:
      if(state.modalLink) {
        state.modalLink.destroy();
      }
      return initialState;
    default:
      return state;
  }
}
