import React, {Component} from "react";
import {ConnectedRouter} from 'connected-react-router';
import {batch, Provider as ReduxProvider} from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import NestedRoutes from 'utils/NestedRoutes';

import "./App.css";

import routes from 'config/routes';
import {store, history} from './ducks/store';
import ProjectThunks from './ducks/project/Thunks';
import ModalContainer from './containers/DrawerContainer/DrawerContainer';
import userActions from './ducks/user/userActions';
import uiActions from './ducks/ui/uiActions';

window.addEventListener('resize', () => {
  batch(() => {
    store.dispatch(uiActions.update('window', 'width', window.innerWidth));
    store.dispatch(uiActions.update('window', 'height', window.innerHeight));
  });
});

setInterval(function () {
  store.dispatch(ProjectThunks.saveFilesInQueue());
}, 5000);

store.dispatch(userActions.restore());

class App extends Component {
  render() {
    return (
      <ReduxProvider store={store}>
          <ConnectedRouter history={history}>
            <div className="App">
              <NestedRoutes routes={routes} match={{path: ''}}/>
              <ModalContainer/>
            </div>
          </ConnectedRouter>
      </ReduxProvider>
    );
  }
}

export default App;
