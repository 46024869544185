exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexProjectPageModalContainer_container__1JV_L {\n  min-height: calc(100vh - 255px); }\n\n.DiglexProjectPageModalContainer_navBar__1-FAP {\n  width: 256px;\n  position: absolute;\n  height: calc(100% - 65px);\n  left: 0;\n  flex-shrink: 0; }\n\n.DiglexProjectPageModalContainer_wrapper__EYoYH {\n  display: flex;\n  align-items: center; }\n  .DiglexProjectPageModalContainer_wrapper__EYoYH > * {\n    flex-shrink: 0;\n    flex-grow: 0; }\n  .DiglexProjectPageModalContainer_wrapper__EYoYH .DiglexProjectPageModalContainer_space__3HPfu {\n    flex-grow: 1; }\n  .DiglexProjectPageModalContainer_wrapper__EYoYH .DiglexProjectPageModalContainer_title__hxTB3 {\n    margin-left: 12px;\n    font-size: 16px;\n    flex-shrink: 1;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden; }\n  .DiglexProjectPageModalContainer_wrapper__EYoYH .DiglexProjectPageModalContainer_icon__2CRea {\n    margin-left: 12px;\n    color: #999;\n    font-size: 12px; }\n  .DiglexProjectPageModalContainer_wrapper__EYoYH .DiglexProjectPageModalContainer_iconLink__1Pkgh {\n    color: rgba(0, 0, 0, 0.65);\n    -webkit-transition: background-color ease .3s;\n    transition: background-color ease .3s;\n    font-size: 18px;\n    vertical-align: middle; }\n    .DiglexProjectPageModalContainer_wrapper__EYoYH .DiglexProjectPageModalContainer_iconLink__1Pkgh:hover {\n      color: #40a9ff; }\n", ""]);

// Exports
exports.locals = {
	"container": "DiglexProjectPageModalContainer_container__1JV_L",
	"navBar": "DiglexProjectPageModalContainer_navBar__1-FAP",
	"wrapper": "DiglexProjectPageModalContainer_wrapper__EYoYH",
	"space": "DiglexProjectPageModalContainer_space__3HPfu",
	"title": "DiglexProjectPageModalContainer_title__hxTB3",
	"icon": "DiglexProjectPageModalContainer_icon__2CRea",
	"iconLink": "DiglexProjectPageModalContainer_iconLink__1Pkgh"
};