import React, {Component} from 'react';
import {Layout, Menu, Icon} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import NestedRoutes from 'utils/NestedRoutes';
import {normalizePath} from 'utils';

const {Sider} = Layout;

class BaseLayout extends Component {

  state = {
    collapsed: false
  };

  onCollapse = (collapsed) => {
    this.setState({collapsed});
  };

  renderMenu = routes => {
    const renderItem = item => {
      return (
        <Menu.Item
          key={item.path}
          onClick={() => this.props.history.push('/corpora' + item.path)}
        >
          <Icon type={item.icon}/>
          <span>{item.text}</span>
        </Menu.Item>
      );
    };
    const renderSub = item => {
      return (
        <Menu.SubMenu
          key={`${item.text}-${item.icon}`}
          title={<span><Icon type={item.icon}/><span>{item.text}</span></span>}
        >
          {this.renderMenu(item.routes)}
        </Menu.SubMenu>
      );
    };

    return routes.map(route => route.sub ? renderSub(route) : renderItem(route));
  };

  testPath = path => {
    const testPath = new RegExp(`^${normalizePath(path)}`);
    return testPath.test(this.props.location.pathname);
  };

  openKeys = () => {
    const keys = [];

    const findOpenKey = (routes, path, key) => {
      routes.forEach(route => {
        if (key && this.testPath(path + route.path)) {
          keys.push(key);
        }
        if (route.routes) {
          findOpenKey(
            route.routes,
            route.path ? `${path}${route.path}` : path,
            route.sub ? `${route.text}-${route.icon}` : null
          );
        }
      });
    };

    findOpenKey(this.props.routes, '/', null);
    return keys;
  };

  selectedKey = () => {
    const keys = [];

    const findSelectedKey = (routes, path) => {
      routes.forEach(route => {
        if (this.testPath(path + route.path)) {
          keys.push(route.path);
        }
        if (route.routes) {
          findSelectedKey(route.routes, route.path ? `${path}${route.path}` : path);
        }
      });
    };

    findSelectedKey(this.props.routes, '/');
    return keys;
  };

  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={this.selectedKey()}
            defaultOpenKeys={this.openKeys()}
          >
            {this.renderMenu(this.props.routes)}
          </Menu>
        </Sider>

        <Layout>
          <NestedRoutes routes={this.props.routes} match={this.props.match}/>
        </Layout>
      </Layout>
    );
  }
}

export default connect()(withRouter(BaseLayout));