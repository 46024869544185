import ActionTypes from './ActionTypes.js';
import {Color} from 'utils'
import {notification} from 'antd';

const initialState = {
  'extractResult': null,
  'files': []
};

export default function diglex(state = initialState, action) {
  switch (action.type) {

    case ActionTypes.DIGLEX_EXTRACT_START: {
      return Object.assign({}, state, {
        isWaitingExtractResult: true
      });
    }

    case ActionTypes.DIGLEX_EXTRACT_DONE: {
      return Object.assign({}, state, {
        availableProjects: action['payload']['projects']
      });
    }

    case ActionTypes.DIGLEX_EXTRACT_CLEAR: {
      return {...state, extractResult: null};
    }

    case ActionTypes.WS_EXTRACT_EXCEPTION: {
      //todo: make it sagas
      notification.error({
        duration: 4,
        message: action.payload.type,
        description: action.payload.text,
      });

      return {
        ...state,
        isWaitingExtractResult: false,
        extractResult: null
      }
    }

    case ActionTypes.WS_EXTRACT_RESULT: {
      const extractResult = action['payload'];

      if (!extractResult['objects']) {
        // todo: handle error?
      }

      extractResult.extractId = state.extractResult && state.extractResult.extractId ? ++state.extractResult.extractId : 1;

      extractResult['objects'].forEach((object, index) => {
        object.color = Color.fromStr(object.class);
        object.uid = `extract-row-${index}`;
        object.markRefs = [];
      });

      return {
        ...state,
        isWaitingExtractResult: false,
        extractResult
      }
    }

    default: {}

  }

  return state;
}
