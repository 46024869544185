import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import thunk from "redux-thunk";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import createRootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

/** todo: remove it at production build? */
const devPlugins = [];
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  devPlugins.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history)),
    ...devPlugins
  )
);

sagaMiddleware.run(rootSaga);

export {history, store};