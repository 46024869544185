import React from "react";
import {connect} from "react-redux";
import {Avatar, Card, Icon, Menu} from 'antd';
import styles from './DiglexProjectPageModalContainer.module.scss';
import NestedRoutes from 'utils/NestedRoutes';
import projectActions from 'ducks/project/projectActions';

class DiglexProjectPageModalContainer extends React.Component {

  constructor(props) {
    super(props);
    const {type, owner, projectPath} = this.props.match.params;
    this.fullPath = `/${type}/${owner}/${projectPath}`;
  }

  componentDidMount = () => {
    this.props.fetchProject(this.fullPath);
  };

  renderTitle = () => (
    <div className={styles.wrapper}>
      {this.props.activeProject.type === 'user' ?
        <Avatar icon="user" title="user project"/>
        :
        <Avatar icon="team" title="group project"/>
      }

      <div className={styles.title}>
        {this.props.activeProject.name}
      </div>

      {this.props.activeProject.access === 'public' ?
        <Icon className={styles.icon} type="book" theme="outlined" title="публичный проект"/>
        :
        <Icon className={styles.icon} type="lock" theme="filled" title="закрытый проект"/>
      }

    </div>
  );

  handleClick = ({key}) => {
    if (key === 'general') {
      return this.props.history.push(this.props.match.url);
    }
    this.props.history.push(`${this.props.match.url}/${key}`);
  };

  render() {
    if (!this.props.activeProject) {
      return null;
    }

    let key = ['members'];
    const math = /\/[^\/]+\/[^\/]+\/[^\/]+\/[^\/]+\/([^\/]+)$/.exec(this.props.pathname);

    if (math) {
      key = [math[1]];
    }

    return (
      <Card
        className={styles.container}
        bodyStyle={{padding: '1px 0 0 0', display: 'flex', paddingLeft: 256}}
        title={this.renderTitle()}
      >
        <Menu
          onClick={this.handleClick}
          className={styles.navBar}
          defaultSelectedKeys={key}
          mode="inline"
        >
          <Menu.Item key="members">
            <Icon type="team"/>
            Участники
          </Menu.Item>

          <Menu.Item key="settings">
            <Icon type="setting" />
            Настроки
          </Menu.Item>
        </Menu>

        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    activeProject: state.project.activeProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: (fullPath) => dispatch(projectActions.fetchProjects('get', fullPath)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexProjectPageModalContainer);
