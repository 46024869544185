import React from 'react'
import ActionTypes from './ActionTypes';
import Actions from './Actions';
import {put, takeLatest, takeEvery, all, select, call} from 'redux-saga/effects';
import {BASE_URL} from "../../constants/constants";

// import {DIGLEX_HELP_TEXT} from '../../constants/text/diglexHelp';

export function* openHelp() {
  const state = yield select();

  if (/^\/project\//.test(state.router.location.pathname)) {
    let tab = state.source.tabs.find(tab => tab.name === "Help" && tab.type === "markdown");

    if (!tab) {
      yield put(Actions.newTab({name: "Help", source: "", type: "markdown"}));
      const state = yield select();
      tab = state.source.tabs.find(tab => tab.name === "Help" && tab.type === "markdown");
    }

    yield put(Actions.activateTab({key: tab.key, }));
  }

  //yield put(Actions.activateTab(tab.key));
}

export default function* recordSaga() {
  yield all([
    yield takeLatest(ActionTypes.SOURCE_OPEN_HELP, openHelp),
  ]);
}