import React from "react";
import {connect} from "react-redux";
import {Avatar, Card, Icon, Menu} from 'antd';
import styles from './DiglexGroupPageContainer.module.scss';
import NestedRoutes from 'utils/NestedRoutes';
import groupActions from 'ducks/group/groupActions';

class DiglexGroupPageContainer extends React.Component {

  constructor(props) {
    super(props);
    const {groupPath} = this.props.match.params;
    this.groupPath = groupPath;
  }

  componentDidMount = () => {
    this.props.fetchGroup(this.groupPath);
  };

  renderTitle = () => (
    <div className={styles.wrapper}>
      <Avatar icon="team?" title="group"/>

      <div className={styles.title}>
        {this.props.activeGroup.name}
      </div>
    </div>
  );

  handleClick = ({key}) => {
    if (key === 'general') {
      return this.props.history.push(this.props.match.url);
    }
    this.props.history.push(`${this.props.match.url}/${key}`);
  };

  render() {
    if (!this.props.activeGroup) {
      return null;
    }

    let key = ['projects'];
    const math = /\/[^\/]+\/[^\/]+\/([^\/]+)$/.exec(this.props.pathname);
    if (math) {
      key = [math[1]];
    }

    // let extra = null;
    //
    // switch (key[0]) {
    //   case 'projects': {
    //     extra = (
    //       <a onClick={() => this.props.history.push(`/projects/new?group=${this.props.activeGroup._id}`)}>
    //         Новый проект
    //       </a>);
    //     break;
    //   }
    // }

    return (
      <Card
        className={styles.container}
        bodyStyle={{padding: '1px 0 0 0', display: 'flex', paddingLeft: 256}}
        // extra={extra}
        title={this.renderTitle()}
      >
        <Menu
          onClick={this.handleClick}
          className={styles.navBar}
          defaultSelectedKeys={key}
          mode="inline"
        >
          {/*<Menu.Item key="general">*/}
          {/*<Icon type="bars"/>*/}
          {/*Общие*/}
          {/*</Menu.Item>*/}

          <Menu.Item key="projects">
            <Icon type="database"/>
            Проекты
          </Menu.Item>

          <Menu.Item key="members">
            <Icon type="team"/>
            Участники
          </Menu.Item>

          <Menu.Item key="settings">
            <Icon type="setting"/>
            Настроки
          </Menu.Item>
        </Menu>

        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    activeGroup: state.group.activeGroup,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroup: (path) => dispatch(groupActions.fetchGroups('get', path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexGroupPageContainer);
