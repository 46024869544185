import React from "react";
import {connect} from "react-redux";
import {Card} from 'antd';
import styles from './DiglexProjectsContainer.module.scss';
import NestedRoutes from 'utils/NestedRoutes';

const tabList = [
  {
    key: '/projects',
    tab: 'Все проекты',
  },
  {
    key: '/projects/personal',
    tab: 'Персональные проекты',
  },
  {
    key: '/projects/groups',
    tab: 'Групповые проекты',
  },
  {
    key: '/projects/explore',
    tab: 'Публичные проекты',
  },
];

class DiglexProjectsContainer extends React.Component {
  onTabChange = key => {
    this.props.history.push(key);
  };

  newGroup = () => {
    this.props.history.push('/groups/new');
  };

  render() {
    return (
      <Card
        className={styles.container}
        title="Проекты"
        // extra={<a onClick={() => this.props.history.push('/projects/new')}>Новый проект</a>}
        tabList={tabList}
        activeTabKey={this.props.pathname}
        onTabChange={this.onTabChange}
        bodyStyle={{padding: 0}}
      >
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexProjectsContainer);
