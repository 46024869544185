import React, {Component} from "react";
import styles from './MovieBotLayout.module.scss';
import {connect} from 'react-redux';
import NestedRoutes from 'utils/NestedRoutes';
import {Layout, Menu} from 'antd';
import HeaderDropdown from 'components/HeaderDropdown/HeaderDropdown';

class MovieBotLayout extends Component {

  state = {
    toggled: false
  };

  toggle = toggled => this.setState({toggled});

  handler = ({key}) => {
    this.toggle(false);

    switch (key) {
      case "movies": {
        this.props.history.push('/movie-bot/movies');
        break
      }
      case "chats": {
        this.props.history.push('/movie-bot/chats');
        break
      }
      default:
    }
  };

  render() {
    const groupMenu = (
      <Menu onClick={this.handler}>
        <Menu.Item key={'movies'}>Movies</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={'chats'}>Chats</Menu.Item>
      </Menu>
    );

    return (
      <Layout style={{minHeight: '100vh'}}>
        <Layout.Header
          className={styles.header}
        >
          MovieBot

          <HeaderDropdown
            overlay={groupMenu}
            overlayClassName={styles.dropDown}
            onVisibleChange={this.toggle}
            placement="bottomLeft"
            trigger={['click']}
          >
            <div>
              <div className={`${this.state.toggled ? styles.menuToggled : styles.menu}`}>
                Entities
              </div>
            </div>
          </HeaderDropdown>
        </Layout.Header>
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieBotLayout);