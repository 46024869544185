export default {
  title: 'Язык Diglex',
  source: `
Diglex
============

Diglex использует [YAML](http://yaml.org) в качестве основы для синтаксиса описания шаблонов и классов.

# Кратко о YAML

YAML это простой язык, он удобен как для людей так и для компьютеров.

Важно помнить при работе с YAML:
* Использовать пробелы, а не табуляцию для указания структуры.
* Всегда использовать UTF-8.
* Использовать моноширный шрифт в редакторе.

## Комментарии

\`\`\`yaml
# Эта строка - комментарий
Ключ: значение  # <- символ начала комментария
\`\`\`

## Простые типы данных

\`\`\`yaml
# Простые типы данных
1  # Число
1.5 # Вещественное число
Москва  # Строка
"Москва" # Тоже строка
'Москва' # Тоже строка
\`\`\`

## Сопоставления имени и значения

\`\`\`yaml
# Блочный формат
Имя: Джон Смит
Возраст: 33

# Однострочный формат
{Имя: Джон Смит, Возраст: 33}
\`\`\`

## Списки

\`\`\`yaml
# Блочный формат
- Новосибирск
- Eкатеринбург
- Москва

# Однострочный формат
[Новосибирск, Екатеринбург, Москва]
\`\`\`

## Любые данные можно комбинировать

\`\`\`yaml
---  # <- указатель начала данных
Имя: Джон Смит
Возраст: 33
Проживал в городах:
  - Новосибирск
  - 'Eкатеринбург'
  - "Москва"
\`\`\`


# Синтаксис Diglex

## Описание классов и шаблонов

\`\`\`yaml
---
class:
  name: должность         # наименование класса
  parent: базовый-класс   # наименование базового класса (не обязательно)
  properties:             # произвольный набор имен и значений свойств класса
    Одушевленность: да
    Тематика: работа и профессия 
  # для каждого класса описывается список шаблонов
  templates:
    - name: академик-секретарь          # Используя имя шаблона можно ссылаться 
      properties:                       # Подобно классам, в шаблонах можно описывать свойства
        Индекс: 1
      cases:                            # Список возможных паттернов шаблона
        - академик{...}-секретар{...}       # Сопоставленине считается успешным, если сопоставляеся
        - акад-секр                     # хотя бы один
    - name: ведущий научный сотрудник
      properties:
        Индекс: 2
      cases:
        - ведущ{...} научн{...} сотрудник{...}
        - внс
\`\`\`

## Регистр.

Данное новое свойство позволяет указывать – учитывать ли при обработке регистр
или нет. Свойство полезно, например, для описания аббревиатур.

\`\`\`yaml
---
# Вариант для реализации: в любом из cases добавить <сs> - чувствительно к регистру
class:
  name: РАН
  templates:
    - cases:
        - РАН<cs>
# более семантический вариант (не планируется реализовывать): опции задаются отдельным ключем
class:
  name: РАН
  templates:
    - cases:
        - options: {CaseSensitive: True}
          pattern: РАН
\`\`\`


## Ссылки на шаблоны

Самой сильной возможностью записи шаблона является использование
в нем ссылок на другой шаблон. Для этого у шаблона должно быть объявлено имя. 

Вложенность шаблонов может быть многоуровневой, т.е. тело шаблона может ссылаться на
другой шаблон, тот, в свою очередь, на другой и т.д. Это позволяет создавать очень сложные
шаблоны в компактном виде. Не допускаются ссылки шаблонов самих на себя, а также кольцевые
ссылки. Еще одной возможностью в языке является ссылка на класс шаблонов, которая позволяет
указать множество альтернативных ссылок на шаблоны.

\`\`\`yaml
---
class:
  name: организации РАН
  templates:
    - name: РАН
      cases:
        - ран
        - российск{...} академи{...} наук
class:
  name: организации РАН
  templates:
    - name: СО РАН
      cases:
       - СО РАН
       - сибирск{...} отделен{...} [РАН]
class:
  name: организации РАН
  templates:
    - name: ИМ СО РАН
      cases:
        - институт{...} математики [СО РАН]
      
\`\`\`

## Предопределенные шаблоны

Простые литералы, такие как: RussianLetter, EnglishLetter, MathSymbol, PunctuationSymbol, SpaceSymbol
можно считать предопределенными шаблонами для общности синтаксиса.

(имена взяты из текущей реализации, можно придумать более короткие)

\`\`\`yaml
---
class:
  name: инициалы
  templates:
    - name: имя-отч
      properties:
        Имя:
        Индекс: 0
      cases:
        - '[RussianLetter].'
        - '[RussianLetter].[RussianLetter].'
\`\`\`

## Хвост

Чтобы учесть словоизменение слов желательно использовать морфологический
анализатор, который каждому изменяемому слову языка сопоставит его форму. С помощью
шаблонов такое описание будет неэффективным и трудоемким, поэтому используется способ,
позволяющий указать неизменяемую основу слова, а оставшуюся часть обозначить хвостом.

\`\`\`yaml
научн{...} сотрдник{...}
\`\`\`


## Поддержка морфологии

Для случаев, когда использование "Хвоста" не хватает можно применять морфологические ограничения.

Для ссылки на шаблон возможна установка ограничения "[месяц:gent,sing]" (соответствует любому варианту из шаблона "[месяц]" в родительном падеже и единственном числе).
Если вариант шаблона "[месяц]" состоит более чем из одного слова, то берется последнее из них.
Для указания любого слова вместо имени шаблона указывается "*", например, "[*:noun,gent,sing]" (соответствует любому существительному в родительном падеже и единственном числе).

Для ссылки не на шаблон, а слово используется запись: "[>ноябрь]" (соотвествует  любой словоформе лексемы "ноябрь"). 
В такой записи также допустимы морфологические ограничения.
Например, "[>ноябрь:plur]" соотвествует любой словоформе лексемы "ноябрь" в множественном числе. 


## Повторитель.

Повторитель позволяет описывать повторяющиеся последовательности
символов. При этом можно либо указывать точную длину последовательности, либо задать
количество возможных повторений с помощью интервала.

\`\`\`yaml
---
# Фигурные скобки исползуются в регулярных выражениях, можно использовать и в Diglex.
class:
  name: телефон
  templates:
    - cases:
        - [цифра]<2,3>-[цифра]<2>-[цифра]<2>
\`\`\`

## Опция

Опция является одним из способов сокращения записи
шаблонов и фиксирует использование необязательных элементов. Например, часто при
сокращениях не ставят точку или пробел:

\`\`\`yaml
н{.}<?>{ }<?>с{.}<?>
\`\`\`

## Дистантный контекст

Одной из отличительных особенностей рассматриваемого языка,
является возможность выделить фрагмент текста неизвестной заранее длины по заданным
начальному и конечному элементам:

\`\`\`yaml
---
template:
  name: начало письма
  cases:
    - уважаем{...} <-.-> !
\`\`\`

Данный шаблон позволяет выделить обращение к комулибо. Элемент <-.-> указывает на
произвольный текст между двумя границами. Если одна из границ не будет задано, то будет
выделяться весь текст до конца (если не задан конечный маркер) или с начала (если не задан
начальный маркер)

## Абзац (TODO)

Использование символа абзаца (\\\\n) позволяет применять шаблон к фрагменту
большему, чем абзац, и использовать данный символ в качестве ограничителя при описании
шаблона.



## Условие.

Данный параметр накладывает ограничение на контекст шаблона. Рассмотрим пример:

\`\`\`yaml
---
class:
  name: Сотрудник
  templates:
    - cases:
       - научный<not> сотрудник{...}
\`\`\`

Данный шаблон позволяет выделить в тексте термин ​сотрудник не как название должности, а
как указание на место работы человека. Это обеспечивается с помощью предварительного
отрицательного условия на наличие термина ​научный, являющегося признаком должности.

Ещё один пример:

\`\`\`yaml
class:
  name: цветофильтр
  templates:
    -cases:
      - '{красный, желтый,}<not> зеленый'
\`\`\`
В данном примере формируется поисковой паттерн " зеленый", без отрицания сформировался бы паттерн
"краcный, желтый, зелёный"

Условие может указываться как для непосредственно предшествующего, так и последующего
за основным содержанием шаблона фрагмента. Внутри шаблона условие возможно использовать
только в рамках дистантного контекста, накладывая ограничение на его длину. Так, шаблон,
выделяющий обращение, должен содержать внутри дистантного контекста условие, например,
отсутствие символа абзаца.

\`\`\`yaml
---
class:
  name: Сотрудник
  templates:
    - cases:
        - уважаем{...} <- not{\\n} -> !
\`\`\`

## Экранирование

В шаблонах присуствуют специальные символы: (), {} и [].
Чтобы указать парсеру, что это не специальный символ, а часть текста необходимо его экранировать.

Варианты экранирования:

* Дублирование спец. симнвола: (()), {{}}, [[]]
* Использование экранирующего символа: \\\\(\\), \\\\{\\}, \\\\[\\]



`
};
