exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexProjectCreateContainer_container1280__1FFnd, .DiglexProjectCreateContainer_container__1tbIF {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexProjectCreateContainer_container1280__1FFnd, .DiglexProjectCreateContainer_container__1tbIF {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexProjectCreateContainer_url__3hpoF {\n  color: #999;\n  font-size: 12px; }\n\n.DiglexProjectCreateContainer_radio__gRT8E {\n  display: block;\n  height: 18px;\n  line-height: 18px; }\n\n.DiglexProjectCreateContainer_radioInfo__37Js0 {\n  line-height: 16px;\n  color: #999;\n  margin: 4px 0 16px 24px; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexProjectCreateContainer_container1280__1FFnd",
	"container": "DiglexProjectCreateContainer_container__1tbIF",
	"url": "DiglexProjectCreateContainer_url__3hpoF",
	"radio": "DiglexProjectCreateContainer_radio__gRT8E",
	"radioInfo": "DiglexProjectCreateContainer_radioInfo__37Js0"
};