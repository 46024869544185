import React, {Component} from "react"
import {connect} from 'react-redux'
import NestedRoutes from 'utils/NestedRoutes'
import {Layout} from 'antd';
import styles from './LoginLayout.module.scss'

class LoginLayout extends Component {
  render() {
    return (
      <Layout style={{minHeight: '100vh'}} className={styles.background}>
        <div className={styles.header}>
          <div className={styles.space} />
          <div className={styles.logo} />
          <div className={styles.space} />
        </div>
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);