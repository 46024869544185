
const ActionTypes = {

  GROUP_CREATE_REQUEST: 'GROUP_CREATE_REQUEST',
  GROUP_CREATE_FULFILLED: 'GROUP_CREATE_FULFILLED',
  GROUP_CREATE_FAIL: 'GROUP_CREATE_FAIL',

  GROUP_DELETE_REQUEST: 'GROUP_DELETE_REQUEST',
  GROUP_DELETE_FULFILLED: 'GROUP_DELETE_FULFILLED',
  GROUP_DELETE_FAIL: 'GROUP_DELETE_FAIL',

  GROUP_FETCH_REQUEST: 'GROUP_FETCH_REQUEST',
  GROUP_FETCH_FULFILLED: 'GROUP_FETCH_FULFILLED',
  GROUP_FETCH_FAIL: 'GROUP_FETCH_FAIL',

  SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
};

export default ActionTypes;
