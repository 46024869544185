const ActionTypes = {
  MOVIE_BOT_TAG_REQUEST: 'MOVIE_BOT_TAG_REQUEST',
  MOVIE_BOT_TAG_FULFILLED: 'MOVIE_BOT_TAG_FULFILLED',
  MOVIE_BOT_TAG_FAIL: 'MOVIE_BOT_TAG_FAIL',

  MOVIE_BOT_TAG_ADD: 'MOVIE_BOT_TAG_ADD',
  MOVIE_BOT_TAG_DELETE: 'MOVIE_BOT_TAG_DELETE',
  MOVIE_BOT_TAG_CHANGE: 'MOVIE_BOT_TAG_CHANGE',
};

export default ActionTypes;
