import ActionTypes from './actionTypes.js';

const ModalActions = {

  showModal: (modalType, modalProps) => {
    return {
      type: ActionTypes.MODAL_SHOW,
      payload: {modalType, modalProps},
    };
  },

  hideModal: () => {
    return {
      type: ActionTypes.MODAL_HIDE,
      payload: {},
    };
  },

};

export default ModalActions;
