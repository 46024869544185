import ActionTypes from './actionTypes.js';

const drawerActions = {

  showDrawer: (markdown, hash) => {
    return {
      type: ActionTypes.DRAWER_SHOW,
      payload: {markdown, hash},
    };
  },

  hideDrawer: () => {
    return {
      type: ActionTypes.DRAWER_HIDE,
      payload: {},
    };
  },

};

export default drawerActions;
