import {eventChannel} from 'redux-saga';
import {all, call, put, take, takeLatest, select, delay} from 'redux-saga/effects';
import ActionTypes from './ActionTypes';
import {receivedSocketMessage} from './Actions';
import {openWS} from "../../utils/ws";

function createWsChannel(ws) {
  return eventChannel(emit => {
    ws.onmessage = (event) => {
      emit(JSON.parse(event.data));
    };
    return () => {
      ws.onmessage = null;
    };
  });
}

export function* handleExtract({payload}) {
  const {project, text} = payload;

  const state = yield select();
  const ws = yield call(openWS, {
    token: state.user.token,
    path: 'ws-diglex',
  });
  const {socketChannel} = ws;

  let hasSend = false;
  let needSend = true;

  while (needSend) {
    if (!hasSend) {
      hasSend = true;
      try {
        ws.send(JSON.stringify({
          'event': 'extract',
          'projectId': project._id,
          'text': text
        }));
      } catch (e) {
        yield put({
          type: ActionTypes.WS_EXTRACT_EXCEPTION,
          payload: {
            type: 'Ошибка',
            text: 'Что-то пошло не так.',
          }
        });
        return;
      }
    }
    const message = yield take(socketChannel);

    switch (message.event) {
      case 'diglex-error': {
        yield put({
          type: ActionTypes.WS_EXTRACT_EXCEPTION,
          payload: {
            type: 'Ошибка',
            text: 'Что-то пошло не так.',
          }
        });
        needSend = false;
        break;
      }

      case 'end-stream': {
        needSend = false;
        break;
      }

      case 'task-accepted': {
        break;
      }

      case 'extract-result': {
        yield put(receivedSocketMessage(message));
        needSend = false;
        break;
      }

      case 'diglex-lib-handled-exception': {
        yield put({
          type: ActionTypes.WS_EXTRACT_EXCEPTION,
          payload: {
            type: message.payload.type,
            text: message.payload.text,
          }
        });
        break;
      }

      default:
        console.error(message);
    }
  }
}

export default function* diglexSagas() {
  yield all([
    yield takeLatest(ActionTypes.DIGLEX_EXTRACT_START, handleExtract),
  ]);
}