import React from 'react';
import {Form, Button, Input, Icon, Card} from 'antd';
import styles from './RegistrationFormContainer.module.scss';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import userActions from 'ducks/user/userActions';

class RegistrationFormContainer extends React.Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {username, email, password} = values;
        this.props.registration({username, email, password});
      }
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.registration.fieldErrors !== nextProps.user.registration.fieldErrors) {
      this.props.form.setFields(nextProps.user.registration.fieldErrors);
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Введённые пароли не совпадают');
    } else {
      callback();
    }
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className={styles.wrapper}>
        <div className={styles.space} />

        <Card
          title="Регистрация"
          headStyle={{textAlign: 'center'}}
          bordered={false}
          style={{width: 348}}
        >

          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {type: 'email', message: 'Введите корректный e-mail'},
                  {required: true, message: 'Введите e-mail'},
                ],
              })(
                <Input
                  size='large'
                  prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                  placeholder="E-mail"
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('username', {
                rules: [
                  {required: true, message: 'Введите username'},
                ],
              })(
                <Input
                  size='large'
                  prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                  placeholder="username"
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Введите пароль',
                  },
                  {
                    validator: (r, v, c) => v.length < 8 ? c('Длина пароля не может быть меньше 8 символов') : c(),
                  },
                ],
              })(<Input.Password
                size='large'
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                placeholder="Пароль"
              />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'Пожалуйста, подтвердите ваш пароль',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password
                size='large'
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)', position: 'relative', left: '-2px'}}/>}
                placeholder="Пароль ещё раз"
              />)}
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
              <Button
                size='large'
                loading={this.props.user.registration.loading}
                type="primary"
                htmlType="submit"
                className={styles.btn}
              >
                Зарегистрироваться
              </Button>

              <div className={styles.link}>
                Уже зарегистрированы?
                {' '}
                <Link to={'/user/login'}>Войдите</Link>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div className={styles.space} />
        <div className={styles.space} />
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registration: ({username, email, password}) => dispatch(userActions.registration({username, email, password})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create({})(RegistrationFormContainer)));