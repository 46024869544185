import {call, put, all, takeEvery} from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import * as axios from 'axios';
import {MOVIE_BOT_URL} from 'constants/constants';
import {notification} from 'antd';

function* fetchMovies({payload}) {
  const {search, page, page_size, id} = payload;
  let results = null;
  let count = 1;

  try {
    if (id) {
      const response = yield call(axios, {
        url: `${MOVIE_BOT_URL}/movies/${id}`,
        method: 'GET',
      });
      results = [response.data];
    } else {
      const response = yield call(axios, {
        url: `${MOVIE_BOT_URL}/movies/`,
        method: 'GET',
        params: {search, page, page_size}
      });
      results = response.data.results;
      count = response.data.count;
    }

    yield put({
      type: ActionTypes.MOVIE_BOT_MOVIE_FULFILLED,
      payload: {
        count,
        results: results.map(movie => {
          movie.key = movie.id;
          movie.links = [
            {
              url: `https://www.imdb.com/title/tt${movie.imdb_id}/`,
              name: 'imdb'
            },
            {
              url: `https://movielens.org/movies/${movie.movielens_id}`,
              name: 'movielens'
            },
          ];
          return movie;
        })
      },
    });

  } catch (e) {
    yield put({
      type: ActionTypes.MOVIE_BOT_MOVIE_FAIL,
      payload: {},
    });
    yield notification.error({
      duration: 4,
      message: 'Что-то пошло не так.',
      description: e.toString(),
    });
  }
}

export default function* userSaga() {
  yield all([
    yield takeEvery(ActionTypes.MOVIE_BOT_MOVIE_REQUEST, fetchMovies),
  ]);
}