import ActionTypes from './actionTypes.js';

const groupActions = {

  createGroup({name, path, description, access}) {
    return {
      type: ActionTypes.GROUP_CREATE_REQUEST,
      payload: {name, path, description, access},
    };
  },

  fetchGroups(type, path) {
    return {
      type: ActionTypes.GROUP_FETCH_REQUEST,
      payload: {type, path},
    };
  },

  deleteGroup(id) {
    return {
      type: ActionTypes.GROUP_DELETE_REQUEST,
      payload: {id},
    };
  },

  setActiveGroup(group) {
    return {
      type: ActionTypes.SET_ACTIVE_GROUP,
      payload: {group},
    };
  },

};

export default groupActions;
