import React from 'react';
import {Menu} from 'antd';
import styles from './HeaderProject.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';

export default class HeaderProject extends React.Component {
  state = {
    toggled: false
  };
  toggle = toggled => this.setState({toggled});

  handler = ({key}) => {
    this.toggle(false);

    switch (key) {
      case "projects": {
        this.props.history.push('/projects');
        break
      }
      case "user-projects": {
        this.props.history.push('/projects/personal');
        break
      }
      case "group-projects": {
        this.props.history.push('/projects/groups');
        break
      }
      case "explore-projects": {
        this.props.history.push('/projects/explore');
        break
      }
      case "new-project": {
        this.props.history.push('/projects/new');
        break
      }
      default:
    }
  };

  render() {
    const projectMenu = (
      <Menu onClick={this.handler}>
        <Menu.Item key={'projects'}>Все проекты </Menu.Item>
        <Menu.Item key={'user-projects'}>Персональные проекты</Menu.Item>
        <Menu.Item key={'group-projects'}>Групповые проекты</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={'explore-projects'}>Публичные проекты</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={'new-project'}>Новый проект</Menu.Item>
      </Menu>
    );
    return (
      <HeaderDropdown
        overlay={projectMenu}
        overlayClassName={styles.dropDown}
        onVisibleChange={this.toggle}
        placement="bottomLeft"
        trigger={['click']}
      >
        <div>
          <div className={`${this.state.toggled ? styles.menuToggled : styles.menu}`}>
            Проекты
          </div>
        </div>
      </HeaderDropdown>
    );
  }
}
