exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexProjectForkContainer_container1280__1GD8h, .DiglexProjectForkContainer_container__1ov9W {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexProjectForkContainer_container1280__1GD8h, .DiglexProjectForkContainer_container__1ov9W {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexProjectForkContainer_url__6cEl_ {\n  color: #999;\n  font-size: 12px; }\n\n.DiglexProjectForkContainer_radio__3hTIO {\n  display: block;\n  height: 18px;\n  line-height: 18px; }\n\n.DiglexProjectForkContainer_radioInfo__1tJbR {\n  line-height: 16px;\n  color: #999;\n  margin: 4px 0 16px 24px; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexProjectForkContainer_container1280__1GD8h",
	"container": "DiglexProjectForkContainer_container__1ov9W",
	"url": "DiglexProjectForkContainer_url__6cEl_",
	"radio": "DiglexProjectForkContainer_radio__3hTIO",
	"radioInfo": "DiglexProjectForkContainer_radioInfo__1tJbR"
};