import React from 'react';
import { Dropdown } from 'antd';
import styles from './HeaderDropdown.module.scss';

export default class HeaderDropdown extends React.Component {
  render() {
    const { overlayClassName, ...props } = this.props;

    return (
      <Dropdown overlayClassName={`${styles.container} ${overlayClassName}`} {...props} />
    );
  }
}
