import React from 'react';
import styles from './CodeEditor.module.scss';
import * as monaco from 'monaco-editor';

export default class CodeEditor extends React.Component {

  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.code = this.props.code || '';
    this.options = this.props.options || {};
  }

  componentDidMount() {
    this.editor = monaco.editor.create(
      this.ref.current,
      {
        scrollbar: {
          verticalScrollbarSize: 12,
          horizontalScrollbarSize: 12,
        },
        value: this.code,
        language: 'yaml',
        minimap: {enabled: false},
        automaticLayout: true,
        roundedSelection: false,
        ...this.options,
      },
    );

    this.editor.onDidChangeModelContent(() => {
      this.props.onChange(this.editor.getValue());
    })
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if(this.props.options.readOnly !== nextProps.options.readOnly) {
      this.editor.updateOptions({readOnly: nextProps.options.readOnly})
    }

    return false;
  }

  render() {
    return (
      <>
        <div className={styles.editor} ref={this.ref}/>
      </>
    );
  }
}


