
const ActionTypes = {
  MEMBER_FETCH_REQUEST: 'MEMBER_FETCH_REQUEST',
  MEMBER_FETCH_FULFILLED: 'MEMBER_FETCH_FULFILLED',
  MEMBER_FETCH_FAIL: 'MEMBER_FETCH_FAIL',

  MEMBER_INVITE_REQUEST: 'MEMBER_INVITE_REQUEST',
  MEMBER_INVITE_FULFILLED: 'MEMBER_INVITE_FULFILLED',
  MEMBER_INVITE_FAIL: 'MEMBER_INVITE_FAIL',

  MEMBER_REMOVE_REQUEST: 'MEMBER_REMOVE_REQUEST',
  MEMBER_REMOVE_FULFILLED: 'MEMBER_REMOVE_FULFILLED',
  MEMBER_REMOVE_FAIL: 'MEMBER_REMOVE_FAIL',
};

export default ActionTypes;
