import ActionTypes from "./ActionTypes.js";

export function receivedSocketMessage(data) {
  return {
    type: "@websocket/" + data["event"],
    payload: data["payload"]
  };
}

const Actions = {
  diglexClear: () => {
    return {
      type: ActionTypes.DIGLEX_EXTRACT_CLEAR,
      payload: {}
    };
  },

  sendExtract: (project, text) => {
    return {
      type: ActionTypes.DIGLEX_EXTRACT_START,
      payload: { isWaitingExtractResult: true, project, text}
    };
  },

  projectListFetched: projects => {
    return {
      type: "PROJECT_LIST_FETCHED",
      payload: { projects: projects }
    };
  },

  fileSelected: path => {
    return {
      type: ActionTypes.DIGLEX_FILE_SELECTED,
      payload: { path: path }
    };
  },

  fileLoaded: (path, text) => {
    return {
      type: ActionTypes.DIGLEX_FILE_LOADED,
      payload: { path: path, text: text }
    };
  }
};

export default Actions;
