
const ActionTypes = {
  SOURCE_NEW_TAB: "SOURCE_NEW_TAB",
  SOURCE_RENAME_TAB: "SOURCE_RENAME_TAB",
  SOURCE_ACTIVATE_TAB: "SOURCE_ACTIVATE_TAB",
  SOURCE_REMOVE_TAB: "SOURCE_REMOVE_TAB",
  SOURCE_REMOVE_ALL_TABS: "SOURCE_REMOVE_ALL_TABS",
  SOURCE_OPEN_HELP: "SOURCE_OPEN_HELP",
};

export default ActionTypes;
