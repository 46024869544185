import React from 'react';
import {Menu, Icon} from 'antd';
import styles from './HeaderInfo.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';

export default class HeaderInfo extends React.Component {
  state = {
    toggled: false
  };

  toggle = toggled => this.setState({toggled});

  clickHandler = ({key}) => {
    this.toggle(false);
    this.props.showDrawer(this.props.helpRoutes[key]);
  };

  render() {
    const userMenu = (
      <Menu onClick={this.clickHandler}>
        {Object.keys(this.props.helpRoutes).map(path => {
          return (
            <Menu.Item key={path}>
              {this.props.helpRoutes[path].title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return (
      <HeaderDropdown
        overlay={userMenu}
        overlayClassName={styles.dropDown}
        onVisibleChange={this.toggle}
        placement="bottomRight"
        trigger={['click']}
      >
        <span>
          <Icon
            type="question-circle"
            className={`${this.state.toggled ? styles.iconToggled : styles.icon}`}
          />
          {this.props.username}
        </span>
      </HeaderDropdown>
    );
  }
}
