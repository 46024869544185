import ActionTypes from './ActionTypes.js';

const initialState = {
  schema: null,
  entity: null,
  data: [],

  count: 0,
  query: null,
  relations: null,

  loading: false,
  saving: false,
  deleting: false,
};

export default function records(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {

    case ActionTypes.RECORD_FLUSH:
      return {
        ...state,
        ...initialState,
        schema: state.schema
      };

    case ActionTypes.RECORD_GET_REQUEST:
      return {
        ...state,
        entity: payload.entity,
        loading: true,
        query: payload.query,
        relations: payload.relations,
      };

    case ActionTypes.RECORD_GET_RECEIVED:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.RECORD_SCHEMA_FETCH_RECEIVED:
      return {
        ...state,
        schema: payload.schema,
      };

    case ActionTypes.RECORD_FETCH_RECEIVED:
      return {
        ...state,
        count: payload.count ? payload.count : state.count,
        data: {
          ...state.data,
          [payload.entity]: payload.data,
        }
      };

    case ActionTypes.RECORD_DELETE_RECEIVED:
      return {
        ...state,
        deleting: false,
        data: {
          ...state.data,
          [payload.entity]: payload.data,
        }
      };

    case ActionTypes.RECORD_DELETE_REQUEST:
      return {
        ...state,
        deleting: true
      };

    case ActionTypes.RECORD_UPDATE_REQUEST:
      return {
        ...state,
        // todo: hardcoded put new state before it actually updates. need better way.
        data: {
          ...state.data,
          [payload.entity]: state.data[payload.entity].map(record => {
            if(record.id === payload.record.id) {
              return {
                ...payload.record,
                ...payload.values,
              }
            }
            return record
          }),
        },
        saving: true,
      };

    case ActionTypes.RECORD_UPDATE_RECEIVED:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.entity]: state.data[payload.entity].map(record => {
            if(record.id === payload.data.id) {
              return payload.data
            }
            return record
          }),
        },
        saving: false
      };

    default:
      return state;
  }
}
