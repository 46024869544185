import ActionTypes from './ActionTypes.js';
import {addFileYamlExtension} from '../../utils';

const initialState = {
  form: {
    delete: {loading: false, errors: {}},
    update: {loading: false, errors: {}},
    create: {loading: false, errors: {}},
    fork: {loading: false, errors: {}},
    list: {loading: false, errors: {}, values: []},
  },
  activeProject: undefined,
  //availableProjects: [],
  filesLocked: [
    //{username, path}
  ],
  saveQueue: {}
};

export default function project(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {

    /** PROJECT_CREATE **/

    case ActionTypes.PROJECT_CREATE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_CREATE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_CREATE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          create: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /** PROJECT_FORK **/

    case ActionTypes.PROJECT_FORK_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          fork: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_FORK_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          fork: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_FORK_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          fork: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /** PROJECT_DELETE **/

    case ActionTypes.PROJECT_DELETE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_DELETE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_DELETE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          delete: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /** PROJECT_DELETE **/

    case ActionTypes.PROJECT_UPDATE_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          update: {
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_UPDATE_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          update: {
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_UPDATE_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          update: {
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    /** PROJECT_FETCH */

    case ActionTypes.PROJECT_FETCH_REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: [],
            loading: true,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_FETCH_FULFILLED: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: payload.projects,
            loading: false,
            errors: {},
          }
        }
      };
    }

    case ActionTypes.PROJECT_FETCH_FAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            values: [],
            loading: false,
            errors: payload.errors,
          }
        }
      };
    }

    case ActionTypes.PROJECT_RENAME_FILE: {
      const {sourcePath, destinationPath} = payload;
      const correctedDestinationPath = addFileYamlExtension(destinationPath);

      const files = state.activeProject.files.map(file => {
        if(file.path === sourcePath) {
          file.path = correctedDestinationPath;
          file.name = correctedDestinationPath;
        }
        return file;
      });

      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          files,
        }
      };
    }

    case ActionTypes.PROJECT_DELETE_FILE: {
      const {path} = payload;
      const files = [];
      state.activeProject.files.forEach(file => {
        if(file.path === path) {
          return
        }
        files.push(file);
      });
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          files,
        }
      };
    }

    /** */

    case ActionTypes.SET_ACTIVE_PROJECT:
      return Object.assign({}, state, {
        activeProject: action['payload']['project']
      });

    case ActionTypes.SET_FILE_TREE:
      return Object.assign({}, state, {
        fileTree: action['payload']['fileTree']
      });

    case ActionTypes.PROJECT_LIST_FETCHED:
      return {
        ...state,
        availableProjects: action.payload.projects
      };

    // case ActionTypes.PROJECT_FILE_LOADED:
    //   return Object.assign({}, state, {
    //     'files': [...state.files, {
    //       'path': action['payload']['path'],
    //       'text': action['payload']['text']
    //     }]
    //   });

    case ActionTypes.PROJECT_ADD_FILE_TO_SAVE_QUEUE:
      let name = action["payload"]["name"];
      let updatingMap = {};
      updatingMap[name] = action["payload"]["value"];

      return Object.assign({}, state, {
        saveQueue: Object.assign({}, state.saveQueue, updatingMap)
      });

    case ActionTypes.PROJECT_REMOVE_FILE_FROM_SAVE_QUEUE: {
      const saveQueue = {...state.saveQueue};
      delete saveQueue[action.payload.name];

      return {
        ...state,
        saveQueue
      };
    }

    case ActionTypes.PROJECT_CLEAN_SAVE_QUEUE:
      return Object.assign({}, state, {
        saveQueue: {}
      });

    /** WS **/
    case ActionTypes.PROJECT_WS_FILES_LOCKED: {
      const files = state.filesLocked.filter(file => payload.files.find(f => file.name !== f.name));
      return {
        ...state,
        filesLocked: [...files, ...payload.files],
      };
    }

    case ActionTypes.PROJECT_WS_FILES_UNLOCKED: {
      const files = state.filesLocked.filter(file => {
        return payload.files.find(f => file.name !== f.name)
      });
      return {
        ...state,
        filesLocked: [...files],
      };
    }

    default: {
      //
    }
  }

  return state;
}
