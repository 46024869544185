import ActionTypes from './actionTypes';

const initialState = {
  visible: false,
  markdown: null,
  hash: null,
};

export default function schema(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.DRAWER_DISPLAY:
      return {
        visible: true,
        markdown: payload.markdown,
        hash: payload.hash,
      };

    case ActionTypes.DRAWER_HIDE:
      return {
        visible: false,
        markdown: null,
        hash: null,
      };

    default:
      return state;
  }
}
