import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';

import intl from 'ducks/intl/reducer.js';
import records from "ducks/record/reducer.js";
import diglex from "ducks/diglex/reducer.js";
import source from "ducks/source/reducer.js";
import project from "ducks/project/reducer.js";
import modal from "ducks/modal/reducer.js";
import user from "ducks/user/userReducer";
import member from "ducks/member/memberReducer";
import group from "ducks/group/groupReducer";
import drawer from "ducks/drawer/drawerReducer";
import ui from "ducks/ui/uiReducer";
import movieBotReducers from "ducks/movieBot/movieBotReducers";

import userActionTypes from './user/actionTypes';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  movieBotReducers,
  intl,
  records,
  modal,
  project,
  diglex,
  source,
  user,
  member,
  group,
  drawer,
  ui,
});

export default (history) => (state, action) => {
  if (action.type === userActionTypes.USER_LOGOUT) {
    state = undefined;
    window.diglexAxios = null;
  }
  return appReducer(history)(state, action);
}