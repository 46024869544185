import React from "react";
import {connect} from "react-redux";
import {Card} from 'antd';
import styles from './DiglexGroupsContainer.module.scss';
import NestedRoutes from 'utils/NestedRoutes';

const tabList = [
  {
    key: '/groups',
    tab: 'Ваши группы',
  },
  // {
  //   key: '/groups/explore',
  //   tab: 'Исследуйте группы',
  // },
];

class DiglexGroupsContainer extends React.Component {

  onTabChange = key => {
    this.props.history.push(key);
  };

  newGroup = () => {
    this.props.history.push('/groups/new');
  };

  render() {
    return (
      <Card
        className={styles.container}
        title="Группы"
        // extra={<a onClick={this.newGroup}>Создать группу</a>}
        tabList={tabList}
        activeTabKey={this.props.pathname}
        onTabChange={this.onTabChange}
        bodyStyle={{padding: 0}}
      >
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DiglexGroupsContainer);
