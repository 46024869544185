
const ActionTypes = {
  DIGLEX_IS_EXTRACTING: 'DIGLEX_IS_EXTRACTING',
  DIGLEX_EXTRACT_DONE: 'DIGLEX_EXTRACT_DONE',
  DIGLEX_EXTRACT_START: 'DIGLEX_EXTRACT_START',
  WS_EXTRACT_RESULT: '@websocket/extract-result',
  WS_EXTRACT_EXCEPTION: '@websocket/diglex-lib-handled-exception',
  DIGLEX_EXTRACT_CLEAR: 'DIGLEX_EXTRACT_CLEAR',

  // filesystem
  DIGLEX_FILE_SELECTED: 'DIGLEX_FILE_SELECTED',
  DIGLEX_FILE_LOADED: 'DIGLEX_FILE_LOADED',
};

export default ActionTypes;
