import React from 'react';
import styles from './HeaderGroup.module.scss';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';
import {Menu} from 'antd';

export default class HeaderGroup extends React.Component {
  state = {
    toggled: false
  };

  toggle = toggled => this.setState({toggled});

  handler = ({key}) => {
    this.toggle(false);

    switch (key) {
      case "groups": {
        this.props.history.push('/groups');
        break
      }
      case "explore-groups": {
        this.props.history.push('/groups/explore');
        break
      }
      case "new-group": {
        this.props.history.push('/groups/new');
        break
      }
      default:
    }
  };

  render() {
    const groupMenu = (
      <Menu onClick={this.handler}>
        <Menu.Item key={'groups'}>Ваши группы</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={'new-group'}>Новая группа</Menu.Item>
      </Menu>
    );
    return (
      <HeaderDropdown
        overlay={groupMenu}
        overlayClassName={styles.dropDown}
        onVisibleChange={this.toggle}
        placement="bottomLeft"
        trigger={['click']}
      >
        <div>
          <div className={`${this.state.toggled ? styles.menuToggled : styles.menu}`}>
            Группы
          </div>
        </div>
      </HeaderDropdown>
    );
  }
}
