import ActionTypes from './actionTypes';

const initialState = {
  loading: false,
  count: 0,
  page: 0,
  movies: null,
  errors: {},
};

const reducers = {
  [ActionTypes.MOVIE_BOT_MOVIE_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    errors: {},
  }),
  [ActionTypes.MOVIE_BOT_MOVIE_FULFILLED]: (state, action) => ({
    ...state,
    loading: false,
    count: action.payload.count,
    movies: action.payload.results,
    errors: {},
  }),
  [ActionTypes.MOVIE_BOT_MOVIE_FAIL]: (state, action) => ({
    ...state,
    loading: false,
    movies: null,
    errors: {},
  }),
};

export default function (state = initialState, action) {
  const reducer = reducers[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};