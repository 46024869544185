import React from "react";
import {connect} from "react-redux";
import {Button, Form, Select, Spin} from 'antd';
import styles from './DiglexMemberInviteContainer.module.scss';
import memberActions from '../../../ducks/member/memberActions';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
    lg: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 0},
    lg: {span: 12, offset: 0},
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 6},
    lg: {span: 12, offset: 6},
  },
};

class DiglexMemberInviteContainer extends React.Component {
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {type, id} = this.data;
        this.props.invite(type, id, values.users, this.props.location.pathname.replace('/invite', ''));
      }
    });
  };

  state = {
    data: [],
    value: [],
    fetching: false,
  };

  constructor(props) {
    super(props);
    const {match} = this.props;

    if (match.params.groupPath) {
      this.data = {
        id: this.props.activeGroup._id,
        type: 'group',
      };
    } else {
      this.data = {
        id: this.props.activeProject._id,
        type: 'project',
      };
    }
  }

  fetchUser = async query => {
    const {type, id} = this.data;
    this.setState({data: [], fetching: true});

    const response = await window.diglexAxios({
      url: '/api/members/find',
      method: 'GET',
      params: {query, type, id}
    });

    const data = response.data.map(user => ({
      text: user.username,
      value: user.id,
    }));

    this.setState({data, fetching: false});
  };

  handleChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {fetching, data, value} = this.state;

    return (
      <div
        className={styles.container}
      >
        <div className={styles.title}>Добавить участников</div>

        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="users">
            {getFieldDecorator('users', {
              rules: [
                {required: true, message: 'Введите username'},
              ],
            })(
              <Select
                mode="multiple"
                labelInValue
                placeholder="Select users"
                notFoundContent={fetching ? <Spin size="small"/> : null}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.handleChange}
                style={{width: '100%'}}
              >
                {data.map(d => (
                  <Select.Option key={d.value}>{d.text}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{marginBottom: 0}}>
            <Button
              loading={this.props.inviteForm.loading}
              style={{minWidth: '50%'}}
              type='primary'
              htmlType='submit'
            >
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    inviteForm: state.member.form.invite,
    activeProject: state.project.activeProject,
    activeGroup: state.group.activeGroup,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    invite: (type, id, users, url) => dispatch(memberActions.invite(type, id, users, url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(DiglexMemberInviteContainer));
