import React from "react";
import {connect} from "react-redux";
import {Button, Card, Form, Icon, Input, Radio, Select} from 'antd';
import styles from './DiglexGroupCreateContainer.module.scss';
import groupActions from 'ducks/group/groupActions';

const url = new URL(document.location);

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
    lg: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 0},
    lg: {span: 12, offset: 0},
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 6},
    lg: {span: 12, offset: 6},
  },
};

class DiglexGroupCreateContainer extends React.Component {
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createGroup(values);
      }
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.create.errors !== nextProps.create.errors) {
      this.props.form.setFields(nextProps.create.errors);
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    const prefixSelector = (
      <div className={styles.url}>{`${url.protocol}//${url.host}/group/`}</div>
    );

    return (
      <Card
        className={styles.container}
        title="Создать группу"
        headStyle={{textAlign: 'center'}}
      >
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="Название группы">
            {getFieldDecorator('name', {
              rules: [
                {required: true, message: 'Введите название группы'},
              ],
            })(
              <Input
                placeholder="Название группы"
              />
            )}
          </Form.Item>

          <Form.Item label="URL группы">
            {getFieldDecorator('path', {
              rules: [
                {required: true, message: 'Выберите URL группы'},
                {pattern: /^[A-Za-z0-9\-_]+$/, message: 'Выберите URL без специальных символов'},
              ],
            })(<Input addonBefore={prefixSelector} style={{width: '100%'}}/>)}
          </Form.Item>

          <Form.Item label="Описание группы">
            {getFieldDecorator('description')(
              <Input.TextArea
                style={{minHeight: 86}}
                placeholder="Описание группы"
              />
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator('access', {initialValue: 'private'})(
              <Radio.Group>
                <Radio className={styles.radio} value={'private'}>
                  <Icon style={{fontSize: 15}} type="lock" theme="filled"/>
                  {' '}
                  Закрытая группа
                </Radio>
                <div className={styles.radioInfo}>
                  Группу и ее проекты могут просматривать только участники.
                </div>
                <Radio className={styles.radio} value={'public'}>
                  <Icon style={{fontSize: 15}} type="book"/>
                  {' '}
                  Публичная группа
                </Radio>
                <div className={styles.radioInfo}>
                  Группу и внутренние проекты может просматривать любой зарегистрированный пользователь.
                </div>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{marginBottom: 0}}>
            <Button
              size={'large'}
              loading={this.props.create.loading}
              style={{minWidth: '45%'}}
              type='primary'
              htmlType='submit'
            >
              Создать группу
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    create: state.group.form.create
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createGroup: ({name, path, description, access}) => dispatch(groupActions.createGroup({
      name,
      path,
      description,
      access
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(DiglexGroupCreateContainer));
