import {Tree as AntdTree} from "antd";
import React from "react";
import ContextMenu from "components/ContextMenu/ContextMenu.js";
import {Scrollbars} from 'react-custom-scrollbars';

import "./Tree.scss";

const DirectoryTree = AntdTree.DirectoryTree;
const {TreeNode} = AntdTree;

class Tree extends React.Component {

  state = {
    popup: {
      visible: false,
      x: 0,
      y: 0
    }
  };

  onSelect = (selectedKeys, event) => {
    this.props.onFileSelect(event.node.props.eventKey);
  };

  onExpand = () => {
    // console.log("Trigger Expand");
  };

  // TODO redux refactor
  onContextMenu = args => {
    const {event, node} = args;

    event.preventDefault();
    if (!this.state.visible) {
      const that = this;
      document.addEventListener(`click`, function onClickOutside(e) {
        // FF emit click event, by right click
        if(e.button !== 0) {
          return
        }
        that.setState({ popup: { visible: false } });
        document.removeEventListener(`click`, onClickOutside);
      });
    }

    this.setState({
      popup: {
        actions: [
          {
            icon: "edit",
            title: "Переименовать",
            onClick: () => this.props.onFileRename(node.props.eventKey)
          },
          {
            icon: "delete",
            title: "Удалить",
            onClick: () => this.props.onFileDelete(node.props.eventKey)
          }
        ],
        visible: true,
        x: event.clientX,
        y: event.clientY
      }
    });
  };

  render() {
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
      >
        <ContextMenu {...this.state.popup} />
        <DirectoryTree
          defaultExpandAll
          selectedKeys={[this.props.activeTab ? this.props.activeTab.key.replace('source#', '') : null]}
          onSelect={this.onSelect}
          onExpand={this.onExpand}
          onRightClick={this.onContextMenu}
        >
          {this.props.tree.map((row, index) => (
            <TreeNode title={row.name} key={row.path} isLeaf />
          ))}
        </DirectoryTree>
      </Scrollbars>
    );
  }
}

export default Tree;
