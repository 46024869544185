import ActionTypes from "./ActionTypes.js";

const initialState = {
  tabs: [],
  activeTab: undefined
};

export default function source(state = initialState, action) {
  const {payload} = action;

  switch (action.type) {
    case ActionTypes.SOURCE_NEW_TAB: {
      const tab = payload.tab;
      tab.key = `${tab.type}#${tab.name}`;
      return {
        ...state,
        tabs: state.tabs.find(i => i.key === tab.key) ? state.tabs : [...state.tabs, tab],
      };
    }

    case ActionTypes.SOURCE_REMOVE_TAB: {
      let index = 0;
      const tabs = state.tabs.filter((tab, i) => {
        if (tab.key !== payload.key) {
          return true;
        }
        index = i;
      });
      return {
        ...state,
        tabs,
        activeTab: tabs[index > 0 ? index - 1 : 0],
      };
    }

    case ActionTypes.SOURCE_RENAME_TAB: {
      //const tab = state.tabs.filter();
      //todo:fix it
      return {
        ...state,
        tabs: [...state.tabs],
      };
    }

    case ActionTypes.SOURCE_ACTIVATE_TAB: {
      let activeTab = state.tabs.length ? state.tabs[0] : null;
      if (payload.key) {
        activeTab = state.tabs.find(tab => tab.key === payload.key);
      }
      return {
        ...state,
        activeTab,
      };
    }

    case ActionTypes.SOURCE_REMOVE_ALL_TABS: {
      return {
        ...state,
        tabs: [...state.tabs.filter(tab => tab.type !== 'source')],
      };
    }

    default: {
    }
  }

  return state;
}
