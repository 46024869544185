exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiglexGroupCreateContainer_container1280__Qws1B, .DiglexGroupCreateContainer_container__olQIN {\n  margin: 32px auto;\n  width: 100%;\n  max-width: 1280px;\n  min-height: calc(100vh - 255px); }\n  @media (max-width: 1346px) {\n    .DiglexGroupCreateContainer_container1280__Qws1B, .DiglexGroupCreateContainer_container__olQIN {\n      margin: 32px 24px;\n      width: calc(100% - 48px); } }\n\n.DiglexGroupCreateContainer_url__1D0fD {\n  color: #999;\n  font-size: 12px; }\n\n.DiglexGroupCreateContainer_radio__2zz3J {\n  display: block;\n  height: 18px;\n  line-height: 18px; }\n\n.DiglexGroupCreateContainer_radioInfo__1PfMk {\n  line-height: 16px;\n  color: #999;\n  margin: 4px 0 16px 24px; }\n", ""]);

// Exports
exports.locals = {
	"container1280": "DiglexGroupCreateContainer_container1280__Qws1B",
	"container": "DiglexGroupCreateContainer_container__olQIN",
	"url": "DiglexGroupCreateContainer_url__1D0fD",
	"radio": "DiglexGroupCreateContainer_radio__2zz3J",
	"radioInfo": "DiglexGroupCreateContainer_radioInfo__1PfMk"
};