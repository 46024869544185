import React from "react";
import {connect} from "react-redux";
import DiglexMemberList from 'components/diglex/DiglexMemberList/DiglexMemberList';
import memberActions from 'ducks/member/memberActions';

function createDiglexMemberList(type) {

  class DiglexMemberListContainer extends React.Component {
    render() {
      return (
        <DiglexMemberList
          type={type}

          history={this.props.history}
          location={this.props.location}

          username={this.props.username}
          values={this.props.values}
          loading={this.props.loading}
          removeLoading={this.props.removeLoading}
          fetchMembers={() => this.props.fetchMembers(type)}
          removeMember={this.props.removeMember}
          active={type === 'project' ? this.props.activeProject : this.props.activeGroup}
        />
      );
    }
  }

  const mapStateToProps = state => {
    return {
      activeProject: state.project.activeProject,
      activeGroup: state.group.activeGroup,

      removeLoading: state.member.form.remove.loading,
      username: state.user.profile.username,
      values: state.member.values,
      loading: state.member.loading,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      removeMember: (userId) => dispatch(memberActions.remove(type, userId)),
      fetchMembers: (type) => dispatch(memberActions.fetchMembers(type)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(DiglexMemberListContainer);
}

export default createDiglexMemberList;
