import React from "react";
import { Icon } from "antd";

import "./ContextMenu.css";

class ContextMenu extends React.Component {

  render() {
    return (
      this.props.visible && (
        <ul
          className="context-menu"
          style={{ left: `${this.props.x}px`, top: `${this.props.y}px` }}
        >
          {this.props.actions.map((row, index) => {
            return (
              <li key={index} onClick={row.onClick}>
                <Icon type={row.icon} />
                {row.title}
              </li>
            );
          })}
        </ul>
      )
    );
  }
}

export default ContextMenu;
