import React, {Component} from "react";
import NestedRoutes from 'utils/NestedRoutes';
import {Modal} from 'antd';
import {history} from 'ducks/store';

class ModalLayout extends Component {
  render() {
    const route = this.props.routes[0].routes.find(r => this.props.location.pathname.match(RegExp(`${r.path}$`)));

    if(!route) {
      return null;
    }

    return (
      <Modal
        width={840}
        bodyStyle={{
          padding: 0,
        }}
        visible={true}
        onCancel={() => history.push(this.props.match.url)}
        footer={null}
        // title={route.title}
      >
        {console.log()}
        <NestedRoutes routes={this.props.routes} match={this.props.match}/>
      </Modal>
    );
  }
}

export default ModalLayout;