import ActionTypes from './ActionTypes.js';

const RecordActions = {

  flushRecords: () => ({
    type: ActionTypes.RECORD_FLUSH,
    payload: {},
  }),

  getRecords: (entity, query = {}, relations = []) => ({
    type: ActionTypes.RECORD_GET_REQUEST,
    payload: {entity, query, relations},
  }),

  fetchSchema: () => ({
    type: ActionTypes.RECORD_SCHEMA_FETCH_REQUEST,
    payload: {},
  }),

  fetchRecords: (entity, query) => ({
    type: ActionTypes.RECORD_FETCH_REQUEST,
    payload: {entity, query},
  }),

  deleteRecord: (entity, values, record, navigate) => ({
    type: ActionTypes.RECORD_DELETE_REQUEST,
    payload: {entity, values, record, navigate},
  }),

  createRecord: (entity, values, navigate) => ({
    type: ActionTypes.RECORD_CREATE_REQUEST,
    payload: {entity, values, navigate},
  }),

  updateRecord: (entity, values, record, navigate) => ({
    type: ActionTypes.RECORD_UPDATE_REQUEST,
    payload: {entity, values, record, navigate},
  }),

};

export default RecordActions;
