import React from "react";
import {connect} from "react-redux";
import groupActions from 'ducks/group/groupActions';
import DiglexGroupList from 'components/diglex/DiglexGroupList/DiglexGroupList';

function createDiglexGroupList(type) {

  class DiglexGroupsListContainer extends React.Component {
    render() {
      return (
        <DiglexGroupList
          history={this.props.history}
          location={this.props.location}

          list={this.props.list}
          fetchGroups={() => this.props.fetchGroups(type)}
        />
      );
    }
  }

  const mapStateToProps = state => {
    return {
      list: state.group.form.list,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      fetchGroups: (type) => dispatch(groupActions.fetchGroups(type)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(DiglexGroupsListContainer);
}

export default createDiglexGroupList;
