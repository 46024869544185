import React from 'react';
import {Drawer} from 'antd';
import {connect} from 'react-redux';
import drawerActions from '../../ducks/drawer/drawerActions';
import SplitPane from 'react-split-pane';
import MarkdownContainer from '../MarkdownContainer/MarkdownContainer';

class DrawerContainer extends React.Component {
  render() {
    const maxSize = this.props.window.width - 600;
    const defaultSize = this.props.window.width / 2 + 125;

    return (
      <Drawer
        style={{overflow: 'hidden', pointerEvents: 'none'}}
        width={"100%"}
        onClose={this.props.hide}
        bodyStyle={{padding: 0}}
        visible={this.props.visible}
      >
        <SplitPane
          split="vertical"
          minSize={8}
          maxSize={maxSize}
          defaultSize={defaultSize < maxSize ? defaultSize : maxSize}
          style={{background: 'transparent', pointerEvents: 'none'}}
          pane1Style={{background: 'transparent', pointerEvents: 'none'}}
          pane2Style={{background: '#fff', pointerEvents: 'all', boxShadow: '-2px 0 8px rgba(0, 0, 0, 0.15)'}}
          resizerStyle={{pointerEvents: 'all'}}
        >
          <div/>
          {this.props.markdown ?
            <MarkdownContainer
              key={this.props.markdown.title}
              title={this.props.markdown.title}
              source={this.props.markdown.source}
              footer={this.props.markdown.footer}
              hash={this.props.hash}
              isModal={true}
              showDrawer={this.props.showDrawer}
            />
            :
            <div/>
          }
        </SplitPane>
      </Drawer>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    window: state.ui.window,
    visible: state.drawer.visible,
    markdown: state.drawer.markdown,
    hash: state.drawer.hash,
  };
};

const mapDispatchToProps = (dispatch) => {

  return {
    showDrawer: (markdown, hash) => dispatch(drawerActions.showDrawer(markdown, hash)),
    hide: () => dispatch(drawerActions.hideDrawer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);